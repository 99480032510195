<template>
  <div
    ref="drawer"
    class="side-drawer-container"
    :class="{ open: pinned }"
    :style="{ width: setSideDrawerWidth }"
    @mouseover="hover = true"
    @mouseleave="!drag ? (hover = false) : null"
    @scroll="onScroll"
  >
    <div class="side-drawer-content">
      <slot> Content missing. </slot>
    </div>
    <div
      class="side-drawer-dragger"
      @mousedown="onDragSideDrawer()"
      @mouseup="leaveDragSideDrawer()"
    >
      <div class="dragger" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    refContent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      resizedWidth: Number,
      hover: false,
      drag: false,
    };
  },
  computed: {
    ...mapGetters({
      dragging: 'dragging',
      pinned: 'pinned',
      drawer: 'drawer',
      sideDrawerWidth: 'sideDrawerWidth',
    }),
    setSideDrawerWidth() {
      if (!this.pinned && !this.hover) {
        return '50px';
      }
      return `${this.resizedWidth}px`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resizedWidth = this.sideDrawerWidth;
      this.$store.dispatch('setDrawerState', { drawer: true });
    });
  },
  unmounted() {
    this.$store.dispatch('setDrawerState', { drawer: false });
    this.leaveDragSideDrawer();
  },
  methods: {
    onScroll(e) {
      var elem = e.target;

      // We are using subtracting a single pixel because it is more performant than Math.ciel(elem.scollTop)
      var offset = elem.scrollTop + elem.offsetHeight;
      var height = elem.scrollHeight - 1;

      if (offset >= height) {
        const bottomScrollEvent = new CustomEvent('bottomScrollSidedrawer');
        window.dispatchEvent(bottomScrollEvent);
      }
    },
    moveSideDrawer(e) {
      e.preventDefault();
      this.resizedWidth = window.innerWidth - e.clientX;
      if (this.resizedWidth <= 320) {
        this.resizedWidth = 320;
      }
    },
    leaveDragSideDrawer() {
      this.drag = false;
      document.body.style.cursor = '';
      document.removeEventListener('mousemove', this.moveSideDrawer, false);
      document.removeEventListener('mouseup', this.moveSideDrawer, false);
      this.$store.dispatch('setSideDrawerWidthState', {
        sideDrawerWidth: this.resizedWidth,
      });
    },
    onDragSideDrawer() {
      this.drag = true;
      document.addEventListener('mousemove', this.moveSideDrawer);
      document.addEventListener('mouseup', this.leaveDragSideDrawer);
    },
  },
};
</script>

<style lang="scss" scoped>
.side-drawer-container {
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 50;
  text-align: center;
  right: 0;
  transition: width 200ms;
  transition: right 200ms;
  overflow: hidden scroll;
  background-color: $color-bg-secondary;

  .side-drawer-content {
    position: absolute;
    width: 99.5%;
    height: 100%;
    top: 0;
    right: -98%;
    background: $color-bg-secondary;
    color: $color-text-secondary;
    transition: width 200ms;
    transition: right 200ms;

    > div {
      background: $color-bg-secondary;
    }
  }

  .side-drawer-dragger {
    height: 100%;
    position: fixed;
    padding-left: 1rem;
    cursor: ew-resize;

    .dragger {
      top: 50%;
      left: 50%;
      width: 3px;
      border: 1px solid #bababa;
      border-radius: 8px;
      height: 4rem;
      background: #bababa;
      position: absolute;
      z-index: 5;
    }
  }

  &:hover,
  &.open {
    .side-drawer-content {
      right: 0;
    }

    .side-drawer-container {
      right: 0;
    }
  }
}
</style>
