<template>
  <div class="va-field va-data-table">
    <label class="title-label">{{ title }}</label>
    <va-list
      v-if="items.length"
      ref="va-list"
      v-model:selected-items="selectedItems"
      :checkbox-actions="checkboxActions"
      :columns="columns"
      :items="items"
      item-id="id"
      @ON_CHECKBOX_ACTION_CLICK="onCheckboxActionClick"
    >
      <template
        v-for="column in fieldModelColumns"
        #[column.slotName]="{ item: item }"
        :key="column.id"
      >
        <va-input
          type="text"
          :model-value="item[column.id]"
          @update:model-value="updateItem(item.id, column.id, $event)"
        />
      </template>
      <template #cell-actions="{ item: item }">
        <div class="icon-wrapper">
          <svg-icon
            icon="cross"
            class="icon"
            @click="itemsToRemove = [item.id]"
          />
        </div>
      </template>
    </va-list>
    <va-button data-testid="data-table--add-button" @click="addItem">
      {{ $translate('generic.addNew') }}
    </va-button>
    <confirm-modal
      v-if="itemsToRemove"
      ref="confirm-modal"
      :title="$translate('formFields.vaDataTable.confirmModal.title')"
      :ok-button="$translate('generic.remove')"
      :cancel-button="$translate('generic.cancel')"
      type="warning"
      width="500"
      @confirm="removeItems"
      @cancel="itemsToRemove = null"
    />
  </div>
</template>

<script>
import formHelper from '@/helpers/form-helper';
import VaList from '@/components/framework/va-list/va-list.vue';
import ConfirmModal from '@/components/common/ConfirmModal.vue';
import VaInput from '@/components/framework/va-input.vue';
import VaButton from '@/components/framework/VaButton.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import { v4 as uuidv4 } from 'uuid';
export default {
  components: {
    VaList,
    ConfirmModal,
    VaInput,
    VaButton,
    SvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      itemsToRemove: null,
      selectedItems: [],
    };
  },
  computed: {
    title() {
      return formHelper.getTranslatedLabel(
        this.fieldModel.label,
        this.$getUserlanguage(),
      );
    },
    fieldModelColumns() {
      return (
        this.fieldModel.schema?.map((column) => ({
          id: column.id,
          name: column.label,
          enabled: true,
          slotName: 'cell-' + column.id,
        })) || []
      );
    },
    columns() {
      const checkboxColumn = {
        id: 'checkbox',
        name: '',
        enabled: true,
        locked: true,
        checkbox: true,
      };
      const actionsColumn = {
        id: 'actions',
        name: '',
        enabled: true,
      };
      return [checkboxColumn, ...this.fieldModelColumns, actionsColumn];
    },
    items() {
      return this.data[this.fieldModel.fieldName] || [];
    },
    checkboxActions() {
      return [
        { label: this.$translate('generic.remove'), emitAction: 'REMOVE' },
      ];
    },
  },
  methods: {
    addItem() {
      let newItem = { id: uuidv4() };
      this.fieldModelColumns.forEach((column) => {
        newItem[column.id] = '';
      });
      const currentItems = this.data[this.fieldModel.fieldName] || [];
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = [...currentItems, newItem];
      this.emitUpdate();
    },
    removeItems() {
      let currentItems = this.data[this.fieldModel.fieldName];
      this.itemsToRemove.forEach((id) => {
        const index = currentItems.findIndex((item) => item.id === id);
        currentItems.splice(index, 1);
      });
      this.emitUpdate();
      this.itemsToRemove = null;
      this.selectedItems = [];
    },
    updateItem(id, attribute, value) {
      let currentItems = this.data[this.fieldModel.fieldName];
      currentItems.find((item) => item.id === id)[attribute] = value;
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('updatedData');
    },
    onCheckboxActionClick({ items }) {
      this.itemsToRemove = items;
    },
  },
};
</script>

<style lang="scss" scoped>
.va-data-table {
  text-align: left;

  .icon-wrapper {
    display: flex;
    align-items: center;
    font-size: 20px;

    .icon {
      cursor: pointer;

      &:hover {
        color: $color-fill-danger;
      }
    }
  }
}
</style>
