<template>
  <div class="va-search-filter">
    <div class="filter-container">
      <svg-icon
        icon="close"
        class="close-icon"
        :title="$translate('icons.close')"
        @click="toggleShowFilter"
      />
      <va-filter
        :save="save"
        :condition-lookups="save.conditionLookups"
        :saved-condition="save.savedCondition"
        :has-permission-to-edit-filter="hasPermissionToEditFilter"
        :filter-by="filterBy"
        :media-libraries="mediaLibraries"
        :tag-type="tagType"
        @SAVE_FILTER="onFilterSave"
        @DELETE_FILTER="onFilterDelete"
        @change="onChangedFilterCondition"
        @changeAttribs="onChangedFilterAttributes"
      />
    </div>
    <va-modal
      v-if="showSaveFilterModal"
      class="saveFilterModal"
      :type="'confirm'"
      :width="525"
      @confirm="onSaveFilter"
      @close="onCloseSave"
    >
      <template #header>
        <h3>{{ $translate('filter.saveFilter') }}</h3>
      </template>
      <template #body>
        <p class="info-p">
          {{ $translate('filter.saveModal.defineSave') }}
        </p>
        <va-radio
          v-model="saveFilterType"
          class="radio-button"
          value="new"
          :label="$translate('filter.saveModal.saveNew')"
          @change="onNewRadioChange"
        />
        <va-radio
          v-model="saveFilterType"
          class="radio-button"
          value="overwrite"
          :label="$translate('filter.saveModal.saveOverwrite')"
          @change="onOverwriteRadioChange"
        />
        <label
          v-if="saveFilterType === 'new'"
          class="info-label"
          for="new-filter-name"
        >
          {{ $translate('filter.saveModal.saveNewInfo') }}
        </label>
        <label
          v-if="saveFilterType === 'overwrite'"
          class="info-label"
          for="overwrite-filter-name"
        >
          {{ $translate('filter.saveModal.saveOverwriteInfo') }}
        </label>
        <input
          v-if="saveFilterType === 'new'"
          id="new-filter-name"
          ref="input-area-new"
          v-model="newFilterName"
          data-testid="va-search-filter--new-filter-name-input"
          maxlength="50"
          class="modal-field"
          type="text"
        />

        <div
          v-if="saveFilterType === 'overwrite'"
          ref="search-field-container"
          class="search-field-container"
          @click="setFocusOnInput"
        >
          <div
            v-for="(code, index) in codesAttributes"
            :key="code.code"
            class="code-container"
          >
            <div class="code">
              <span>{{ code.attributes.name }}</span>
              <i class="fa fa-times" @click="onRemoveCode(index)" />
            </div>
          </div>
          <input
            v-if="!saveCode"
            id="overwrite-filter-name"
            ref="input-area"
            v-model="saveSearchText"
            maxlength="50"
            class="input-area"
            @keydown.down.prevent
            @keydown="onInputKeyDown"
          />
          <svg-icon
            v-if="codesAttributes.length === 0"
            icon="search"
            class="search-icon"
          />
        </div>
        <va-popup
          v-if="showSearchResult && saveFilterType == 'overwrite'"
          :reference-element="$refs['search-field-container']"
          direction="bottom"
          @close="onPopupClose"
        >
          <div ref="popup-content" class="search-result-container">
            <ul v-if="savedFilters.length > 0" role="listbox">
              <li
                v-for="(filter, index) in savedFilters"
                :id="`option${index}`"
                :key="filter.code"
                role="option"
                tabindex="-1"
                @keydown.prevent="onListKeyDown($event, filter)"
                @click="addCode(filter.code, { name: filter.name })"
              >
                {{ filter.name }}
              </li>
            </ul>
          </div>
        </va-popup>
      </template>
      <template #footer>
        <div class="footer">
          <va-button
            color="secondary"
            class="cancel-button"
            @click="onCloseSave"
          >
            {{ $translate('generic.cancel') }}
          </va-button>
          <va-button
            id="confirm-button"
            data-testid="va-search-filter--new-filter-confirm-button"
            :disabled="!newFilterName && !saveCode"
            @click="onSaveFilter"
          >
            {{ $translate('generic.save') }}
          </va-button>
        </div>
      </template>
    </va-modal>
  </div>
</template>

<script>
import VaModal from '@/components/framework/va-modal.vue';
import VaFilter from '@/components/framework/va-filter/va-filter.vue';
import VaPopup from '@/components/framework/va-popup.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import VaRadio from '@/components/framework/va-radio.vue';
import VaButton from './VaButton.vue';

export default {
  components: {
    VaModal,
    VaFilter,
    VaPopup,
    SvgIcon,
    VaRadio,
    VaButton,
  },
  props: {
    filterBy: {
      type: [Object, Array],
      default: null,
    },
    save: {
      type: Object,
      default: null,
    },
    successfullySavedFilter: {
      type: Boolean,
      default: false,
    },
    savedFilters: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hasPermissionToEditFilter: {
      type: Boolean,
    },
    mediaLibraries: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tagType: {
      type: String,
      default: '',
    },
  },
  emits: [
    'CLOSE_FILTER',
    'CHANGED_FILTER',
    'CHANGED_ATTRIBS',
    'DELETE_FILTER',
    'SAVE_FILTER',
    'SEARCH_SAVED_FILTER',
  ],
  data() {
    return {
      showSaveFilterModal: false,
      conditions: null,
      saveCode: null,
      newFilterName: null,
      filterName: null,
      showSearchResult: false,
      codesAttributes: [],
      saveFilterType: 'new',
      saveSearchText: '',
      searchDelayTimer: null,
    };
  },
  watch: {
    successfullySavedFilter(newValue) {
      if (newValue === true) {
        this.onSuccessfulSaveFilter();
      }
    },
    saveSearchText() {
      this.onKeyUp();
    },
  },
  methods: {
    onInputKeyDown(e) {
      if (e.key === 'Tab' || e.key === 'Enter') {
        e.preventDefault();
        return;
      }
      this.showSearchResult = true;
      if (e.key === 'ArrowDown') {
        this.$nextTick(() => {
          document.getElementById('option0').focus();
        });
      }
    },
    onListKeyDown(e, filter) {
      if (e.key === 'ArrowDown') {
        const activeOption = document.activeElement;
        const nextOption = activeOption.nextElementSibling;
        if (nextOption) {
          nextOption.focus();
        }
      } else if (e.key === 'ArrowUp') {
        const activeOption = document.activeElement;
        const prevOption = activeOption.previousElementSibling;
        if (prevOption) {
          prevOption.focus();
        }
      } else if (e.key === 'Enter') {
        this.addCode(filter.code, { name: filter.name });
      } else if (e.key === 'Escape' || e.key === 'Tab') {
        this.showDropdown = false;
      }
    },
    toggleShowFilter() {
      this.$emit('CLOSE_FILTER');
      this.filterActive = false;
      this.$emit('CHANGED_FILTER', null);
      this.$emit('CHANGED_ATTRIBS', null);
    },
    onChangedFilterCondition(condition) {
      if (!condition) {
        this.filterActive = false;
      } else if (condition.subNodes[0].subNodes.length == 0) {
        condition = null;
        this.filterActive = false;
      } else {
        this.filterActive = true;
      }
      this.$emit('CHANGED_FILTER', condition);
    },
    onChangedFilterAttributes(attributes) {
      this.$emit('CHANGED_ATTRIBS', attributes);
    },
    onFilterDelete(filterCode) {
      this.$emit('DELETE_FILTER', filterCode);
    },
    onFilterSave(conditions) {
      this.conditions = conditions;
      this.showSaveFilterModal = true;
      this.onNewRadioChange();
    },
    onCloseSave() {
      this.showSaveFilterModal = false;
      this.saveCode = null;
      this.saveFilterType = 'new';
      this.codesAttributes = [];
      this.conditions = null;
      this.saveSearchText = '';
    },
    onRadioChange() {
      this.codesAttributes = [];
      this.saveCode = null;
    },
    onNewRadioChange() {
      this.onRadioChange();

      this.$nextTick(() => {
        this.$refs['input-area-new'].focus();
      });
    },
    onOverwriteRadioChange() {
      this.onRadioChange();
      // To trigger store to get right filters if client has been changed
      this.onSearch();

      this.$nextTick(() => {
        this.$refs['input-area'].focus();
      });
    },
    onSaveFilter() {
      this.$emit('SAVE_FILTER', {
        saveCode: this.saveCode || null,
        filterName: this.saveCode ? this.filterName : this.newFilterName,
        conditions: this.conditions,
      });
    },
    onSuccessfulSaveFilter() {
      if (this.saveCode) {
        this.$store.dispatch('addMessage', {
          message: {
            text: this.$translate(
              'filter.saveModal.saveOverwriteSuccess',
              this.filterName,
            ),
            type: 'success',
          },
          id: 'filter-save--success',
          time: 10000,
        });
        this.saveCode = null;
      } else {
        this.$store.dispatch('addMessage', {
          message: {
            text: this.$translate(
              'filter.saveModal.saveNewSuccess',
              this.newFilterName,
            ),
            type: 'success',
          },
          id: 'filter-save--success',
          time: 10000,
        });
        this.newFilterName = '';
      }

      this.toggleShowFilter();
      this.saveFilterType = 'new';
      this.codesAttributes = [];
      this.conditions = null;
      this.showSaveFilterModal = false;
    },
    setFocusOnInput(e) {
      if (!this.saveCode) {
        if (
          e.target !== this.$refs['search-field-container'] &&
          e.target !== this.$refs['input-area']
        ) {
          return;
        }
        this.$refs['input-area'].focus();
        this.showSearchResult = true;
      }
    },
    addCode(code, attributes) {
      this.codesAttributes.push({ code: code, attributes: attributes });
      this.saveCode = code;
      this.filterName = attributes.name;
      this.showSearchResult = false;
      this.$nextTick(() => {
        document.getElementById('confirm-button').focus();
      });
    },
    onRemoveCode(index) {
      this.codesAttributes.splice(index, 1);
      this.saveCode = null;
    },
    onPopupClose() {
      this.saveSearchText = '';
      this.showSearchResult = false;
    },
    onKeyUp() {
      if (this.searchDelayTimer) {
        clearTimeout(this.searchDelayTimer);
      }
      this.searchDelayTimer = setTimeout(this.onSearch, 500);
    },
    onSearch() {
      this.$emit('SEARCH_SAVED_FILTER', this.saveSearchText);
    },
  },
};
</script>

<style lang="scss" scoped>
.va-search-filter {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  letter-spacing: 0.38px;
  margin-bottom: 1rem;

  .filter-container {
    position: relative;
    background-color: $color-bg-secondary;
    border-radius: 20px;
    padding: 30px;
    width: 100%;
    min-width: 950px;
    box-sizing: border-box;
    right: 0;
    z-index: 10;

    .close-icon {
      position: absolute;
      color: $color-text-secondary;
      top: 1rem;
      right: 1rem;
      font-size: 16px;
      cursor: pointer;
      transition: color 0.2s ease-out;

      &:hover {
        color: $color-text;
      }
    }

    &::before {
      content: '';
      position: absolute;
      right: 12.2rem;
      height: 0;
      width: 0;
      top: -0.5rem;
      border-left: 0.6rem solid transparent;
      border-right: 0.6rem solid transparent;
      border-bottom: 0.6rem solid $color-bg-secondary;
      background-color: transparent;
      overflow: hidden;
    }
  }

  .search-field-container {
    position: relative;
    min-height: 40px;
    width: 100%;
    box-sizing: border-box;
    display: block;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 2.5px;
    background-color: $color-input;
    cursor: pointer;

    .code-container {
      height: 40px;
      display: flex;
      align-items: center;

      .code {
        background-color: $color-action-bg-secondary;
        padding: 9px 15px;
        border-radius: 15.8px;
        font-size: 12.15px;
        letter-spacing: 0.3px;
        text-align: center;
        margin: 2.5px;

        > i {
          padding-left: 5px;
          transition: color 0.2s ease-out;

          &:hover {
            color: $color-text;
          }
        }
      }
    }

    .input-area {
      border: none;
      background: none;
      color: $color-text-secondary;
      margin-left: 12px;
      padding: 0;
      height: 40px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .search-result-container {
    position: absolute;
    margin-top: 0.2rem;
    background-color: $color-fill-secondary;
    right: 0;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

    ul {
      max-height: 240px;
      overflow-y: scroll;
      border-radius: 4px;
      padding: 0;

      li {
        height: 20px;
        padding: 10px 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        transition:
          background-color 0.2s ease-out,
          color 0.2s ease-out;

        &:focus-visible {
          outline: $color-border-active solid 1px;
        }

        &:focus-visible,
        &:hover {
          background-color: $color-fill-secondary-hover;
          color: $color-text;
          cursor: pointer;
        }
      }
    }
  }

  .modal-field {
    display: block;
    background-color: $color-input;
    outline: none;
    border: none;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    padding-left: 12px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: Roboto, sans-serif;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: left;
    color: $color-text;
  }

  .info-label {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .info-p {
    padding-bottom: 10px !important;
  }

  .radio-button {
    padding-bottom: 10px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;

    .cancel-button {
      margin-right: 10px;
    }
  }
}
</style>
