<template>
  <div class="va-field va-textarea">
    <va-field :is-view-mode="!editMode" :is-empty="empty">
      <template #field-data>
        <template v-if="editMode">
          <textarea
            ref="vatextarea"
            :value="dataFieldName"
            :maxlength="maxLength"
            :data-testid="`textarea-edit-${
              fieldModel.id || fieldModel.fieldName
            }`"
            @blur="finishEdit($event)"
          />
        </template>

        <template v-if="!editMode">
          <div
            class="textarea-nonedit"
            tabindex="0"
            :data-testid="`textarea-${fieldModel.id || fieldModel.fieldName}`"
            @focus="editMode = !editMode"
            @click="editMode = !editMode"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="dataFieldName" />
          </div>
        </template>
      </template>

      <template #field-label>
        {{ computedLabel }}
      </template>
    </va-field>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    empty() {
      if (!this.dataFieldName) {
        return true;
      }
      return false;
    },
    computedData() {
      return this.data;
    },
    computedLabel() {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    dataFieldName() {
      return DOMPurify.sanitize(this.computedData[this.fieldModel.fieldName]);
    },
    maxLength() {
      if (this.fieldModel.maxLength) {
        return this.fieldModel.maxLength;
      }
      return '';
    },
  },
  updated() {
    if (this.editMode) {
      this.$refs.vatextarea.focus();
      this.$refs.vatextarea.value =
        this.dataFieldName
          ?.replace(/<h1>(.*?)<\/h1><br>/g, '#$1\n')
          .replace(/<br>/g, '\n') || '';
    }
  },
  methods: {
    finishEdit(e) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = e.target.value
        .replace(/^#(.*?)\n/m, '<h1>$1</h1><br>')
        .replace(/\r\n|\r|\n/g, '<br>');
      this.editMode = false;
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  min-height: 5.4rem;
  width: calc(100% - 1.6rem);
  line-height: 0.9rem;
  font-size: 0.8rem;
  color: $color-text;
  padding: 1.2rem 0.8rem 0;
  border: 0;
  background-color: inherit;
}

:deep(.field) {
  &.view-mode.empty {
    label {
      font-size: 0.8rem;
      top: 1.2rem !important;
      transform: none !important;
    }
  }
}

.textarea-nonedit {
  height: 5.5rem;
  line-height: 0.9rem;
  color: $color-text-secondary;
  padding: 1.2rem 0.8rem 0;
  overflow: hidden;
  word-break: break-all;

  span {
    display: inline-block;
    color: $color-text;
  }
}
</style>
