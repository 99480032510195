import api from '@/api/api.js';
import ErrorMessageHelper from '@/helpers/errorMessageHelper';
import store from '@/store/index';

const URLS = {
  metadataWithCode: 'media/{mediaCode}/metadata',
  getMediaUsage: 'media/{mediaCode}/usage',
  searchMedia: 'media/search',
  removeMedia: 'media/removemedia',
  modifyMediaTags: 'media/multipletags',
  mediaTagsWithCode: 'media/{mediaCode}/tags',
  mediaWithCode: 'media/{mediaCode}',
  getImages: 'media/images',
  getVideos: 'media/videos',
  getSigns: 'media/signs',
  getFallbackSigns: 'media/fallbackSigns',
  mediaLookup: 'media/mediafiles/lookup',
  getUnsplash: 'externalmedia/Unsplash',
  postUnsplash: 'externalmedia/Unsplash/{unsplashId}',
  mediaUpload: 'media/upload',
  downloadPreviewZip: 'media/multipleMediaFiles',
  mediaGenerate: 'media/generate',
};

export const getMetadata = (mediaCode) => {
  return api.get(URLS.metadataWithCode.replace('{mediaCode}', mediaCode));
};

export const editMetadata = (mediaCode, metadata) => {
  return api.put(
    URLS.metadataWithCode.replace('{mediaCode}', mediaCode),
    metadata,
  );
};

export const getMedia = (
  {
    condition,
    searchText,
    maxItemsInResult,
    skipItemsInResult,
    orderBy,
    order,
    mediaTypes,
    libraries,
  },
  config,
) => {
  return api.post(
    URLS.searchMedia,
    {
      Condition: condition,
      SearchText: searchText,
      MaxItemsInResult: maxItemsInResult,
      SkipItemsInResult: skipItemsInResult,
      OrderBy: orderBy,
      Order: order,
      MediaTypes: mediaTypes,
      Libraries: libraries,
    },
    config,
  );
};

export const removeMultipleMedia = (mediaCodes) => {
  return api.put(URLS.removeMedia, {
    mediaCodes: mediaCodes,
  });
};

export const modifyMediaTags = (tagCodes, mediaCodes, added) => {
  return api.put(URLS.modifyMediaTags, {
    tagCodes: tagCodes,
    mediaCodes: mediaCodes,
    added: added,
  });
};

export const getMediaTags = (mediaCode) => {
  return api.get(URLS.mediaTagsWithCode.replace('{mediaCode}', mediaCode));
};

export const editMediaTags = (mediaCode, mediaTags) => {
  return api.put(
    URLS.mediaTagsWithCode.replace('{mediaCode}', mediaCode),
    mediaTags,
  );
};

export const getMediaUsage = (mediaCode) => {
  return api.get(URLS.getMediaUsage.replace('{mediaCode}', mediaCode));
};

export const downloadFile = (mediaCode) => {
  return api.get(URLS.mediaWithCode.replace('{mediaCode}', mediaCode), {
    responseType: 'blob',
  });
};

export const downloadPreview = (mediaCodes) => {
  return api.post(
    URLS.downloadPreviewZip,
    {
      mediaCodes: mediaCodes,
      appearances: ['Poster'],
    },
    {
      responseType: 'blob',
    },
  );
};

export const mediaUpload = (
  files,
  translate,
  { allowOverwrite = false, mediaLibrary } = {},
) => {
  let addMessage = true;
  let fileName;
  let fileList = new FormData();

  if (files instanceof FormData) {
    fileList = files;
  } else {
    for (let i = 0; i < files.length; i++) {
      fileList.append(files[i].name, files[i], files[i].name);
    }
  }

  return api
    .post(URLS.mediaUpload, fileList, {
      params: {
        allowOverwrite: allowOverwrite,
        mediaLibrary: mediaLibrary,
      },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.total;
        let uploadProgress = Math.round(
          (progressEvent.loaded * 100) / totalLength,
        );

        for (const file of fileList.entries()) {
          fileName = file[0];
          if (addMessage) {
            addMessage = false;
            store.dispatch('addMessage', {
              message: {
                text: `${translate('media.uploading')} ${fileName}`,
                type: 'info',
                progress: uploadProgress,
              },
              id: fileName,
            });
          } else {
            store.dispatch('editMessage', {
              id: fileName,
              message: { progress: uploadProgress },
            });
          }
        }
      },
    })
    .then((response) => {
      if (response.data?.Medias) {
        if (response.data.Medias.length > 0) {
          if (response.data.Medias[0].errorMessage) {
            store.dispatch('editMessage', {
              id: fileName,
              message: {
                text: ErrorMessageHelper.renderErrorMessage(
                  response.data.Medias[0].errorMessage,
                ),
                type: 'error',
              },
            });
          } else {
            store.dispatch('editMessage', {
              id: fileName,
              message: {
                text: translate('media.uploaded') + ' ' + fileName,
                type: 'success',
              },
            });
            setTimeout(function () {
              store.dispatch('removeMessage', {
                id: fileName,
              });
            }, 6000);
          }
        }
      }
      return response;
    })
    .catch(() => {
      if (fileName) {
        store.dispatch('editMessage', {
          id: fileName,
          message: {
            text: translate('media.error.upload') + ' ' + fileName,
            type: 'error',
          },
        });
        setTimeout(function () {
          store.dispatch('removeMessage', {
            id: fileName,
          });
        }, 10000);
      } else {
        store.dispatch('addMessage', {
          message: {
            text: translate('media.error.upload'),
            type: 'error',
          },
          time: 10000,
        });
      }
    });
};

export const getImages = (
  { searchText, maxItemsInResult, skipItemsInResult, orderBy, order },
  config,
) => {
  return api.get(
    URLS.getImages,
    {
      params: {
        SearchText: searchText,
        MaxItemsInResult: maxItemsInResult,
        SkipItemsInResult: skipItemsInResult,
        OrderBy: orderBy,
        Order: order,
      },
    },
    config,
  );
};

export const getVideos = (
  { searchText, maxItemsInResult, skipItemsInResult, orderBy, order },
  config,
) => {
  return api.get(
    URLS.getVideos,
    {
      params: {
        SearchText: searchText,
        MaxItemsInResult: maxItemsInResult,
        SkipItemsInResult: skipItemsInResult,
        OrderBy: orderBy,
        Order: order,
      },
    },
    config,
  );
};

export const getSigns = (
  { searchText, maxItemsInResult, skipItemsInResult, orderBy, order },
  config,
) => {
  return api.get(
    URLS.getSigns,
    {
      params: {
        SearchText: searchText,
        MaxItemsInResult: maxItemsInResult,
        SkipItemsInResult: skipItemsInResult,
        OrderBy: orderBy,
        Order: order,
      },
    },
    config,
  );
};

export const getFallbackSigns = (
  { searchText, maxItemsInResult, skipItemsInResult, orderBy, order },
  config,
) => {
  return api.get(
    URLS.getFallbackSigns,
    {
      params: {
        SearchText: searchText,
        MaxItemsInResult: maxItemsInResult,
        SkipItemsInResult: skipItemsInResult,
        OrderBy: orderBy,
        Order: order,
      },
    },
    config,
  );
};

export const mediaLookup = ({ searchText, lookupBy, mediaLibrary }, config) => {
  return api.get(
    URLS.mediaLookup,
    {
      params: {
        SearchText: searchText,
        lookupBy: lookupBy,
        mediaLibrary: mediaLibrary,
      },
    },
    config,
  );
};

//Unsplash media
export const getUnsplash = (
  { searchText, maxItemsInResult, skipItemsInResult },
  config,
) => {
  return api.get(URLS.getUnsplash, {
    params: {
      searchText: searchText,
      maxItemsInResult: maxItemsInResult,
      skipItemsInResult: skipItemsInResult,
    },
    ...config,
  });
};

export const uploadUnsplashMedia = (item, translate) => {
  const unsplashId = item.getAttribute('unsplashId');
  const name = item.getAttribute('name');
  const userName = item.getAttribute('username');

  return api
    .post(
      URLS.postUnsplash.replace('{unsplashId}', unsplashId),
      {},
      {
        onUploadProgress: () => {
          store.dispatch('addMessage', {
            message: {
              text: `${translate('media.uploading')} ${unsplashId}...`,
              type: 'info',
            },
            id: unsplashId,
          });
        },
      },
    )
    .then((response) => {
      if (unsplashId) {
        store.dispatch('editMessage', {
          id: unsplashId,
          message: {
            text: translate('framework.photoByUnsplash', userName, name),
            type: 'success',
          },
        });
        setTimeout(function () {
          store.dispatch('removeMessage', { id: unsplashId });
        }, 6000);
      }
      return response;
    })
    .catch(() => {
      store.dispatch('editMessage', {
        id: unsplashId,
        message: {
          text: translate('framework.getUnsplashError'),
          type: 'error',
        },
      });
    });
};

export const generateMedia = (text, imageSize, imageStyle, config) => {
  return api.post(
    URLS.mediaGenerate,
    {
      prompt: text,
      imageSize: `size${imageSize}`,
      imageStyle: imageStyle,
    },
    config,
  );
};
