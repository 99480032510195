<template>
  <div class="loader" />
</template>

<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 0.3rem solid $color-fill-inactive;
  border-top: 0.3rem solid $color-fill-active;
  border-radius: 50%;
  width: 100%;
  animation: loading-spin 2s linear infinite;
  box-sizing: border-box;
}

.loader::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

@keyframes loading-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
