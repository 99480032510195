<template>
  <div class="va-field va-select">
    <label class="title-label">{{ computedLabel }}</label>
    <div class="dropdown">
      <select
        ref="dropdown"
        :data-testid="`select-${fieldModel.fieldName}`"
        class="dropdown-select"
        @change="select"
      >
        <option
          value
          disabled
          :selected="!computedData[fieldModel.fieldName]"
          hidden
        >
          {{ placeholder }}
        </option>
        <option
          v-for="(value, key) in computedOptions"
          :key="key"
          :value="key"
          :selected="computedData[fieldModel.fieldName] === key"
        >
          {{ value }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    placeholder() {
      var returnValue = this.fieldModel
        ? this.fieldModel.placeholder
          ? this.fieldModel.placeholder
          : `${this.$translate('generic.select')}...`
        : `${this.$translate('generic.select')}...`;
      return returnValue;
    },
    computedData() {
      return this.data;
    },
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },

    computedOptions: function () {
      let computedOptions = {};
      if (this.fieldModel.options) {
        Object.keys(this.fieldModel.options).map((key) => {
          if (
            typeof this.fieldModel.options[key] === 'object' &&
            this.fieldModel.options[key] !== null
          ) {
            if (this.fieldModel.options[key][this.$getUserlanguage()]) {
              computedOptions[key] =
                this.fieldModel.options[key][this.$getUserlanguage()];
              return computedOptions[key];
            } else {
              computedOptions[key] = this.fieldModel.options[key]['en_US'];
              return computedOptions[key];
            }
          } else {
            return (computedOptions[key] = this.fieldModel.options[key]);
          }
        });
      }
      return computedOptions;
    },
  },
  mounted() {
    if (!this.data[this.fieldModel.fieldName] && this.fieldModel.default) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = this.fieldModel.default;
      this.$emit('updatedData');
    }
  },
  methods: {
    select() {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = String(this.$refs.dropdown.value);
      this.editMode = false;
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-select {
  position: relative;
  text-align: left;

  .dropdown {
    display: inline-block;
    position: relative;
    height: 35px;
    line-height: 35px;
    border-radius: 20px;
    background: $color-input;
    width: 100%;
    overflow: hidden;

    .dropdown-select {
      cursor: pointer;
      color: $color-text;
      padding: 5px 8px;
      width: 100%;
      border: none;
      padding-right: 3rem;
      padding-left: 1rem;
      background: $color-input;
      box-shadow: none;
      background-image: none;
      appearance: none;

      option {
        background: $color-input;
        color: $color-text;
        border: 0;
      }

      &:focus {
        outline: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $color-fill-secondary;
      }
    }

    &::before {
      pointer-events: none;
      position: absolute;
      right: 0.8rem;
      top: 50%;
      top: calc(50% - 0.15rem);
      transform: translateY(-50%) rotate(45deg);
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      box-sizing: border-box;
      border-bottom: 2px solid $color-fill-secondary;
      border-right: 2px solid $color-fill-secondary;
      transition: border-color 0.2s ease-out;
    }

    &:hover {
      &::before {
        border-color: $color-fill;
      }
    }
  }
}
</style>
