<template>
  <va-modal
    data-testid="confirm-modal"
    :type="type"
    :width="width"
    @close="$emit('cancel')"
  >
    <template #header>
      <h3>
        {{ title }}
      </h3>
    </template>
    <template #body>
      <div>
        <p v-if="message" class="body-message">{{ message }}</p>
        <slot v-else name="body" />
      </div>
    </template>
    <template #footer>
      <div class="footer-buttons">
        <div>
          <slot name="extra-footer-button"></slot>
        </div>
        <!-- translate can not be used in props, it fallbacks to English -->
        <va-button
          data-testid="cancel-button"
          color="secondary"
          @click="
            $emit('cancel');
            $emit('secondaryConfirm');
          "
        >
          {{ cancelButton || translate('generic.cancel') }}
        </va-button>
        <va-button
          data-testid="confirm-button"
          :color="submitButtonColor"
          :loading="loading"
          :disabled="disabled"
          @click="$emit('confirm')"
        >
          {{ okButton || translate('generic.confirm') }}
        </va-button>
      </div>
    </template>
  </va-modal>
</template>

<script>
import VaModal from '@/components/framework/va-modal.vue';
import VaButton from '@/components/framework/VaButton.vue';
import { translate } from '@/helpers/translationHelper';

export default {
  components: {
    VaModal,
    VaButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    okButton: {
      type: String,
      default: '',
    },
    cancelButton: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
    width: {
      type: [String, Number],
      default: 800,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'confirm', 'secondaryConfirm'],
  computed: {
    submitButtonColor() {
      // Type can only be warning or confirm and defaults to confirm
      if (this.type === 'warning') {
        return 'danger';
      }
      return 'primary';
    },
  },
  methods: {
    translate,
  },
};
</script>

<style lang="scss" scoped>
.body-message {
  overflow-wrap: break-word;
}
</style>
