<template>
  <div class="va-select">
    <div
      ref="va-select-container"
      class="va-select-container"
      tabindex="0"
      aria-haspopup="listbox"
      aria-expanded="false"
      @keydown.down.prevent
      @keydown="onKeydown"
      @click="setShowOptions"
    >
      <div class="selected">
        <span v-if="selected">{{ selected }}</span>
        <span v-else class="placeholder">{{ placeholder }}</span>
      </div>
      <div class="chevrons">
        <div
          class="chevron up"
          data-testid="va-select--chevron-up"
          @click="onChevronClick('UP')"
        >
          <svg-icon icon="chevron" />
        </div>
        <div
          class="chevron down"
          data-testid="va-select--chevron-down"
          @click="onChevronClick('DOWN')"
        >
          <svg-icon icon="chevron" />
        </div>
      </div>
    </div>
    <va-popup
      v-if="showOptions"
      direction="bottom"
      :reference-element="$refs['va-select-container']"
      @close="showOptions = false"
    >
      <div v-if="options" ref="popup-content" class="va-select-options">
        <ul role="listbox">
          <li
            v-for="(option, index) in options"
            :id="`option${index}`"
            :key="option.value"
            tabindex="-1"
            role="option"
            :class="{ active: option.value === modelValue }"
            :data-testid="option.value"
            @keydown.prevent="onListKeyDown($event, option)"
            @click="onSelect(option)"
          >
            {{ option.label }}
          </li>
        </ul>
      </div>
    </va-popup>
  </div>
</template>

<script>
import VaPopup from '@/components/framework/va-popup.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';

export default {
  components: {
    VaPopup,
    SvgIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    reversUpAndDown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    selected() {
      if (this.options?.find((option) => option.value === this.modelValue)) {
        return this.options.find((option) => option.value === this.modelValue)
          .label;
      }
      return '';
    },
  },
  methods: {
    onKeydown(e) {
      if (e.key === 'Enter') {
        this.showOptions = !this.showOptions;
      } else if (e.key === 'ArrowDown') {
        this.showOptions = true;
        this.$nextTick(() => {
          const selectedOption = this.options.find(
            (option) => option.value === this.modelValue,
          );
          const activeOption = selectedOption
            ? this.options.indexOf(selectedOption)
            : 0;
          document.getElementById(`option${activeOption}`).focus();
        });
      } else if (e.key === 'Escape' || e.key === 'Tab') {
        this.showOptions = false;
      }
    },
    onListKeyDown(e, option) {
      if (e.key === 'ArrowDown') {
        const activeOption = document.activeElement;
        const nextOption = activeOption.nextElementSibling;
        if (nextOption) {
          nextOption.focus();
        }
      } else if (e.key === 'ArrowUp') {
        const activeOption = document.activeElement;
        const prevOption = activeOption.previousElementSibling;
        if (prevOption) {
          prevOption.focus();
        }
      } else if (e.key === 'Enter') {
        this.onSelect(option);
      } else if (e.key === 'Escape' || e.key === 'Tab') {
        this.showOptions = false;
      }
    },
    onSelect(option) {
      this.showOptions = false;
      this.$emit('update:modelValue', option.value);
    },
    onChevronClick(direction) {
      if (this.reversUpAndDown) {
        direction = direction === 'DOWN' ? 'UP' : 'DOWN';
      }

      if (
        this.options?.length > 1 &&
        this.options.some((option) => option.value === this.modelValue)
      ) {
        const selectedOption = this.options.find(
          (option) => option.value === this.modelValue,
        );
        const index = this.options.indexOf(selectedOption);
        if (direction === 'DOWN') {
          if (index + 1 < this.options.length) {
            this.onSelect(this.options[index + 1]);
          } else {
            this.onSelect(this.options[0]);
          }
        } else if (direction === 'UP') {
          if (index - 1 > -1) {
            this.onSelect(this.options[index - 1]);
          } else {
            this.onSelect(this.options[this.options.length - 1]);
          }
        }
      } else if (this.options?.length) {
        this.onSelect(this.options[0]);
      }
    },
    setShowOptions(e) {
      if (e.target !== this.$refs['va-select-container']) {
        return;
      }
      this.showOptions = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.va-select {
  display: inline-block;
  color: $color-text-secondary;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.35px;
  font-weight: normal;

  .va-select-container {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    padding: 0 10px;
    background-color: #4b4b51;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    // Set focus on the container when tabbing through the page
    &:focus-visible {
      outline: $color-border-active solid 2px;
    }

    .selected {
      color: $color-text;
      pointer-events: none;

      .placeholder {
        color: $color-text-secondary;
        user-select: none;
      }
    }

    .chevrons {
      height: 100%;

      .chevron {
        height: 50%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 5px;
        transition: color 0.2s ease-out;

        &:hover {
          color: $color-text;
        }

        &.up {
          transform: scaleY(-1);
        }
      }
    }
  }

  .va-select-options {
    position: absolute;
    margin-top: 0.2rem;
    background-color: $color-fill-secondary;
    right: 0;
    border-radius: 4px;
    width: 100%;
    max-height: 25vh;
    overflow-y: scroll;
    box-shadow: 0 3px 10px $color-box-shadow;

    ul {
      overflow: hidden;
      border-radius: 4px;
      padding: 0;
      margin: 0;

      li {
        box-sizing: border-box;
        position: relative;
        height: 40px;
        padding: 10px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        text-align: left;
        transition:
          background-color 0.2s ease-out,
          color 0.2s ease-out;

        &:focus,
        &:hover {
          background-color: $color-fill-secondary-hover;
          color: $color-text;
          cursor: pointer;
        }

        &:focus-visible {
          outline: $color-border-active solid 2px;
        }

        &.active {
          background-color: $color-fill-secondary-active;
          color: $color-text;

          &:focus,
          &:hover {
            background-color: $color-fill-secondary-active-hover;
          }
        }
      }
    }

    &::before {
      position: absolute;
      right: 10px;
      height: 0;
      width: 0;
      top: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #3a3a3f;
      background-color: transparent;
      content: '';
      overflow: hidden;
    }
  }
}
</style>
