<template>
  <draggable
    ref="draggable"
    :group="{ name: 'media' }"
    handle=".draggable-handle"
    :force-fallback="true"
    class="va-media-draggable"
    @add="onDropMedia"
  >
    <div ref="va-file-uploader" class="dropzone">
      <slot />
    </div>
  </draggable>
</template>

<script>
import { userComputed } from '@/services/auth.service';
import VaDraggable from '@/components/framework/VaDraggable.vue';
import Dropzone from 'dropzone';
import { withAsync } from '@/helpers/withAsync';
import { uploadUnsplashMedia } from '@/api/mediaApi';
import ErrorMessageHelper from '@/helpers/errorMessageHelper.js';
import { mediaTypesAccepted } from '@/constants/mediaConstants';

export default {
  components: {
    draggable: VaDraggable,
  },
  props: {
    uploadEnabled: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    mediaLibrary: {
      type: String,
      default: '',
    },
    acceptedFiles: {
      type: String,
      default: '',
    },
    allowOverwrite: {
      type: Boolean,
      default: false,
    },
    rename: {
      type: String,
      default: '',
    },
  },
  emits: ['mediaUploadOk', 'mediaAddedFromLibrary', 'sendingMedia'],
  data() {
    return {
      messages: [],
      messageTime: 6000,
      defaultAcceptedFiles: mediaTypesAccepted,
    };
  },
  computed: {
    ...userComputed,
  },
  mounted() {
    this.setupDropzone();
  },
  beforeUnmount() {
    this.$refs['va-file-uploader'].removeEventListener(
      'dragenter',
      this.addDragClass,
    );
    this.$refs['va-file-uploader'].removeEventListener(
      'dragleave',
      this.removeDragClass,
    );
    this.$refs['va-file-uploader'].removeEventListener(
      'mouseleave',
      this.removeDragClass,
    );
  },
  methods: {
    setupDropzone() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      Dropzone.autoDiscover = false;

      const dropZoneOptions = {
        url:
          this.$getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
          `/api/media/upload${
            '?allowOverwrite=' +
            this.allowOverwrite +
            (this.mediaLibrary ? '&mediaLibrary=' + this.mediaLibrary : '')
          }`,
        clickable: this.clickable,
        // If acceptedFiles are empty we set default file types here.
        // Some components sends in empty sting as default's and then all file types are allowed. Backend can´t handle all file types and we don´t want to allow all file types.
        acceptedFiles: this.acceptedFiles || this.defaultAcceptedFiles,
        autoProcessQueue: true,
        dictDefaultMessage: '',
        createImageThumbnails: false,
        timeout: 4200000, // 70 minutes
        previewTemplate: '<div style="display:none"></div>',
        dictInvalidFileType: 'invalidFileType',
        maxFilesize: 524288000, //500mb
        uploadMultiple: true,
        parallelUploads: 5,
        renameFile: function (file) {
          if (that.rename) {
            return that.rename;
          }
          return file.name;
        },
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          Authorization: `Bearer ${this.user?.access_token}`,
        },
        init: function () {
          this.on('sending', that.onSending);
          this.on('uploadprogress', that.onUploadProgress);
          this.on('success', that.onSuccess);
          this.on('error', that.onError);
        },
      };
      if (this.uploadEnabled) {
        new Dropzone(this.$refs['va-file-uploader'], dropZoneOptions);
        this.$refs['va-file-uploader'].addEventListener(
          'dragenter',
          this.addDragClass,
        );
        this.$refs['va-file-uploader'].addEventListener(
          'dragleave',
          this.removeDragClass,
        );
        this.$refs['va-file-uploader'].addEventListener(
          'mouseleave',
          this.removeDragClass,
        );
      }
    },
    addDragClass() {
      this.$refs['va-file-uploader'].classList.add('drag-active');
    },
    removeDragClass() {
      this.$refs['va-file-uploader'].classList.remove('drag-active');
    },
    async onDropMedia(e) {
      if (!this.uploadEnabled) {
        return;
      }

      if (e.item.getAttribute('fromUnsplash')) {
        const { response } = await withAsync(
          uploadUnsplashMedia,
          e.item,
          this.$translate,
        );
        if (response?.data) {
          this.$emit('mediaUploadOk', response.data.mediaCode);
        }
      } else {
        this.$emit(
          'mediaAddedFromLibrary',
          e.item.getAttribute('data-media-code'),
        );
      }
    },

    sendMessage(action, { id, text, type, progress, time } = {}) {
      this.$store.dispatch(action, {
        message: {
          text: text,
          type: type,
          progress: progress,
        },
        id: id,
        time: time,
      });
    },
    addMessage(text, type, { id, progress, time } = {}) {
      this.sendMessage('addMessage', {
        text: text,
        type: type,
        id: id,
        progress: progress,
        time: time,
      });
    },
    editMessage(id, { text, type, progress } = {}) {
      this.sendMessage('editMessage', {
        id: id,
        text: text,
        type: type,
        progress: progress,
      });
    },
    removeMessage(id) {
      this.sendMessage('removeMessage', { id: id });
    },
    onSending(file) {
      const id = file.upload.uuid;
      this.$emit('sendingMedia', id);
      this.loading = true;
      this.messages.push({ id: id });
      this.addMessage(
        this.$translate('media.uploading') + ' ' + file.upload.filename,
        'info',
        {
          id: id,
        },
      );
    },
    onUploadProgress(file, progress) {
      const message = this.messages.find((x) => x.id === file.upload.uuid);
      this.editMessage(message.id, { progress: progress });
    },
    onSuccess(file, response) {
      this.loading = false;
      let message = this.messages.find((x) => x.id === file.upload.uuid);

      if (response.success.ok) {
        if (response.Medias) {
          const media = response.Medias.find(
            (m) => m.displayName === file.upload.filename,
          );
          const error = media.errorMessage ? true : false;
          // Error
          // (errors are sent with success codes in multiple file uploads)
          if (error) {
            this.editMessage(message.id, {
              text: `${
                file.upload.filename
              }: ${ErrorMessageHelper.renderErrorMessage(media.errorMessage)}`,
              type: 'error',
            });
            setTimeout(this.removeMessage, this.messageTime, message.id);
            this.messages.splice(
              this.messages.findIndex((x) => x.id === message.id),
              1,
            );
            return;
          }
          // Success
          if (message.id) {
            this.editMessage(message.id, {
              text:
                this.$translate('media.uploaded') + ' ' + file.upload.filename,
              type: 'success',
            });
            setTimeout(this.removeMessage, this.messageTime, message.id);
            this.messages.splice(
              this.messages.findIndex((x) => x.id === message.id),
              1,
            );
          } else {
            this.addMessage(
              this.$translate('media.uploaded') + ' ' + file.upload.filename,
              'success',
            );
          }

          const mediaCode = response.Medias.find(
            (media) => media.displayName === file.upload.filename,
          )?.mediaCode;

          if (mediaCode) {
            this.$emit('mediaUploadOk', mediaCode, file.upload.uuid);
          }
        }
      } else {
        if (message.id) {
          this.editMessage(message.id, {
            text: `${
              file.upload.filename
            }: ${ErrorMessageHelper.renderErrorMessage(response)}`,
            type: 'error',
          });
          setTimeout(this.removeMessage, this.messageTime, message.id);
          this.messages.splice(
            this.messages.findIndex((x) => x.id === message.id),
            1,
          );
        } else {
          this.addMessage(
            `${file.upload.filename}: ${ErrorMessageHelper.renderErrorMessage(
              response,
            )}`,
            'error',
            { time: this.messageTime },
          );
        }
      }
    },
    onError(file, error) {
      this.loading = false;

      let errorMessage;
      if (error === 'invalidFileType') {
        errorMessage = this.$translate(
          'media.error.invalidFileType',
          this.acceptedFiles || this.defaultAcceptedFiles,
        );
      } else {
        try {
          errorMessage = ErrorMessageHelper.renderErrorMessage(
            JSON.parse(error),
          );
        } catch (e) {
          console.error('Failed to parse JSON:', e);
          errorMessage = this.$translate('media.error.upload');
        }
      }

      const message = this.messages.find((x) => x.id === file.upload.uuid);
      if (message?.id) {
        this.editMessage(message.id, { text: errorMessage, type: 'error' });
        setTimeout(this.removeMessage, this.messageTime, message.id);
        this.messages.splice(
          this.messages.findIndex((x) => x.id === message.id),
          1,
        );
      } else {
        this.addMessage(`${file.upload.filename}: ${errorMessage}`, 'error', {
          id: `uploading-file-error-${file.upload.filename}`,
          time: this.messageTime,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  height: 170px;
  border-radius: 20px;
  background-color: $color-bg;
  border: dashed 2px $color-border;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: filter 0.2s ease-out;

  &.drag-active,
  &.dz-drag-hover,
  &:hover {
    filter: brightness(90%);
  }

  :deep() {
    .dz-default {
      display: none;
    }
  }

  :deep(.drop-text) {
    pointer-events: none;
    user-select: none;
  }

  :deep(i.drop-text) {
    width: 34.2px;
    height: 34.2px;
    margin-bottom: 12px;
  }

  :deep(h3.drop-text) {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: left;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 10px;
  }

  :deep(p.drop-text) {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    text-align: center;
    margin-top: 3px;
    letter-spacing: 0.6px;
  }
}
</style>
