<template>
  <div class="svg-icon" role="button" @click="$emit('click')">
    <div
      ref="svgicon"
      class="icon-container"
      @mouseover="onMouseOver"
      @mouseleave="showTitle = false"
    >
      <div v-if="loading" class="va-loader-container">
        <va-loader />
      </div>
      <component
        :is="icon"
        v-else
        ref="icon"
        :class="color"
        class="icon"
        :data-testid="`svg-icon--${icon}`"
      />
    </div>
    <!-- Tooltip #12910-->
    <va-popup
      v-if="showTitle && title && !loading"
      :reference-element="$refs.svgicon"
      direction="top"
      class="icon-title-popup"
    >
      <div ref="popup-content" class="icon-title" :style="titleStyle">
        <div v-if="Array.isArray(title)">
          <div v-for="t in title" :key="t">
            {{ t }}
          </div>
        </div>
        <div v-else>
          {{ title }}
        </div>
      </div>
    </va-popup>
  </div>
</template>

<script>
import VaPopup from '@/components/framework/va-popup.vue';
import VaLoader from '@/components/framework/va-loader.vue';
// Icons
import Calendar from './CalendarIcon.vue';
import Checkmark from './CheckmarkIcon.vue';
import CheckmarkSolidBackground from './CheckmarkSolidBackgroundIcon.vue';
import Cross from './CrossIcon.vue';
import CrossSolidBackground from './CrossSolidBackgroundIcon.vue';
import Play from './PlayIcon.vue';
import Pause from './PauseIcon.vue';
import Power from './PowerIcon.vue';
import Broadcast from './BroadcastIcon.vue';
import Screens from './ScreensIcon.vue';
import MediaLibrary from './MediaLibraryIcon.vue';
import ShareOfVoice from './ShareOfVoiceIcon.vue';
import Settings from './SettingsIcon.vue';
import Help from './HelpIcon.vue';
import Users from './UsersIcon.vue';
import User from './UserIcon.vue';
import Add from './AddIcon.vue';
import AddOutlined from './AddOutlineIcon.vue';
import Remove from './RemoveIcon.vue';
import Options from './OptionsIcon.vue';
import Drag from './DragIcon.vue';
import Reorder from './ReorderIcon.vue';
import Chevron from './ChevronIcon.vue';
import Search from './SearchIcon.vue';
import Close from './CloseIcon.vue';
import Warning from './WarningIcon.vue';
import Grid from './GridIcon.vue';
import GridBar from './GridBarIcon.vue';
import List from './ListIcon.vue';
import Dot from './CircleIcon.vue';
import Sort from './SortIcon.vue';
import FilterIcon from './FilterIcon.vue';
import SupportLogin from './SupportLoginIcon.vue';
import TriggerRobot from './TriggerRobotIcon.vue';
import Dayparts from './DaypartsIcon.vue';
import Timer from './TimerIcon.vue';
import Trigger from './TriggerIcon.vue';
import Logout from './LogoutIcon.vue';
import Bold from './BoldIcon.vue';
import Italic from './ItalicIcon.vue';
import Strikethrough from './StrikethroughIcon.vue';
import Underline from './UnderlineIcon.vue';
import LeftAlign from './LeftAlignIcon.vue';
import CenterAlign from './CenterAlignIcon.vue';
import RightAlign from './RightAlignIcon.vue';
import Indent from './IndentIcon.vue';
import Outdent from './OutdentIcon.vue';
import NumberedList from './NumberedListIcon.vue';
import BulletList from './BulletListIcon.vue';
import HorizontalRule from './HorizontalRuleIcon.vue';
import Quote from './QuoteIcon.vue';
import Copy from './CopyIcon.vue';
import Nested from './NestedIcon.vue';
import Item from './ItemIcon.vue';
import BroadcastCircle from './BroadcastCircleIcon.vue';
import ReorderGrid from './ReorderGridIcon.vue';
import Break from './BreakIcon.vue';
import OptionsCircle from './OptionsCircleIcon.vue';
import CustomPlayerStatus from './CustomPlayerStatusIcon.vue';
import GeoTag from './GeoTagIcon.vue';
import External from './ExternalIcon.vue';
import Language from './LanguageIcon.vue';
import Account from './AccountIcon.vue';
import SwitchArrows from './SwitchIcon.vue';
import Tag from './TagIcon.vue';
import Refresh from './RefreshIcon.vue';
import Download from './DownloadIcon.vue';
import Pin from './PinIcon.vue';
import Unpin from './UnpinIcon.vue';

export default {
  components: {
    VaPopup,
    VaLoader,
    Calendar,
    Checkmark,
    CheckmarkSolidBackground,
    Cross,
    CrossSolidBackground,
    Play,
    Pause,
    Power,
    Broadcast,
    Screens,
    MediaLibrary,
    ShareOfVoice,
    Settings,
    Help,
    Users,
    User,
    Add,
    AddOutlined,
    Remove,
    Options,
    Drag,
    Reorder,
    Chevron,
    Search,
    Close,
    Warning,
    Grid,
    GridBar,
    List,
    Dot,
    Sort,
    FilterIcon,
    SupportLogin,
    Bold,
    Italic,
    Strikethrough,
    Underline,
    LeftAlign,
    CenterAlign,
    RightAlign,
    Indent,
    Outdent,
    NumberedList,
    BulletList,
    HorizontalRule,
    Quote,
    TriggerRobot,
    Dayparts,
    Timer,
    Trigger,
    Logout,
    Copy,
    Nested,
    Item,
    BroadcastCircle,
    ReorderGrid,
    Break,
    OptionsCircle,
    CustomPlayerStatus,
    GeoTag,
    External,
    Language,
    Account,
    SwitchArrows,
    Tag,
    Refresh,
    Download,
    Pin,
    Unpin,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: [String, Array],
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ['click'],
  data() {
    return {
      showTitle: false,
      titleStyle: {
        left: '50%',
        right: 'auto',
        transform: 'translateX(-50%)',
      },
    };
  },
  mounted() {
    // Clear svg styling
    if (this.icon) {
      this.$refs.icon.$el.removeAttribute('height');
      this.$refs.icon.$el.removeAttribute('width');
    }
  },
  methods: {
    onMouseOver() {
      this.showTitle = true;
      if (this.title) {
        this.$nextTick(() => {
          if (this.$refs['popup-content']) {
            var rect = this.$refs['popup-content'].getBoundingClientRect();
            if (rect.x < 0) {
              this.titleStyle.left = 0;
              this.titleStyle.right = 'auto';
              this.titleStyle.transform = 'none';
            } else if (rect.x + rect.width > window.innerWidth) {
              this.titleStyle.left = 'auto';
              this.titleStyle.right = 0;
              this.titleStyle.transform = 'none';
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-flex !important;
  transition: color 0.2s ease-out;

  &.clickable {
    cursor: pointer;
  }

  &.flipped-y {
    transform: scaleY(-1);
  }

  &.hover-effect-highlight:hover {
    color: $color-text;
  }

  .icon-container {
    position: relative;
    display: inline-flex;

    .va-loader-container {
      position: absolute;
      height: 100%;
      top: 0;
      width: 22px;
    }
  }

  .icon-title-popup {
    pointer-events: none;
  }

  .icon-title {
    white-space: nowrap;
    padding: 6px 10px;
    border-radius: 4px;
    background-color: $color-grey-400;
    color: $color-text-secondary;
    font-size: 14px;
    letter-spacing: 0.35px;
    line-height: 19px;
    position: absolute;
    left: 50%;
    bottom: -0.5rem;
    transform: translateX(-50%);
    text-align: left;
  }

  .icon {
    width: 1em;
    height: 1em;
    overflow: visible;
  }

  .dark-grey {
    fill: $color-grey-300;
    color: $color-grey-300;
  }

  .grey {
    fill: $color-grey-100;
    color: $color-grey-100;
  }

  .white {
    fill: $color-grey-50;
    color: $color-grey-50;
  }

  .green {
    fill: $color-green;
    color: $color-green;
  }

  .pink {
    fill: $color-red;
    color: $color-red;
  }

  .sec-white {
    :deep(.secondary) {
      fill: $color-grey-50;
      color: $color-grey-50;
    }
  }

  .turquoise {
    fill: $color-aqua;
    color: $color-aqua;
  }

  .purple {
    fill: $color-purple;
    color: $color-purple;
  }

  .orange {
    fill: $color-yellow;
    color: $color-yellow;
  }

  .blue {
    fill: $color-light-blue;
    color: $color-light-blue;
  }

  .blueberry {
    fill: $color-blue;
    color: $color-blue;
  }
}
</style>
