<template>
  <div class="va-field va-input">
    <va-field
      :is-view-mode="!editMode"
      :is-empty="!computedData[fieldModel.fieldName]"
    >
      <template #field-data>
        <input
          ref="vainput"
          type="text"
          class="value"
          :value="computedData[fieldModel.fieldName] || ''"
          :maxlength="maxLength"
          :data-testid="`input-${fieldModel.id || fieldModel.fieldName}`"
          autocomplete="off"
          @click="editMode = true"
          @blur="editMode = false"
          @input="onInput"
        />
      </template>

      <template #field-label>
        {{ computedLabel }}
      </template>
    </va-field>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    computedData() {
      return this.data;
    },
    computedLabel() {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    maxLength() {
      if (this.fieldModel.maxLength) {
        return this.fieldModel.maxLength;
      }
      return '';
    },
  },
  methods: {
    onInput(e) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = e.target.value;
      this.$emit('updatedData', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.va-input {
  input {
    width: calc(100% - 1rem);
    font-size: 0.8rem;
    color: inherit;
    padding: 0;
    border: 0;
    background-color: inherit;

    &:focus {
      outline: none;
    }
  }

  .value {
    word-break: break-all;
    color: $color-text;
    min-height: 1rem;
    padding: 1.2rem 0.5rem 0.5rem 0.8rem;
  }

  &.error .value {
    color: $color-text-danger !important;
  }
}
</style>
