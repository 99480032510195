import PeriodsHelper from '@/helpers/periods-helper';
import DateTimeHelper from './dateTimeHelper';
import { dateToLocaleString, timeToLocaleString } from '@/helpers/localization';
import { removeEmptyNodes } from './filterHelper/filterHelper';
import { BroadcastType } from '@/views/broadcasts/enums/broadcastType';

const mapNewBroadcast = (inputValues) => {
  let broadcastGroups = [];
  let periods = [];

  inputValues.groups.forEach((g) => {
    let mediaToAdd = [];
    if (g.media && g.media.length > 0) {
      g.media.forEach((mediaItem) => {
        mediaToAdd.push(mapMediaObject(mediaItem, true));
      });
    }

    const group = {
      Name: g.name,
      BroadcastGroupCode: null,
      Layers: {
        LayerCodes: g.layers.map((l) => ({ LayerCode: l.layerCode })),
      },
      Media: {
        AddedMedia: mediaToAdd,
        ModifiedMedia: [],
        RemovedMedia: [],
      },
      Filter: { ...g.filter, Condition: removeEmptyNodes(g.filter.Condition) },
      Tags: {
        AddedGroupTags: g.chosenContentTypeTagCode
          ? [{ tagCode: g.chosenContentTypeTagCode, tagType: 'ContentType' }]
          : [],
        RemovedGroupTags: [],
      },
      orderN: g.orderN,
    };
    if (
      inputValues.broadcastType === BroadcastType.SHARE_OF_VOICE ||
      inputValues.broadcastType === BroadcastType.FILLER
    ) {
      group.spotLength = g.spotLength;
    }
    broadcastGroups.push(group);
  });

  inputValues.schedule.periods.forEach((p) => {
    let period = { ...p, periodCode: null };
    periods.push(period);
  });

  let requestPayload = {
    Broadcast: {
      BroadcastName: inputValues.broadcastName,
      Status:
        inputValues.schedule && inputValues.schedule.status
          ? inputValues.schedule.status
          : 'Draft',
      IsTakeover: inputValues.schedule.isTakeover,
      IsInteractive: inputValues.isInteractive,
    },
    BroadcastGroups: broadcastGroups,
    Periods: {
      AddedPeriods: periods,
      ModifiedPeriods: [],
      RemovedPeriods: [],
    },
  };

  requestPayload.Broadcast.BroadcastTriggers = inputValues.triggers?.map(
    (trigger) => ({
      TriggerCode: trigger.triggerCode,
      TriggerActionType: trigger.actionType,
    }),
  );

  return requestPayload;
};

const mapDifferenceInBroadcasts = (initialValues, currentValues) => {
  let broadcastGroups = [];

  currentValues.groups.forEach((g) => {
    let initialGroup = initialValues.groups.find((ig) => {
      return ig.key === g.key;
    });
    const group = {
      Name: g.name,
      BroadcastGroupCode: g.code ? g.code : null,
      Layers: {
        LayerCodes: g.layers.map((l) => ({ LayerCode: l.layerCode })),
      },
      Media: calculateMediaDifferences(initialGroup, g),
      Filter: { ...g.filter, Condition: removeEmptyNodes(g.filter.Condition) },
      Players: {
        AddedPlayers: [],
        RemovedPlayers: [],
      },
      Tags: calculateTagDifferences(
        initialGroup?.chosenContentTypeTagCode,
        g.chosenContentTypeTagCode,
      ),
      orderN: g.orderN,
    };

    if (
      currentValues.broadcastType === BroadcastType.SHARE_OF_VOICE ||
      currentValues.broadcastType === BroadcastType.FILLER
    ) {
      group.spotLength = g.spotLength;
    }

    broadcastGroups.push(group);
  });

  let requestPayload = {
    Broadcast: {
      BroadcastName: currentValues.broadcastName,
      Status:
        currentValues.schedule && currentValues.schedule.status
          ? currentValues.schedule.status
          : 'Draft',
      IsTakeover: currentValues.schedule.isTakeover,
      IsInteractive: currentValues.isInteractive,
    },
    BroadcastGroups: broadcastGroups,
    Periods: calculatePeriodDifferences(
      initialValues.schedule.periods,
      currentValues.schedule.periods,
    ),
  };

  requestPayload.Broadcast.BroadcastTriggers = currentValues.triggers?.map(
    (trigger) => ({
      TriggerCode: trigger.triggerCode,
      TriggerActionType: trigger.actionType,
    }),
  );

  return requestPayload;
};

const mapMediaObject = (inMediaItem, newBroadcast = false) => {
  if (!inMediaItem) {
    return {};
  }

  if (newBroadcast && inMediaItem.periods) {
    inMediaItem.periods = inMediaItem.periods.map((p) => ({
      ...p,
      periodCode: null,
    }));
  }

  let mediaObject = {
    MediaCode: inMediaItem.mediaCode,
    OrderN: inMediaItem.orderN,
    Duration: inMediaItem.duration ? inMediaItem.duration : '00:00:10',
    Status: inMediaItem.status,
    Periods: {
      AddedPeriods: inMediaItem.periods ? inMediaItem.periods : [],
      ModifiedPeriods: [],
      RemovedPeriods: [],
    },
  };

  mediaObject.MediaTriggers =
    inMediaItem.triggers?.map((t) => ({
      TriggerCode: t.triggerCode,
      TriggerActionType: t.actionType,
    })) || [];

  return mediaObject;
};

const mapMediaObjectWithId = (inMediaItem) => {
  if (!inMediaItem) {
    return {};
  }

  let mediaObject = {
    Id: inMediaItem.broadcastMediaCode,
    MediaCode: inMediaItem.mediaCode,
    OrderN: inMediaItem.orderN,
    Duration: inMediaItem.duration ? inMediaItem.duration : '00:00:10',
    Status: inMediaItem.status,
    Periods: inMediaItem.periodModification
      ? inMediaItem.periodModification
      : [],
  };

  mediaObject.MediaTriggers =
    inMediaItem.triggers?.map((t) => ({
      TriggerCode: t.triggerCode,
      TriggerActionType: t.actionType,
    })) || [];

  return mediaObject;
};

const calculateMediaDifferences = (initialValues, currentValues) => {
  const returnPlayerDifferences = {
    AddedMedia: [],
    ModifiedMedia: [],
    RemovedMedia: [],
  };

  // If there is no initialValues put all media in added
  if (!initialValues) {
    currentValues.media.forEach((currentMediaItem) => {
      returnPlayerDifferences['AddedMedia'].push(
        mapMediaObject(currentMediaItem),
      );
    });
    return returnPlayerDifferences;
  }

  // Make a copy of both the initial and the current values
  let initialValuesCopy = JSON.parse(JSON.stringify(initialValues));
  let currentValuesCopy = JSON.parse(JSON.stringify(currentValues));

  // If the currentValue is not found in the initialValue, we'll add it to the RemovedMedia category
  initialValuesCopy.media.forEach((initialMediaItem) => {
    if (
      !currentValuesCopy.media
        .map((mediaItem) => mediaItem.hashCode)
        .includes(initialMediaItem.hashCode)
    ) {
      returnPlayerDifferences['RemovedMedia'].push(
        initialMediaItem.broadcastMediaCode,
      );
    }
  });

  // Now going to determine if a currentVal is:
  // 1.) added
  // 2.) modified
  // 3.) or has not change

  // Going to use pop because there could be duplicates in the currentValues

  currentValuesCopy.media.forEach((currentMediaItem) => {
    if (
      !initialValuesCopy.media
        .map((mediaItem) => mediaItem.mediaCode)
        .includes(currentMediaItem.mediaCode) ||
      !initialValuesCopy.media
        .map((mediaItem) => mediaItem.broadcastMediaCode)
        .includes(currentMediaItem.broadcastMediaCode)
    ) {
      returnPlayerDifferences['AddedMedia'].push(
        mapMediaObject(currentMediaItem),
      );
    } else {
      let { isModified, foundItem } = getMatchingMediaItem(
        currentMediaItem,
        initialValuesCopy.media,
      );
      if (isModified) {
        currentMediaItem.broadcastMediaCode = foundItem.broadcastMediaCode;
        currentMediaItem.periodModification = foundItem.periodModification;
        returnPlayerDifferences['ModifiedMedia'].push(
          mapMediaObjectWithId(currentMediaItem),
        );
      }
    }
  });

  return returnPlayerDifferences;
};
const calculateTagDifferences = (
  initialContentType = '',
  currentContentType = '',
) => {
  if (!initialContentType) {
    return {
      AddedGroupTags: currentContentType
        ? [{ tagCode: currentContentType, tagType: 'ContentType' }]
        : [],
      RemovedGroupTags: [],
    };
  } else if (initialContentType === currentContentType) {
    return {
      AddedGroupTags: [],
      RemovedGroupTags: [],
    };
  } else if (!currentContentType) {
    return {
      AddedGroupTags: [],
      RemovedGroupTags: [
        { tagCode: initialContentType, tagType: 'ContentType' },
      ],
    };
  }

  return {
    AddedGroupTags: [{ tagCode: currentContentType, tagType: 'ContentType' }],
    RemovedGroupTags: [{ tagCode: initialContentType, tagType: 'ContentType' }],
  };
};

const calculateTriggerDifferences = (initialValues, currentValues) => {
  const returnTriggerDifferences = {
    AddedTriggers: [],
    ModifiedTriggers: [],
    RemovedTriggers: [],
  };

  // If there is no initialValue put all media in added
  if (!initialValues) {
    currentValues.forEach((trigger) => {
      returnTriggerDifferences['AddedTriggers'].push(trigger.triggerCode);
    });
    return returnTriggerDifferences;
  }

  // Make a copy of both the initial and the current values
  let initialValuesCopy = JSON.parse(JSON.stringify(initialValues));
  let currentValuesCopy = JSON.parse(JSON.stringify(currentValues));

  // If the currentValue is not found in the initialValue, we'll add it to the RemovedMedia category
  initialValuesCopy.forEach((initalTriggerItem) => {
    if (
      !currentValuesCopy
        .map((triggerItem) => triggerItem.triggerCode)
        .includes(initalTriggerItem.triggerCode)
    ) {
      returnTriggerDifferences['RemovedTriggers'].push(
        initalTriggerItem.triggerCode,
      );
    }
  });

  // Now going to determine if a currentVal is:
  // 1.) added
  // 2.) modified
  // 3.) or has not change

  // Going to use pop because there could be duplicates in the currentValues

  currentValuesCopy.forEach((currentTrigger) => {
    if (
      !initialValuesCopy
        .map((trigger) => trigger.triggerCode)
        .includes(currentTrigger.triggerCode)
    ) {
      returnTriggerDifferences['AddedTriggers'].push(
        currentTrigger.triggerCode,
      );
    } else {
      let initialActionType = initialValuesCopy.find(
        (trigger) => trigger.triggerCode === currentTrigger.triggerCode,
      )?.actionType;
      if (
        initialActionType &&
        initialActionType !== currentTrigger.actionType
      ) {
        returnTriggerDifferences['ModifiedTriggers'].push(
          currentTrigger.triggerCode,
        );
      }
    }
  });

  return returnTriggerDifferences;
};

const calculatePeriodDifferences = (initialValues, currentValues) => {
  const returnPeriodDifferences = {
    AddedPeriods: [],
    ModifiedPeriods: [],
    RemovedPeriods: [],
  };

  // Make a copy of both the initial and the current values
  const initialValuesCopy = JSON.parse(JSON.stringify(initialValues));
  const currentValuesCopy = JSON.parse(JSON.stringify(currentValues));

  // If the currentValue is not found in the initialValue, we'll add it to the RemovedMedia category
  initialValuesCopy.forEach((initialPeriod) => {
    if (
      !currentValuesCopy
        .map((period) => period.periodCode)
        .includes(initialPeriod.periodCode)
    ) {
      // Null check
      if (initialPeriod.periodCode) {
        returnPeriodDifferences['RemovedPeriods'].push(
          initialPeriod.periodCode,
        );
      }
    }
  });

  // Now going to determine if a currentVal is:
  // 1.) added
  // 2.) modified
  // 3.) or has not change

  currentValuesCopy.forEach((currentPeriod) => {
    if (!currentPeriod.periodCode) {
      // if all is not empty, push to added
      if (
        currentPeriod.startDateTime ||
        currentPeriod.endDateTime ||
        currentPeriod.weekdayStartTime ||
        currentPeriod.weekdayEndTime ||
        currentPeriod.dayOfWeeks
      ) {
        returnPeriodDifferences['AddedPeriods'].push(currentPeriod);
      }
    } else if (
      !initialValuesCopy
        .map((period) => period.periodCode)
        .includes(currentPeriod.periodCode)
    ) {
      // if all is not empty, push to added
      if (
        currentPeriod.startDateTime ||
        currentPeriod.endDateTime ||
        currentPeriod.weekdayStartTime ||
        currentPeriod.weekdayEndTime ||
        currentPeriod.dayOfWeeks
      ) {
        returnPeriodDifferences['AddedPeriods'].push(currentPeriod);
      }
    } else {
      let { isModified, foundItem } = getMatchingPeriod(
        currentPeriod,
        initialValuesCopy,
      );
      if (isModified) {
        currentPeriod.periodCode = foundItem.periodCode;
        // if all is empty, push to removed
        if (
          !currentPeriod.startDateTime &&
          !currentPeriod.endDateTime &&
          !currentPeriod.weekdayStartTime &&
          !currentPeriod.weekdayEndTime &&
          !currentPeriod.dayOfWeeks
        ) {
          returnPeriodDifferences['RemovedPeriods'].push(
            currentPeriod.periodCode,
          );
        } else {
          // else push to modified
          returnPeriodDifferences['ModifiedPeriods'].push(currentPeriod);
        }
      }
    }
  });

  return returnPeriodDifferences;
};

const getMatchingMediaItem = (item, targetArray) => {
  if (!item || !targetArray) {
    return false;
  }

  let isModified = false;
  let foundIdx;
  let foundItemInTargetArray = targetArray.find((arrayItem, idx) => {
    if (item.broadcastMediaCode === arrayItem.broadcastMediaCode) {
      foundIdx = idx;
      return arrayItem;
    }
  });
  if (
    foundItemInTargetArray.duration != item.duration ||
    foundItemInTargetArray.orderN != item.orderN ||
    foundItemInTargetArray.isTakeover != item.isTakeover ||
    foundItemInTargetArray.status != item.status
  ) {
    isModified = true;
  }
  foundItemInTargetArray.periodModification = calculatePeriodDifferences(
    foundItemInTargetArray.periods,
    item.periods,
  );
  if (
    foundItemInTargetArray.periodModification.AddedPeriods.length ||
    foundItemInTargetArray.periodModification.ModifiedPeriods.length ||
    foundItemInTargetArray.periodModification.RemovedPeriods.length
  ) {
    isModified = true;
  }
  foundItemInTargetArray.triggersModification = calculateTriggerDifferences(
    foundItemInTargetArray.triggers,
    item.triggers,
  );
  if (
    foundItemInTargetArray.triggersModification.AddedTriggers.length ||
    foundItemInTargetArray.triggersModification.ModifiedTriggers.length ||
    foundItemInTargetArray.triggersModification.RemovedTriggers.length
  ) {
    isModified = true;
  }

  return {
    isModified: isModified,
    foundItem: foundItemInTargetArray,
    foundIdx: foundIdx,
  };
};

const getMatchingPeriod = (item, targetArray) => {
  if (!item || !targetArray) {
    return false;
  }

  let isModified = false;
  let foundIdx;
  let foundItemInTargetArray = targetArray.find((arrayItem, idx) => {
    if (item.periodCode === arrayItem.periodCode) {
      foundIdx = idx;
      return arrayItem;
    }
  });
  if (
    foundItemInTargetArray.startDateTime != item.startDateTime ||
    foundItemInTargetArray.endDateTime != item.endDateTime ||
    foundItemInTargetArray.weekdayStartTime != item.weekdayStartTime ||
    foundItemInTargetArray.weekdayEndTime != item.weekdayEndTime ||
    foundItemInTargetArray.dayOfWeeks != item.dayOfWeeks
  ) {
    isModified = true;
  }

  return {
    isModified: isModified,
    foundItem: foundItemInTargetArray,
    foundIdx: foundIdx,
  };
};

const hasDefaultScheduling = (item) => {
  if (item.periods && item.periods.length > 1) {
    return false;
  } else if (
    !item.periods[0].startDateTime &&
    !item.periods[0].endDateTime &&
    !item.periods[0].weekdayStartTime &&
    !item.periods[0].weekdayEndTime &&
    item.periods[0].dayOfWeeks == 127 &&
    item.status == 'Confirmed'
  ) {
    return true;
  }

  return false;
};

const hasScheduling = (item) => {
  if (item.periods && item.periods.length > 1) {
    return true;
  }
  if (
    item.periods[0].startDateTime == '' ||
    item.periods[0].endDateTime == '' ||
    item.periods[0].weekdayStartTime == '' ||
    item.periods[0].weekdayEndTime == '' ||
    item.periods[0].dayOfWeeks == 127 ||
    item.status == 'Confirmed'
  ) {
    return true;
  }

  return false;
};

export const getStatusIcon = (app, item) => {
  const $translate = app.$translate;
  const periodsInText = PeriodsHelper.getPeriodsInText(app, item.periods);
  let statusIcon = {
    icon: '',
    color: '',
    status: '',
  };
  if (item.scheduleStatus === 'Expired') {
    statusIcon.icon = 'calendar';
    statusIcon.color = 'grey';
    statusIcon.status = [
      $translate('icons.status.expiredScheduling'),
      ...periodsInText,
    ];
  } else if (item.status === 'Draft' && item.scheduleStatus === 'Always') {
    statusIcon.icon = 'pause';
    statusIcon.color = 'pink';
    statusIcon.status = $translate('icons.status.paused');
  } else if (item.status === 'Draft') {
    statusIcon.icon = 'calendar';
    statusIcon.color = 'pink';
    statusIcon.status = [
      $translate('icons.status.pausedScheduling'),
      ...periodsInText,
    ];
  } else if (item.scheduleStatus === 'Always') {
    statusIcon.icon = 'play';
    statusIcon.color = 'green';
    statusIcon.status = $translate('icons.status.playing');
  } else if (item.scheduleStatus === 'Upcoming') {
    statusIcon.icon = 'calendar';
    statusIcon.color = 'turquoise';
    statusIcon.status = [
      $translate('icons.status.upcomingScheduling'),
      ...periodsInText,
    ];
  } else if (item.scheduleStatus === 'Active') {
    statusIcon.icon = 'calendar';
    statusIcon.color = 'green';
    statusIcon.status = [
      $translate('icons.status.activeScheduling'),
      ...periodsInText,
    ];
  }
  statusIcon.title = statusIcon.status;

  return statusIcon;
};

const getTriggerIconTitle = (app, triggers) => {
  const $translate = app.$translate;

  let titles = [];
  triggers.forEach((trigger) => {
    switch (trigger.actionType) {
      case 'Play':
        titles.push(
          $translate('triggers.triggerIconActiveTitle', trigger.name),
        );
        break;
      case 'Pause':
        titles.push(
          $translate('triggers.triggerIconInactiveTitle', trigger.name),
        );
        break;
    }
  });
  return titles;
};

const getUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

const createMediaItem = ({
  broadcastMediaCode,
  displayName,
  mediaCode,
  playerOrientation,
  orderN,
  thumbnailUrl,
  previewUrl,
  mediaType,
  duration,
  status,
  periods,
  isScheduled,
  mediaFormats,
  scheduleStatus,
  triggers,
}) => {
  let hashCode =
    mediaCode + Date.now() + Math.floor(Math.random() * Math.floor(100));

  const newItem = {
    hashCode: hashCode,
    broadcastMediaCode: broadcastMediaCode,
    displayName: displayName ? displayName : '',
    mediaCode: mediaCode,
    playerOrientation: playerOrientation
      ? playerOrientation
      : mediaFormats
        ? mediaFormats.join(', ')
        : '',
    orderN: orderN,
    thumbnailUrl: thumbnailUrl ? thumbnailUrl : '',
    previewUrl: previewUrl ? previewUrl : '',
    mediaType: mediaType ? mediaType : '',
    duration: duration ? duration : '00:00:10',
    status: status ? status : 'Confirmed',
    periods: periods ? periods : [],
    isScheduled: isScheduled ? true : false,
    scheduleStatus: scheduleStatus ? scheduleStatus : 'Always',
    triggers: triggers && triggers.triggers ? triggers.triggers : [],
  };

  return newItem;
};

const getSectionTitleWhen = (app, currentBroadcast) => {
  const $translate = app.$translate;

  if (currentBroadcast.schedule || currentBroadcast.triggers?.length) {
    let resultArray = [];
    currentBroadcast.schedule?.periods?.forEach((period) => {
      let periodArray = [];
      if (period.startDateTime || period.endDateTime) {
        let startDateTime =
          dateToLocaleString(period.startDateTime) ||
          $translate('broadcast.broadcastDetails.scheduleSection.noStartDate');
        let endDateTime =
          dateToLocaleString(period.endDateTime) ||
          $translate('broadcast.broadcastDetails.scheduleSection.noEndDate');
        periodArray.push(`${startDateTime} - ${endDateTime}`);
      }
      if (period.dayOfWeeks) {
        let days = DateTimeHelper.convertWeekdayFlagIntToText(
          period.dayOfWeeks,
        );
        if (!Array.isArray(days)) {
          // Special combinations ("Every day", "Weekdays", "Weekends")
          periodArray.push(
            $translate('components.vascheduler.specialDayCombinations.' + days),
          );
        } else {
          // Go through each day (in English) and translate as needed
          periodArray.push(
            days
              .map((day) => {
                let translatedDay = $translate(
                  'components.vascheduler.abbreviatedDays.' + day,
                );

                if (translatedDay) {
                  return translatedDay;
                }
                return translatedDay ? translatedDay : '';
              })
              .filter((translatedDay) => translatedDay)
              .join(', '),
          );
        }
      }
      if (period.weekdayStartTime || period.weekdayEndTime) {
        let weekdayStartTime = period.weekdayStartTime || '00:00';
        let weekdayEndTime = period.weekdayEndTime || '23:59';
        periodArray.push(
          `${timeToLocaleString(weekdayStartTime)} - ${timeToLocaleString(
            weekdayEndTime,
          )}`,
        );
      }
      if (periodArray.length) {
        resultArray.push(periodArray.join(', '));
      }
    });
    if (currentBroadcast.schedule.isTakeover) {
      resultArray.push('Takeover');
    }
    if (currentBroadcast.triggers.length) {
      let triggersArray = [];
      currentBroadcast.triggers.forEach((t) => {
        triggersArray.push(t.name);
      });
      resultArray.push('Trigger: ' + triggersArray.join(', '));
    }
    return (
      $translate('broadcast.broadcastDetails.when') +
      ' | ' +
      resultArray.join('. ')
    );
  }
  return (
    $translate('broadcast.broadcastDetails.when') +
    ' | ' +
    $translate('broadcast.broadcastDetails.whenPrompt')
  );
};

const getSectionTitleWhere = (app, currentBroadcast) => {
  const $translate = app.$translate;

  let title = [];
  currentBroadcast.groups.forEach((g) => {
    let playerArray = [];
    if (g.totalPlayerCount) {
      let numberOfScreens =
        g.totalPlayerCount != 1
          ? g.totalPlayerCount +
            ' ' +
            $translate('broadcast.broadcastDetails.multipleScreens')
          : g.totalPlayerCount +
            ' ' +
            $translate('broadcast.broadcastDetails.singleScreen');
      playerArray.push(numberOfScreens);
    } else {
      playerArray.push(
        $translate(
          'broadcast.broadcastDetails.playerSection.noSelectedPlayers',
        ),
      );
    }
    if (playerArray.length) {
      if (currentBroadcast.groups.length > 1) {
        title.push(`${g.name}: ${playerArray.join(', ')}`);
      } else {
        title.push(`${playerArray.join(', ')}`);
      }
    }
  });

  if (title.length > 0) {
    return (
      $translate('broadcast.broadcastDetails.where') + ' | ' + title.join(' | ')
    );
  } else {
    return (
      $translate('broadcast.broadcastDetails.where') +
      ' | ' +
      $translate('broadcast.broadcastDetails.wherePrompt')
    );
  }
};

const getSectionTitleWhat = (app, currentBroadcast) => {
  const $translate = app.$translate;

  let resultArray = [];
  currentBroadcast.groups.forEach((g) => {
    let mediaArray = [];
    if (g.media && g.media.length > 0) {
      let numberOfPhotos = g.media.filter(
        (mediaItem) => mediaItem.mediaType === 'Photo',
      ).length;
      let numberOfVideos = g.media.filter(
        (mediaItem) => mediaItem.mediaType === 'Video',
      ).length;
      let numberOfSigns = g.media.filter(
        (mediaItem) => mediaItem.mediaType === 'HtmlAd',
      ).length;

      if (numberOfPhotos === 1) {
        mediaArray.push(
          `1 ${$translate('broadcast.broadcastDetails.singlePhoto')}`,
        );
      } else if (numberOfPhotos > 1) {
        mediaArray.push(
          `${numberOfPhotos} ${$translate(
            'broadcast.broadcastDetails.multiplePhotos',
          )}`,
        );
      }

      if (numberOfVideos === 1) {
        mediaArray.push(
          `1 ${$translate('broadcast.broadcastDetails.singleVideo')}`,
        );
      } else if (numberOfVideos > 1) {
        mediaArray.push(
          `${numberOfVideos} ${$translate(
            'broadcast.broadcastDetails.multipleVideos',
          )}`,
        );
      }

      if (numberOfSigns === 1) {
        mediaArray.push(
          `1 ${$translate('broadcast.broadcastDetails.singleSign')}`,
        );
      } else if (numberOfSigns > 1) {
        mediaArray.push(
          `${numberOfSigns} ${$translate(
            'broadcast.broadcastDetails.multipleSigns',
          )}`,
        );
      }
    } else {
      mediaArray.push(
        $translate('broadcast.broadcastDetails.mediaSection.noSelectedMedia'),
      );
    }
    if (mediaArray.length) {
      if (currentBroadcast.groups.length > 1) {
        resultArray.push(`${g.name}: ${mediaArray.join(', ')}`);
      } else {
        resultArray.push(`${mediaArray.join(', ')}`);
      }
    }
  });
  if (resultArray && resultArray.length > 0) {
    return (
      $translate('broadcast.broadcastDetails.what') +
      ' | ' +
      resultArray.join(' | ')
    );
  }
  return (
    $translate('broadcast.broadcastDetails.what') +
    ' | ' +
    $translate('broadcast.broadcastDetails.whatPrompt')
  );
};

const getBroadcastActions = (broadcast, app) => {
  const $translate = app.$translate;

  let playPause =
    broadcast.status === 'Confirmed'
      ? {
          Label: $translate('playlist.itemActions.pause'),
          EmitAction: 'PAUSE',
        }
      : {
          Label: $translate('playlist.itemActions.play'),
          EmitAction: 'PLAY',
        };
  let list = [
    playPause,
    {
      Label: $translate('generic.edit'),
      EmitAction: 'EDIT',
    },
    {
      Label: $translate('generic.duplicate'),
      EmitAction: 'DUPLICATE',
    },
    {
      Label: $translate('generic.delete'),
      EmitAction: 'DELETE',
      IsDanger: true,
    },
  ];
  return list;
};

const getLocalSelectionColumns = (app, hasOptions = false) => {
  const $translate = app.$translate;

  return [
    {
      id: 'broadcastName',
      name: $translate('broadcast.headings.name'),
      attributeName: 'name',
      enabled: true,
      orderBy: 'BroadcastName',
    },
    {
      id: 'periods',
      name: $translate('broadcast.headings.periods'),
      enabled: true,
    },
    {
      id: 'numScreens',
      name: $translate('broadcast.headings.screens'),
      attributeName: 'playerCount',
      enabled: true,
      orderBy: 'BroadcastScreens',
    },
    {
      id: 'status',
      name: $translate('broadcast.headings.status'),
      enabled: true,
      orderBy: 'BroadcastStatus',
    },
    {
      id: 'options',
      name: '',
      options: hasOptions ? getBroadcastActions : null,
      enabled: true,
      locked: true,
    },
  ];
};

// Remove things that are not needed for comparison (things not sent to backend)
const cleanupBroadcast = (broadcast) => {
  let cleanedBroadcast = JSON.parse(JSON.stringify(broadcast));

  cleanedBroadcast.groups.forEach((group) => {
    delete group.conditionLookups;
  });

  return cleanedBroadcast;
};

export default {
  mapNewBroadcast,
  mapDifferenceInBroadcasts,
  hasDefaultScheduling,
  hasScheduling,
  createMediaItem,
  calculatePeriodDifferences,
  getStatusIcon,
  getTriggerIconTitle,
  getUniqueId,
  getSectionTitleWhen,
  getSectionTitleWhere,
  getSectionTitleWhat,
  getBroadcastActions,
  getLocalSelectionColumns,
  cleanupBroadcast,
};
