<script setup lang="ts">
import VaButton from '../VaButton.vue';
import { translate } from '@/helpers/translationHelper';

defineProps<{
  modelValue: 'and' | 'or';
}>();

const emit = defineEmits<{
  'update:modelValue': [value: 'and' | 'or'];
}>();
</script>

<template>
  <div class="va-and-or">
    <div class="va-and-or-buttons">
      <VaButton
        :color="modelValue === 'and' ? 'success' : 'tertiary'"
        size="small"
        @click="emit('update:modelValue', 'and')"
      >
        <template #prepend><div class="plus">+</div></template>
        {{ translate('filter.and') }}
      </VaButton>
      <VaButton
        data-testid="va-and-or-button--or"
        :color="modelValue === 'or' ? 'success' : 'tertiary'"
        size="small"
        @click="emit('update:modelValue', 'or')"
      >
        <template #prepend><div class="plus">+</div></template>
        {{ translate('filter.or') }}
      </VaButton>
    </div>
    <div v-if="modelValue === 'or'" class="break-line"></div>
  </div>
</template>

<style lang="scss" scoped>
.va-and-or {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 1rem;
  place-items: center left;

  .va-and-or-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    place-items: center left;
  }

  .break-line {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid $color-border;
    width: 100%;
    max-width: 736px;
  }

  .plus {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>
