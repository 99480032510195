<!--
Slot header can be used for title
Slot body can be used for describing texts and fields
Slot footer can be used for extra buttons
Slot confirm-button should be a paragraph with the name of the confirm button
Slot cancel-button should be a paragraph with the name of the cancel button
For green button bind type:confirm to modal
For red button bind type:warning to modal
-->
<template>
  <div class="va-modal">
    <transition name="modal">
      <div v-if="true" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container"
            :style="'max-width:' + width + 'px;'"
            :class="type"
          >
            <div class="modal-header">
              <slot class="header" name="header" />
              <svg-icon
                ref="svg-icon-close"
                data-testid="modal-icon-close"
                icon="close"
                :title="$translate('icons.close')"
                class="close-icon"
                @click="$emit('close')"
              />
            </div>

            <div class="modal-body">
              <slot name="body" />
            </div>

            <div class="modal-footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
export default {
  components: {
    SvgIcon,
  },
  props: {
    type: {
      type: String,
      default: '',
      validator: (value) =>
        value ? ['confirm', 'warning'].includes(value) : true,
    },
    width: {
      type: [String, Number],
      default: 800,
    },
  },
  emits: ['close'],
  mounted() {
    // TODO: Find a better way to focus on the first input, not relying on data-testid
    const inputs = this.$el.querySelectorAll('[data-testid="va-input"]');

    if (inputs.length) {
      inputs[0].focus();
    }
  },
};
</script>

<style lang="scss" scoped>
@use '../../styles/animations';

.va-modal {
  animation: up_in 0.2s ease-out forwards;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .modal-mask {
    width: 100%;
    height: 100%;
    background-color: $color-bg-overlay;
    display: table;
    transition: opacity 0.2s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    margin: 0 auto;
    background-color: $color-bg-tertiary;
    transition: all 0.2s ease;
    border-radius: 5px;
    width: 100vw;
  }

  .modal-header {
    margin-top: 0;
    padding: 20px 30px;
    background-color: $color-fill-secondary;
    border-radius: 5px 5px 0 0;
    width: calc(100% - 60px);
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-icon {
      color: $color-text-secondary;
      cursor: pointer;
      transition: color 0.2s ease-out;

      &:hover {
        color: $color-text;
      }
    }
  }

  .modal-header h3,
  .modal-header :deep(h3) {
    display: inline-block;
    margin: 0;
    font-size: 18px;
    color: $color-text-secondary;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 720px;
    line-height: normal;
    width: calc(100% - 30px);
  }

  .modal-header i {
    cursor: pointer;
    color: $color-text-secondary;
    margin: 0;
    text-align: right;
  }

  .modal-body {
    margin: 30px 0;
    padding: 0 30px;
    font-size: 14px;
    letter-spacing: 0.6px;
    text-align: left;
    color: $color-text-secondary;

    :deep(p) {
      color: $color-text-secondary;
      line-height: 1.3em;
      font-size: 14px;
      text-align: left;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .modal-body p,
  :deep(.modal-body) p {
    padding-bottom: 20px;

    &.padding-none {
      padding: 0;
    }
  }

  :deep(.modal-default-button) {
    float: right;
    margin-left: 10px;
    cursor: pointer;
    min-width: 100px;
    height: 39px;
    border-radius: 19.5px;
    border: solid 1px $color-action-border-secondary;
    background-color: $color-action-bg-secondary;
    margin-bottom: 30px;
    transition:
      background-color 0.2s ease-out,
      border-color 0.2s ease-out;

    p {
      margin: 5px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.3px;
      text-align: center;
      color: $color-text-secondary;
      transition: color 0.2s ease-out;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      border: 1px solid $color-action-border-secondary-hover;

      p {
        color: $color-action-label-secondary-hover;
      }
    }
  }

  .warning {
    :deep(.modal-default-button.confirm-button) {
      background-color: $color-action-bg-danger;
      border: none;

      &:hover {
        background-color: $color-action-bg-danger-hover;
      }

      &[disabled='true'],
      &[disabled='disabled'],
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      p {
        color: $color-action-label-danger;
        font-weight: 500;
      }
    }
  }

  .confirm {
    :deep(.modal-default-button.confirm-button) {
      background-color: $color-action-bg;
      border: none !important;

      &:hover {
        background-color: $color-action-bg-hover;
      }

      &[disabled='true'],
      &[disabled='disabled'],
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      p {
        color: $color-action-label;
        font-weight: 500;
      }
    }
  }

  :deep(.left) {
    float: right;
  }

  .modal-footer {
    padding: 0 30px;
    display: block;
    overflow: auto;
    height: 100%;

    :deep(.footer-buttons) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 30px;
      gap: 1rem;
    }
  }

  /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.1);
  }
}
</style>
