<template>
  <div v-if="filterBy" class="va-filter">
    <div class="filter-by">
      <span>{{ title || $translate('filter.filterBy') }}: </span>
      <!-- Custom filter by (Array) -->
      <template v-if="Array.isArray(filterBy)">
        <va-filter-button
          v-for="node in filterBy"
          :key="node.key || node.type"
          :text="node.label"
          :data-testid="`va-filter--filter-by-${node.label}`"
          type="add"
          @click="addNode(node.type, node.key)"
        />
      </template>
      <!-- Predefined filter by (Object) -->
      <template v-else>
        <va-filter-button
          v-if="filterBy.location"
          data-testid="va-filter--filter-by-location"
          :text="$translate('filter.locations')"
          type="add"
          @click="onAddLocation"
        />
        <va-filter-button
          v-if="filterBy.screenFormat"
          data-testid="va-filter--filter-by-screen-format"
          :text="$translate('filter.screenFormats')"
          type="add"
          @click="onAddScreenFormat"
        />
        <va-filter-button
          v-if="filterBy.screenNames"
          data-testid="va-filter--filter-by-screen-name"
          :text="$translate('filter.screenNames')"
          type="add"
          @click="onAddScreenName"
        />
        <va-filter-button
          v-if="filterBy.mediaNames"
          data-testid="va-filter--filter-by-media-name"
          :text="$translate('filter.mediaNames')"
          type="add"
          @click="onAddMediaName"
        />
        <va-filter-button
          v-if="filterBy.tags"
          data-testid="va-filter--filter-by-tags"
          :text="getTagsButtonText(tagType)"
          type="add"
          @click="onAddTags"
        />
        <va-filter-button
          v-if="filterBy.layers && showLayers"
          data-testid="va-filter--filter-by-layers"
          :text="$translate('filter.layers')"
          type="add"
          @click="onAddLayers"
        />
        <va-filter-button
          v-if="filterBy.playerStatus"
          data-testid="va-filter--filter-by-player-status"
          text="Player status"
          type="add"
          @click="onAddPlayerStatus"
        />
        <va-filter-button
          v-if="filterBy.searchText"
          data-testid="va-filter--filter-by-search-text"
          :text="$translate('filter.searchText')"
          type="add"
          @click="onAddSearchText"
        />
      </template>
    </div>
    <div class="filter-nodes">
      <div
        v-for="(filterNode, index) in filterNodes"
        :key="filterNode.id"
        class="filter-node"
      >
        <va-filter-node
          :node-info="filterNode.node"
          :condition-lookups="conditionLookups"
          :filter-by="filterBy"
          :media-libraries="mediaLibraries"
          :tag-type="tagType"
          @remove="onRemoveNode(index)"
          @update="onUpdateNode($event, index)"
          @update-attribs="onUpdateAttribs($event, index)"
        />
        <va-and-or
          v-if="index < filterNodes.length - 1"
          :model-value="filterNode.operator.toLowerCase()"
          @update:model-value="changeOperator($event, index)"
        />
      </div>
    </div>
    <div v-if="save && hasPermissionToEditFilter" class="filter-buttons">
      <va-button
        v-if="save.filterCode"
        data-testid="va-filter--delete-button"
        color="danger"
        @click="onDeleteFilter"
      >
        {{ $translate('generic.delete') }}
      </va-button>
      <va-button
        data-testid="va-filter--save-button"
        :disabled="!removeEmptyNodes(condition)"
        @click="onSaveFilter"
      >
        {{ $translate('filter.saveFilter') }}
      </va-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VaFilterButton from './VaFilterButton.vue';
import VaFilterNode from './va-filter-node.vue';
import VaAndOr from './VaAndOr.vue';
import VaButton from '@/components/framework/VaButton.vue';
import { v4 as uuidv4 } from 'uuid';
import filterHelper from '@/helpers/filter-helper';
import {
  removeEmptyNodes,
  getTagsButtonText,
} from '@/helpers/filterHelper/filterHelper';

export default {
  components: {
    VaFilterButton,
    VaFilterNode,
    VaAndOr,
    VaButton,
  },
  props: {
    filterBy: {
      type: [Object, Array],
      default: null,
    },
    savedCondition: {
      type: Object,
      default: () => {
        return {};
      },
    },
    conditionLookups: {
      type: Object,
      default: () => {
        return { nodes: [] };
      },
    },
    save: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hasPermissionToEditFilter: {
      type: Boolean,
      default: false,
    },
    mediaLibraries: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tagType: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'DELETE_FILTER', 'SAVE_FILTER', 'changeAttribs'],
  data() {
    return {
      filterNodes: [],
      condition: {},
      attributes: this.conditionLookups || { nodes: [] },
    };
  },
  computed: {
    ...mapGetters({
      showLayers: 'showLayers',
    }),
  },
  watch: {
    savedCondition: function (newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return;
      }
      this.convertSavedCondition();
    },
    conditionLookups(newVal) {
      this.attributes = newVal || { nodes: [] };
    },
  },
  created() {
    this.convertSavedCondition();
  },
  mounted() {
    if (this.$store.getters.showLayers) {
      this.$store.dispatch('getLayers', {}); // To set 'showLayers'
    }
  },
  methods: {
    onAddLayers() {
      this.addNode('Layers');
    },
    onAddPlayerStatus() {
      this.addNode('PlayerStatus');
    },
    onAddLocation() {
      this.addNode('Locations');
    },
    onAddScreenFormat() {
      this.addNode('Aspects');
    },
    onAddTags() {
      this.addNode('Tags');
    },
    onAddScreenName() {
      this.addNode('Players');
    },
    onAddMediaName() {
      this.addNode('Media');
    },
    onAddSearchText() {
      this.addNode('SearchText');
    },
    onDeleteFilter() {
      this.$emit('DELETE_FILTER', this.save.filterCode);
    },
    onSaveFilter() {
      this.$emit('SAVE_FILTER', this.condition);
    },
    addNode(nodeType, nodeKey) {
      this.filterNodes.push({
        node: {
          nodeType: nodeType,
          subNodes: null,
          codes: [],
          codeCondition: 'In',
          nodeKey: nodeKey,
        },
        operator: 'AND',
        id: uuidv4(),
      });
      this.buildFilterCondition();
    },
    buildFilterCondition() {
      this.condition = filterHelper.buildFilterCondition(this.filterNodes);
      this.$emit('change', this.condition);
    },
    convertSavedCondition() {
      this.filterNodes = [];
      if (this.savedCondition && this.savedCondition.subNodes) {
        this.filterNodes = filterHelper.getFilterNodes(this.savedCondition);
        this.buildFilterCondition();
      }
    },
    changeOperator(operator, index) {
      this.filterNodes[index].operator = operator;
      this.buildFilterCondition();
    },
    onRemoveNode(index) {
      this.filterNodes.splice(index, 1);
      this.buildFilterCondition();
    },
    onUpdateNode(node, index) {
      this.filterNodes[index].node = node;
      this.buildFilterCondition();
    },
    onUpdateAttribs(attribs) {
      const index =
        this.attributes.nodes?.findIndex(
          (node) => node.nodeType === attribs.nodeType,
        ) || -1;
      if (index !== -1) {
        const oldItems = this.attributes.nodes[index].items || [];
        const newItems = attribs.items || [];
        this.attributes.nodes[index].items = [...oldItems, ...newItems];
      } else {
        if (this.attributes.nodes) {
          this.attributes.nodes.push(attribs);
        } else {
          this.attributes.nodes = [attribs];
        }
      }
      this.$emit('changeAttribs', this.attributes);
    },
    removeEmptyNodes,
    getTagsButtonText,
  },
};
</script>

<style lang="scss" scoped>
.va-filter {
  font-size: 15px;
  color: $color-text-secondary;
  letter-spacing: 0.38px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .filter-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .filter-by {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .filter-nodes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .filter-node {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }
}
</style>
