<template>
  <div class="va-dropdown-search">
    <va-popup right :opened="searchResultPopupOpened" @blur="onPopupClose">
      <template #toggler>
        <div
          ref="search-field-container"
          data-testid="filter-search-container"
          class="search-field-container"
          aria-haspopup="listbox"
          aria-expanded="false"
          @keydown.down.prevent
          @keydown="onKeydown"
          @click="setFocusOnInput"
        >
          <div
            v-for="selectedSearchText in selectedSearchTexts"
            :key="selectedSearchText.id"
            class="search-text-container"
          >
            <div class="search-text">
              <span class="break-word">
                {{ selectedSearchText.displayName }}
              </span>
              <i
                class="fa fa-times"
                data-testid="dropdown-search--remove-item"
                @click="onRemoveOption(selectedSearchText.id)"
              />
            </div>
          </div>
          <input
            ref="input-area"
            v-model="searchText"
            data-testid="input-area"
            class="input-area"
          />
          <span
            v-if="
              placeholder && !searchText && selectedSearchTexts.length === 0
            "
            class="placeholder"
          >
            {{ placeholder }}
          </span>
          <svg-icon
            v-if="selectedSearchTexts.length === 0 && optionsToShow.length > 0"
            icon="search"
            class="search-icon"
            @click="searchResultPopupOpened = true"
          />
        </div>
      </template>
      <div ref="popup-content" class="search-result-container">
        <ul role="listbox">
          <li
            v-for="(dropdownOption, index) in optionsToShow"
            :id="`option${index}`"
            :key="dropdownOption.id"
            tabindex="-1"
            :data-testid="`search-result-container-${dropdownOption.id}`"
            class="noselect"
            role="option"
            :title="dropdownOption.displayName"
            @keydown.prevent="onListKeydown($event, dropdownOption)"
            @click="onOptionClick(dropdownOption)"
          >
            {{ dropdownOption.displayName }}
          </li>
        </ul>
      </div>
    </va-popup>
  </div>
</template>

<script>
import VaPopup from '@/components/framework/vaPopup/VaPopup.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';

export default {
  components: {
    VaPopup,
    SvgIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    dropdownOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    initialValues: {
      type: Array,
      default() {
        return [];
      },
    },
    singleSelect: {
      type: Boolean,
    },
  },
  emits: ['selection_updated'],
  data() {
    return {
      searchText: '',
      searchResultPopupOpened: false,
    };
  },
  computed: {
    optionsToShow() {
      let options;
      if (!this.singleSelect) {
        options = this.dropdownOptions.filter((f) => {
          return !this.selectedSearchTexts
            .map((sel) => {
              return sel.id;
            })
            .includes(f.id);
        });
      } else if (this.singleSelect) {
        options = this.dropdownOptions;
      }
      return options.filter((f) =>
        f.displayName.toLowerCase().includes(this.searchText.toLowerCase()),
      );
    },
    selectedSearchTexts() {
      return this.dropdownOptions.filter((f) => {
        return this.initialValues.includes(f.id);
      });
    },
  },
  methods: {
    setFocusOnInput(e) {
      if (
        this.optionsToShow.length > 0 &&
        (e.target === this.$refs['search-field-container'] ||
          e.target === this.$refs['input-area'])
      ) {
        this.searchResultPopupOpened = true;
        this.$refs['input-area'].focus();
      }
    },
    onKeydown(e) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        this.searchResultPopupOpened = true;
        this.$nextTick(() => {
          document.getElementById('option0').focus();
        });
      } else if (
        e.key !== 'Escape' &&
        e.key !== 'Tab' &&
        e.key !== 'Shift' &&
        this.optionsToShow.length
      ) {
        this.searchResultPopupOpened = true;
      }
    },
    onPopupClose() {
      this.searchText = '';
      this.searchResultPopupOpened = false;
    },
    onListKeydown(e, option) {
      if (e.key === 'ArrowDown') {
        const activeOption = document.activeElement;
        const nextOption = activeOption.nextElementSibling;
        if (nextOption) {
          nextOption.focus();
        }
      } else if (e.key === 'ArrowUp') {
        const activeOption = document.activeElement;
        const prevOption = activeOption.previousElementSibling;
        if (prevOption) {
          prevOption.focus();
        }
      } else if (e.key === 'Enter') {
        this.onOptionClick(option);
      } else if (e.key === 'Escape' || e.key === 'Tab') {
        this.onPopupClose();
      }
    },
    onOptionClick(option) {
      let selectedSearchTexts = this.selectedSearchTexts;
      if (this.singleSelect) {
        selectedSearchTexts = [option];
        this.searchResultPopupOpened = false;
        this.searchText = '';
      } else {
        selectedSearchTexts.push(option);
        this.$refs['input-area'].focus();
      }
      this.$emit('selection_updated', selectedSearchTexts);
    },
    onRemoveOption(id) {
      const searchTextToRemove = this.selectedSearchTexts.find(
        (searchText) => searchText.id === id,
      );
      if (searchTextToRemove) {
        let selectedSearchTexts = this.selectedSearchTexts;
        selectedSearchTexts.splice(
          selectedSearchTexts.indexOf(searchTextToRemove),
          1,
        );
        this.$emit('selection_updated', selectedSearchTexts);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.va-dropdown-search {
  display: inline-block;
  color: $color-text-secondary;
  font-family: Roboto, sans-serif;
  width: 100%;
  font-size: 14px;

  .break-word {
    word-break: break-all;
  }

  .search-text-container {
    display: flex;
    align-items: center;

    .search-text {
      background-color: $color-action-bg-secondary;
      padding: 10px 15px;
      border-radius: 15.8px;
      font-size: 12.15px;
      letter-spacing: 0.3px;
      text-align: center;
      margin: 2.5px;
      line-height: 1;

      > i {
        padding-left: 5px;
        transition: color 0.2s ease-out;

        &:hover {
          color: $color-text;
        }
      }
    }
  }

  .search-field-container {
    position: relative;
    min-height: 40px;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 2.5px;
    background-color: $color-input;
    cursor: pointer;

    .input-area {
      border: none;
      background: none;
      color: $color-text;
      margin: 2.5px 10px;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 0.35px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      right: 15px;
      top: 13px;
    }

    .placeholder {
      position: absolute;
      font-size: 14px;
      padding-left: 13px;
      letter-spacing: 0.35px;
      user-select: none;
      pointer-events: none;
      color: $color-text-secondary;
    }
  }

  .search-result-container {
    background-color: $color-input;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 3px 10px $color-shadow;

    ul {
      max-height: 240px;
      overflow-y: scroll;
      margin: 0;
      padding: 0;
      border-radius: 4px;

      li {
        height: 20px;
        padding: 10px 20px;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        transition:
          background-color 0.2s ease-out,
          color 0.2s ease-out;

        &:focus-visible {
          outline: $color-border-focus solid 1px;
        }

        &:focus,
        &:hover {
          background-color: $color-input-hover;
          color: $color-text;
          cursor: pointer;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-fill-secondary;
  }

  .tag-color {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 1px 6px 1px 1px;
  }

  .separator {
    text-transform: lowercase;
    line-height: 1;
  }
}
</style>
