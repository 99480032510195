import broadcastRoutes from '@/views/broadcasts/broadcastRoutes';
import playerRoutes from '@/views/players/playerRoutes';
import mediaRoutes from '@/views/media/mediaRoutes';
import shareOfVoiceRoutes from '@/views/shareOfVoice/shareOfVoiceRoutes';
import templateRoutes from '@/views/templates/templateRoutes';
import helpRoutes from '@/views/help/helpRoutes';
import settingsRoutes from '@/views/settings/settingsRoutes';
import triggerRoutes from '@/views/triggers/triggerRoutes';
import localSelectionRoutes from '@/views/localSelection/localSelectionRoutes';
import itemRoutes from '@/views/items/itemRoutes';
import userRoutes from '@/views/users/userRoutes';
import overviewRoutes from '@/views/overview/overviewRoutes';
import variableRoutes from '@/views/variables/variableRoutes';
import roleRoutes from '@/views/roles/roleRoutes';
import tagsRoutes from '@/views/tags/tagsRoutes';

const SideBar = () => import('@/components/sideBar/SideBar.vue');
const LoginView = () => import('@/views/LoginsView.vue');
const ConfirmUserView = () => import('@/views/logins/ConfirmUserView.vue');
const ConfirmNewPasswordView = () =>
  import('@/views/logins/ConfirmNewPasswordView.vue');
const ForgotPasswordView = () =>
  import('@/views/logins/ForgotPasswordView.vue');
const ErrorView = () => import('@/views/ErrorView.vue');
const LogoutView = () => import('@/views/LogoutView.vue');
const CallbackView = () => import('@/views/CallbackView.vue');
const NotFoundView = () => import('@/views/NotFoundView.vue');

export default [
  {
    path: '/accessdenied',
    name: 'AccessDeniedView',
    components: { default: ErrorView, 'side-bar': SideBar },
  },
  {
    path: '/not-found',
    name: 'NotFoundView',
    components: { default: NotFoundView, 'side-bar': SideBar },
  },
  {
    path: '/logins/:accountCode',
    name: 'LoginWithAccountCode',
    components: { fullscreen: LoginView },
  },
  {
    path: '/logins/',
    name: 'Login',
    components: { fullscreen: LoginView },
  },
  {
    path: '/userEmailConfirmation',
    name: 'ConfirmUser',
    components: {
      fullscreen: ConfirmUserView,
    },
  },
  {
    path: '/userEmailResetPassword',
    name: 'ConfirmNewPassword',
    components: {
      fullscreen: ConfirmNewPasswordView,
    },
  },
  {
    path: '/passwordForgot',
    name: 'PasswordForgot',
    components: {
      fullscreen: ForgotPasswordView,
    },
  },
  {
    path: '/logout',
    name: 'LogoutView',
    components: { fullscreen: LogoutView },
  },
  {
    path: '/callback',
    name: 'CallbackView',
    components: { fullscreen: CallbackView },
  },
  { path: '/:pathMatch(.*)*', name: 'Redirect', redirect: '/overview/clients' },
  ...broadcastRoutes,
  ...playerRoutes,
  ...mediaRoutes,
  ...shareOfVoiceRoutes,
  ...templateRoutes,
  ...helpRoutes,
  ...settingsRoutes,
  ...triggerRoutes,
  ...localSelectionRoutes,
  ...itemRoutes,
  ...userRoutes,
  ...overviewRoutes,
  ...variableRoutes,
  ...roleRoutes,
  ...tagsRoutes,
];
