<template>
  <div class="va-vid-container">
    <div v-if="loading" class="loader" />
    <video
      v-if="videoData"
      draggable="false"
      class="va-vid"
      controls
      :class="{ transparent: loading }"
      :src="videoData"
      @load="loading = false"
      @canplay="handleEvent('canplay', $event)"
      @error="handleError"
    />
  </div>
</template>

<script>
import imageService from '@/services/image';

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dataBlob: '',
      loading: true,
    };
  },

  computed: {
    hasImage() {
      return this.src ? true : false;
    },
    videoData() {
      if (this.src) {
        return this.dataBlob;
      } else {
        return '/static/missingVideo.mp4';
      }
    },
  },

  watch: {
    src() {
      this.updateImage();
    },
  },

  created() {
    if (this.src) {
      this.updateImage();
    }
  },

  methods: {
    handleError(e) {
      this.handleEvent('error', e);
    },
    handleEvent(eventType, eventData) {
      this.$emit(eventType, eventData);
    },
    updateImage() {
      imageService
        .getImage(this.src)
        .then((response) => {
          this.dataBlob = URL.createObjectURL(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.va-vid-container {
  position: relative;

  .va-vid {
    width: 100%;
    height: 100%;

    &.transparent {
      opacity: 0;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 0.3rem solid #4c4b51;
  border-top: 0.3rem solid $color-fill-active;
  border-radius: 50%;
  animation: loading-spin 2s linear infinite;
  box-sizing: border-box;
  width: 6rem;
  height: 6rem;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

@keyframes loading-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
