import { v4 as uuidv4 } from 'uuid';

import broadcastHelper from '@/helpers/broadcast-helper';
import broadcastService from '@/services/broadcasts';
import mediaService from '@/services/media';
import { BroadcastType } from '@/views/broadcasts/enums/broadcastType';
import { BROADCAST_SPOT_LENGTH_DEFAULT } from '@/views/broadcasts/constants/broadcast';

const SET_BROADCAST = 'SET_BROADCAST';
const SET_BROADCAST_NAME = 'SET_BROADCAST_NAME';
const SET_BROADCAST_TYPE = 'SET_BROADCAST_TYPE';
const SET_BROADCAST_SCHEDULE = 'SET_BROADCAST_SCHEDULE';
const SET_BROADCAST_MEDIA_SCHEDULE = 'SET_BROADCAST_MEDIA_SCHEDULE';
const TOGGLE_TAKEOVER = 'TOGGLE_TAKEOVER';
const SET_FILTER = 'SET_FILTER';
const SET_CURRENT_BROADCAST_STATUS = 'SET_CURRENT_BROADCAST_STATUS';

const SET_INITIAL_VALUES_FOR_COMPARISON = 'SET_INITIAL_VALUES_FOR_COMPARISON';
const SET_DEFAULT_VALUES = 'SET_DEFAULT_VALUES';

const SET_CONTENT_TYPE_TAG = 'SET_CONTENT_TYPE_TAG';
const SET_CONTENT_TYPE_SPOT_LENGTH = 'SET_CONTENT_TYPE_SPOT_LENGTH';

const ADD_MEDIA_TO_BROADCAST = 'ADD_MEDIA_TO_BROADCAST';
const REMOVE_MEDIA_IN_BROADCAST = 'REMOVE_MEDIA_IN_BROADCAST';
const CLEAR_MEDIALIST_IN_BROADCAST = 'CLEAR_MEDIALIST_IN_BROADCAST';
const SET_MEDIA_DURATION = 'SET_MEDIA_DURATION';
const SET_MEDIA_SCHEDULE = 'SET_MEDIA_SCHEDULE';
const SET_MEDIA_STATUS = 'SET_MEDIA_STATUS';
const SET_MEDIA_ORDER = 'SET_MEDIA_ORDER';
const SET_MEDIA_TRIGGERS = 'SET_MEDIA_TRIGGERS';

const UPDATES_LAYERS_IN_BROADCAST = 'UPDATES_LAYERS_IN_BROADCAST';

const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
const ADD_BROADCAST_GROUP = 'ADD_BROADCAST_GROUP';
const EDIT_BROADCAST_GROUP = 'EDIT_BROADCAST_GROUP';
const DELETE_BROADCAST_GROUP = 'DELETE_BROADCAST_GROUP';
const UPDATE_GROUP_PLAYER_COUNT = 'UPDATE_GROUP_PLAYER_COUNT';
const DUPLICATE_ACTIVE_BROADCAST_GROUP = 'DUPLICATE_ACTIVE_BROADCAST_GROUP';

const SET_BROADCAST_TRIGGERS = 'SET_BROADCAST_TRIGGERS';

const SET_CONDITION_LOOKUPS = 'SET_CONDITION_LOOKUPS';
const SET_BROADCAST_GROUP_ORDER = 'SET_BROADCAST_GROUP_ORDER';
const SET_LOADING = 'SET_LOADING';
const SET_HAS_PERMISSION_TO_ALL_LAYERS = 'SET_HAS_PERMISSION_TO_ALL_LAYERS';

// state
const state = {
  currentBroadcast: {
    broadcastName: '',
    broadcastCode: '',
    broadcastType: BroadcastType.STANDARD,
    insertedDatetime: '',
    modifiedDateTime: '',
    createdByUser: '',
    modifiedByUser: '',
    schedule: {
      periods: [],
      isScheduled: false,
      isTakeover: false,
      status: 'Draft',
      scheduleStatus: 'Always',
    },
    groups: [],
    triggers: [],
    activeGroup: '0',
    loading: false,
    isInteractive: false,
    hasPermissionToAllLayers: true,
  },
  initialBroadcastValues: {},
};

// getters
const getters = {
  currentBroadcast: (state) => state.currentBroadcast,
  broadcastChanges: (state) =>
    JSON.stringify(broadcastHelper.cleanupBroadcast(state.currentBroadcast)) !==
    JSON.stringify(
      broadcastHelper.cleanupBroadcast(state.initialBroadcastValues),
    ),
};

// actions
const actions = {
  saveBroadcastPromisfied({ state }) {
    if (state.currentBroadcast.broadcastCode && state.initialBroadcastValues) {
      return new Promise((resolve, reject) => {
        // Compare difference between initialValues and state.currentBroadcast;
        const payload = broadcastHelper.mapDifferenceInBroadcasts(
          state.initialBroadcastValues,
          state.currentBroadcast,
        );

        // Update
        broadcastService
          .updateBroadcast(state.currentBroadcast.broadcastCode, payload)
          .then((response) => {
            if (response.success.ok) {
              resolve(response.broadcast);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        const payload = broadcastHelper.mapNewBroadcast(state.currentBroadcast);

        broadcastService
          .createBroadcast(state.currentBroadcast.broadcastType, payload)
          .then((response) => {
            if (response.success.ok) {
              resolve(response.broadcast);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    }
  },
  deleteBroadcast({ commit, dispatch }, { broadcastCode }) {
    return new Promise((resolve, reject) => {
      broadcastService
        .deleteBroadcast(broadcastCode)
        .then((response) => {
          if (response.success.ok) {
            //Just to set broadcastChanges to false to prevent save modal to show up on delete changed broadcast
            commit('SET_INITIAL_VALUES_FOR_COMPARISON');
            resolve(true);
          } else {
            if (response.success.message) {
              dispatch('addMessage', {
                message: { text: response.success.message, type: 'error' },
                time: 10000,
              });
            } else {
              dispatch('addMessage', {
                message: { text: 'An error has occurred.', type: 'error' },
                time: 10000,
              });
            }
            resolve(false);
          }
        })
        .catch((error) => {
          reject();
          console.error(error);
        });
    });
  },

  getDuplicateBroadcast({ commit, state }, broadcastCode) {
    return new Promise((resolve, reject) => {
      if (broadcastCode) {
        commit('SET_LOADING', true);
        broadcastService
          .getBroadcast(broadcastCode)
          .then((broadcastInformation) => {
            commit('SET_LOADING', false);
            let duplicateBroadcastInformation = broadcastInformation;
            let date = new Date().toLocaleString();
            duplicateBroadcastInformation.broadcast.name += ' ' + date;
            duplicateBroadcastInformation.broadcast.status = 'Draft';
            commit('SET_BROADCAST', duplicateBroadcastInformation);
            commit('SET_INITIAL_VALUES_FOR_COMPARISON');
            state.currentBroadcast.broadcastCode = null;
            resolve();
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      }
    });
  },
  getCurrentBroadcast({ commit }, broadcastCode) {
    return new Promise((resolve, reject) => {
      if (broadcastCode) {
        commit('SET_LOADING', true);
        broadcastService
          .getBroadcast(broadcastCode)
          .then((broadcastInformation) => {
            commit('SET_LOADING', false);
            commit('SET_BROADCAST', broadcastInformation);
            commit('SET_INITIAL_VALUES_FOR_COMPARISON');
            resolve();
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      }
    });
  },
  setDefaultValues({ commit }, values) {
    commit('SET_DEFAULT_VALUES', values);
    commit('SET_INITIAL_VALUES_FOR_COMPARISON');
  },
  setBroadcastSchedule({ commit }, schedule) {
    const scheduleCopy = JSON.parse(JSON.stringify(schedule));
    const payload = {
      periods: scheduleCopy.periods,
    };

    broadcastService
      .checkPeriodStatus(payload)
      .then((response) => {
        if (response.success.ok) {
          scheduleCopy.scheduleStatus = response.status;
          commit('SET_BROADCAST_SCHEDULE', scheduleCopy);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  setCurrentBroadcastStatus({ commit }, status) {
    commit(SET_CURRENT_BROADCAST_STATUS, status);
  },
  setBroadcastFilter({ commit }, { filter }) {
    commit('SET_FILTER', filter);
  },
  setBroadcastLookups({ commit }, { conditionLookups }) {
    commit('SET_CONDITION_LOOKUPS', conditionLookups);
  },
  setContentTypeTagCode({ commit }, tagCode) {
    commit('SET_CONTENT_TYPE_TAG', tagCode);
  },
  setContentTypeSpotLength({ commit }, spotLength) {
    commit('SET_CONTENT_TYPE_SPOT_LENGTH', spotLength);
  },
  setBroadcastName({ commit }, broadcastName) {
    commit('SET_BROADCAST_NAME', broadcastName);
  },
  setBroadcastType({ commit }, broadcastType) {
    commit('SET_BROADCAST_TYPE', broadcastType);
  },
  setBroadcastTriggers({ commit }, triggers) {
    commit('SET_BROADCAST_TRIGGERS', triggers);
  },
  toggleTakeoverState({ commit }) {
    commit('TOGGLE_TAKEOVER');
  },
  addMediaToDropzone(
    { commit },
    { mediaCode, positionInList = 0, groupCode = null },
  ) {
    mediaService
      .getMetadata(mediaCode)
      .then((metadata) => {
        let newMediaItem = broadcastHelper.createMediaItem({
          displayName: metadata.displayName,
          mediaCode: mediaCode,
          playerOrientation: metadata.orientation,
          orderN: positionInList,
          thumbnailUrl: metadata.thumbnailUrl,
          previewUrl: metadata.previewUrl,
          mediaType: metadata.mediaTypes,
          duration: metadata.duration,
        });

        commit('ADD_MEDIA_TO_BROADCAST', {
          mediaItem: newMediaItem,
          positionInList: positionInList,
          group: groupCode,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  addMediaToBroadcastPlaylist({ commit }, eventPayloadFromDraggable) {
    const newIndex = eventPayloadFromDraggable.newIndex;
    const newItemMediaCode =
      eventPayloadFromDraggable.item.getAttribute('data-media-code');

    if (
      !newItemMediaCode &&
      eventPayloadFromDraggable.item.getAttribute('unsplashid') &&
      eventPayloadFromDraggable.item.getAttribute('fromunsplash') &&
      eventPayloadFromDraggable.item.getAttribute('fromunsplash') == 'true'
    ) {
      // Upload
      //TODO remove api call from media.js when updated
      mediaService
        .uploadUnsplashMedia({
          unsplashId: eventPayloadFromDraggable.item.getAttribute('unsplashid'),
        })
        .then((response) => {
          //TODO remove api call from media.js when updated
          return mediaService.getMetadata(response.data.mediaCode);
        })
        .then((metadata) => {
          const newMediaItem = broadcastHelper.createMediaItem({
            displayName: metadata.displayName,
            mediaCode: metadata.mediaCode,
            playerOrientation: metadata.orientation,
            orderN: newIndex,
            thumbnailUrl: metadata.thumbnailUrl,
            previewUrl: metadata.previewUrl,
            mediaType: metadata.mediaTypes,
            duration: metadata.duration,
          });

          commit('ADD_MEDIA_TO_BROADCAST', {
            mediaItem: newMediaItem,
            positionInList: newIndex,
            group: eventPayloadFromDraggable.groupCode,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      mediaService
        .getMetadata(newItemMediaCode)
        .then((metadata) => {
          const newMediaItem = broadcastHelper.createMediaItem({
            displayName: metadata.displayName,
            mediaCode: newItemMediaCode,
            playerOrientation: metadata.orientation,
            orderN: newIndex,
            thumbnailUrl: metadata.thumbnailUrl,
            previewUrl: metadata.previewUrl,
            mediaType: metadata.mediaTypes,
            duration: metadata.duration,
          });

          commit('ADD_MEDIA_TO_BROADCAST', {
            mediaItem: newMediaItem,
            positionInList: newIndex,
            group: eventPayloadFromDraggable.groupCode,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  removeMedia({ commit }, { hashCode }) {
    commit('REMOVE_MEDIA_IN_BROADCAST', { hashCode: hashCode });
  },
  clearMediaList({ commit }) {
    commit('CLEAR_MEDIALIST_IN_BROADCAST');
  },
  setMediaDuration({ commit }, { hashCode, duration }) {
    commit('SET_MEDIA_DURATION', { hashCode: hashCode, duration: duration });
  },
  setMediaSchedule({ commit }, { hashCode, schedule }) {
    const scheduleCopy = JSON.parse(JSON.stringify(schedule));
    const payload = {
      periods: scheduleCopy.periods,
    };

    broadcastService
      .checkPeriodStatus(payload)
      .then((response) => {
        if (response.success.ok) {
          scheduleCopy.scheduleStatus = response.status;
          commit('SET_MEDIA_SCHEDULE', {
            hashCode: hashCode,
            schedule: scheduleCopy,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  setMediaStatus({ commit }, { hashCode, status }) {
    commit('SET_MEDIA_STATUS', { hashCode: hashCode, status: status });
  },
  setMediaOrder({ commit }, { hashCode, oldIndex, newIndex }) {
    commit('SET_MEDIA_ORDER', {
      hashCode: hashCode,
      oldIndex: oldIndex,
      newIndex: newIndex,
    });
  },
  setMediaTriggers({ commit }, { hashCode, triggers }) {
    commit('SET_MEDIA_TRIGGERS', { hashCode: hashCode, triggers: triggers });
  },
  updatesLayersInCurrentBroadcast({ commit }, { layers }) {
    commit('UPDATES_LAYERS_IN_BROADCAST', { layers });
  },
  setActiveBroadcastGroup({ commit }, { groupCode }) {
    commit('SET_ACTIVE_GROUP', { groupCode });
  },
  addBroadcastGroup({ commit }, { group }) {
    commit('ADD_BROADCAST_GROUP', { group });
  },
  editActiveBroadcastGroup({ commit }, { group }) {
    commit('EDIT_BROADCAST_GROUP', { group });
  },
  duplicateActiveBroadcastGroup({ commit }) {
    commit('DUPLICATE_ACTIVE_BROADCAST_GROUP');
  },
  deleteActiveBroadcastGroup({ commit }) {
    commit('DELETE_BROADCAST_GROUP');
  },
  updateBroadcastGroupPlayerCount({ commit }, { totalPlayerCount }) {
    commit('UPDATE_GROUP_PLAYER_COUNT', { totalPlayerCount });
  },
  setBroadcastGroupOrder({ commit }, { groups }) {
    commit('SET_BROADCAST_GROUP_ORDER', { groups });
  },
  setHasPermissionToAllLayers({ commit }, hasPermission) {
    commit(SET_HAS_PERMISSION_TO_ALL_LAYERS, hasPermission);
  },
};

// mutations
const mutations = {
  [SET_BROADCAST_NAME](state, broadcastName) {
    state.currentBroadcast.broadcastName = broadcastName;
  },
  [SET_BROADCAST_TYPE](state, broadcastType) {
    if (!broadcastType) {
      return;
    }
    state.currentBroadcast.broadcastType = broadcastType;
  },
  [SET_BROADCAST](state, broadcastInformation) {
    if (!broadcastInformation) {
      return;
    }

    state.currentBroadcast.broadcastName = broadcastInformation.broadcast.name;
    state.currentBroadcast.broadcastCode =
      broadcastInformation.broadcast.broadcastCode;
    state.currentBroadcast.broadcastType =
      broadcastInformation.broadcast.bookingType;
    state.currentBroadcast.insertedDatetime = broadcastInformation.broadcast
      .insertedDatetimeUtc
      ? broadcastInformation.broadcast.insertedDatetimeUtc + ' UTC'
      : '';
    state.currentBroadcast.modifiedDateTime = broadcastInformation.broadcast
      .modifiedDateTimeUtc
      ? broadcastInformation.broadcast.modifiedDateTimeUtc + ' UTC'
      : '';
    state.currentBroadcast.createdByUser = broadcastInformation.broadcast
      .createdByUser
      ? broadcastInformation.broadcast.createdByUser
      : '';
    state.currentBroadcast.modifiedByUser = broadcastInformation.broadcast
      .modifiedByUser
      ? broadcastInformation.broadcast.modifiedByUser
      : '';
    state.currentBroadcast.schedule.periods = broadcastInformation.broadcast
      .periods
      ? broadcastInformation.broadcast.periods
      : [];
    state.currentBroadcast.schedule.isTakeover =
      broadcastInformation.broadcast.isTakeover;
    state.currentBroadcast.schedule.status = broadcastInformation.broadcast
      .status
      ? broadcastInformation.broadcast.status
      : 'Draft';
    state.currentBroadcast.schedule.scheduleStatus = broadcastInformation
      .broadcast.scheduleStatus
      ? broadcastInformation.broadcast.scheduleStatus
      : 'Active';
    state.currentBroadcast.triggers =
      broadcastInformation.broadcast.broadcastTriggers &&
      broadcastInformation.broadcast.broadcastTriggers.triggers
        ? broadcastInformation.broadcast.broadcastTriggers.triggers
        : [];
    state.currentBroadcast.isInteractive =
      broadcastInformation.broadcast.isInteractive;

    state.currentBroadcast.groups = [];
    broadcastInformation.broadcastGroups.forEach((g) => {
      let media = [];
      let layers = [];
      let filter = {
        Condition: null,
        FixedPlayerList: false,
      };
      let conditionLookups = null;
      let chosenContentTypeTagCode = '';

      if (g.media) {
        g.media.forEach((m) => {
          let mediaObject = broadcastHelper.createMediaItem(m);
          media.push(mediaObject);
        });
      }

      if (g.channels && g.channels.length) {
        let foundChannel = g.channels[0];

        filter.Condition = foundChannel.condition || null;
      }

      if (g.conditionLookups) {
        conditionLookups = g.conditionLookups;
      }

      if (g.layers && g.layers.length) {
        layers = g.layers;
      }
      if (g.tags && g.tags.length) {
        chosenContentTypeTagCode =
          g.tags.find((t) => t.tagType === 'ContentType')?.tagCode ?? '';
      }
      state.currentBroadcast.groups.push({
        name: g.name,
        key: uuidv4(),
        code: g.broadcastGroupCode,
        media: media,
        layers: layers,
        filter: filter,
        conditionLookups: conditionLookups,
        totalPlayerCount: g.players?.length || g.playerCount || 0,
        chosenContentTypeTagCode: chosenContentTypeTagCode,
        orderN: g.orderN || 0,
        spotLength: g.spotLength,
      });

      state.currentBroadcast.groups.sort((a, b) =>
        a.orderN > b.orderN ? 1 : b.orderN > a.orderN ? -1 : 0,
      );
    });
  },
  [SET_BROADCAST_SCHEDULE](state, schedule) {
    if (!schedule) {
      return;
    }

    state.currentBroadcast.schedule.periods = schedule.periods;
    state.currentBroadcast.schedule.scheduleStatus = schedule.scheduleStatus;
  },
  [SET_CURRENT_BROADCAST_STATUS](state, status) {
    if (!status) {
      return;
    }
    state.currentBroadcast.schedule.status = status;
  },
  [SET_BROADCAST_MEDIA_SCHEDULE](state, { mediaItemHashCode, schedule }) {
    if (!mediaItemHashCode || !schedule) {
      return;
    }

    let target = state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.find((m) => m.hashCode === mediaItemHashCode);
    if (!target) {
      return;
    }
    target['isScheduled'] = true;
    target['weekdayStartTime'] = schedule.weekdayStartTime;
    target['weekdayEndTime'] = schedule.weekdayEndTime;
    target['startDate'] = schedule.startDate;
    target['endDate'] = schedule.endDate;
  },
  [TOGGLE_TAKEOVER](state) {
    state.currentBroadcast.schedule.isTakeover =
      !state.currentBroadcast.schedule.isTakeover;
  },
  [SET_FILTER](state, filter) {
    state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].filter.Condition = filter;
  },
  [SET_CONDITION_LOOKUPS](state, conditionLookups) {
    if (
      state.currentBroadcast.groups[state.currentBroadcast.activeGroup]
        .conditionLookups?.nodes
    ) {
      conditionLookups.nodes.forEach((node, index) => {
        if (node) {
          state.currentBroadcast.groups[
            state.currentBroadcast.activeGroup
          ].conditionLookups.nodes[index] = node;
        }
      });
    } else {
      state.currentBroadcast.groups[
        state.currentBroadcast.activeGroup
      ].conditionLookups = conditionLookups;
    }
  },
  [SET_CONTENT_TYPE_TAG](state, tagCode) {
    state.currentBroadcast.groups[state.currentBroadcast.activeGroup][
      'chosenContentTypeTagCode'
    ] = tagCode;
  },
  [SET_CONTENT_TYPE_SPOT_LENGTH](state, spotLength) {
    state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].spotLength = spotLength;
  },
  [ADD_MEDIA_TO_BROADCAST](state, { mediaItem, positionInList, group }) {
    if (!mediaItem) {
      return;
    }

    group = group || state.currentBroadcast.activeGroup;

    state.currentBroadcast.groups[group].media.splice(
      positionInList,
      0,
      mediaItem,
    );

    state.currentBroadcast.groups[group].media.forEach((media, idx) => {
      media['orderN'] = idx;
    });
  },
  [REMOVE_MEDIA_IN_BROADCAST](state, { hashCode }) {
    let removedItemIdx = state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.findIndex((m) => m.hashCode === hashCode);

    if (removedItemIdx < 0) {
      return;
    }

    state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.splice(removedItemIdx, 1);

    state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.forEach((media, idx) => {
      media['orderN'] = idx;
    });
  },
  [CLEAR_MEDIALIST_IN_BROADCAST](state) {
    state.currentBroadcast.groups[state.currentBroadcast.activeGroup].media =
      [];
  },
  [SET_MEDIA_DURATION](state, { hashCode, duration }) {
    let target = state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.find((m) => m.hashCode === hashCode);
    if (!target) {
      return;
    }

    target['duration'] = duration;
  },
  [SET_MEDIA_SCHEDULE](state, { hashCode, schedule }) {
    let target = state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.find((m) => m.hashCode === hashCode);
    if (!target) {
      return;
    }

    target['isScheduled'] = true;
    target['periods'] = schedule.periods;
    target['scheduleStatus'] = schedule.scheduleStatus;
  },
  [SET_MEDIA_STATUS](state, { hashCode, status }) {
    let target = state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.find((m) => m.hashCode === hashCode);
    if (!target) {
      return;
    }

    target['status'] = status ? status : 'Draft';
  },
  [SET_MEDIA_ORDER](state, { hashCode, oldIndex, newIndex }) {
    let movedItem = state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.find((m) => m.hashCode === hashCode);
    if (!movedItem) {
      return;
    }

    // Move item in array to new position
    while (oldIndex < 0) {
      oldIndex +=
        state.currentBroadcast.groups[state.currentBroadcast.activeGroup].media
          .length;
    }
    while (newIndex < 0) {
      newIndex +=
        state.currentBroadcast.groups[state.currentBroadcast.activeGroup].media
          .length;
    }
    if (
      newIndex >=
      state.currentBroadcast.groups[state.currentBroadcast.activeGroup].media
        .length
    ) {
      var k =
        newIndex -
        state.currentBroadcast.groups[state.currentBroadcast.activeGroup].media
          .length;
      while (k-- + 1) {
        state.currentBroadcast.groups[
          state.currentBroadcast.activeGroup
        ].media.push(undefined);
      }
    }
    state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.splice(
      newIndex,
      0,
      state.currentBroadcast.groups[
        state.currentBroadcast.activeGroup
      ].media.splice(oldIndex, 1)[0],
    );

    // Update orderN for all items in array
    state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.forEach((media, idx) => {
      media['orderN'] = idx;
    });
  },
  [SET_MEDIA_TRIGGERS](state, { hashCode, triggers }) {
    let target = state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].media.find((m) => m.hashCode === hashCode);
    if (!target) {
      return;
    }

    target['triggers'] = triggers;
  },
  [SET_INITIAL_VALUES_FOR_COMPARISON](state) {
    state.initialBroadcastValues = JSON.parse(
      JSON.stringify(state.currentBroadcast),
    );
  },
  [SET_DEFAULT_VALUES](state, { isInteractive = false, broadcastType }) {
    state.currentBroadcast['broadcastName'] = '';
    state.currentBroadcast['broadcastCode'] = '';
    state.currentBroadcast['broadcastType'] =
      broadcastType ?? BroadcastType.STANDARD;
    state.currentBroadcast['modifiedByUser'] = '';
    state.currentBroadcast['modifiedDateTime'] = '';

    state.currentBroadcast['schedule'] = {};
    state.currentBroadcast.schedule['isScheduled'] = false;
    state.currentBroadcast.schedule['periods'] = [
      {
        periodCode: null,
        weekdayStartTime: '',
        weekdayEndTime: '',
        dayOfWeeks: 127,
        startDateTime: '',
        endDateTime: '',
      },
    ];
    state.currentBroadcast.schedule['isTakeover'] = false;
    state.currentBroadcast.schedule['status'] = 'Draft';
    state.currentBroadcast.schedule['scheduleStatus'] = 'Always';

    state.currentBroadcast['activeGroup'] = '0';
    state.currentBroadcast['groups'] = [];
    state.currentBroadcast.groups.push({
      name: 'Group 1',
      key: uuidv4(),
      code: '',
      media: [],
      layers: [],
      filter: {
        Condition: null,
        FixedPlayerList: false,
      },
      conditionLookups: null,
      totalPlayerCount: 0,
      chosenContentTypeTagCode: '',
      orderN: 0,
      spotLength: BROADCAST_SPOT_LENGTH_DEFAULT,
    });
    state.currentBroadcast['triggers'] = [];
    state.currentBroadcast['loading'] = false;
    state.currentBroadcast['isInteractive'] = isInteractive;
  },
  [UPDATES_LAYERS_IN_BROADCAST](state, { layers }) {
    if (!layers) {
      return;
    }

    state.currentBroadcast.groups[state.currentBroadcast.activeGroup].layers =
      layers;
  },
  [SET_ACTIVE_GROUP](state, { groupCode }) {
    state.currentBroadcast.activeGroup = groupCode;
    // Initial should be same, so we can compare other changes
    state.initialBroadcastValues.activeGroup = groupCode;
  },
  [ADD_BROADCAST_GROUP](state, { group }) {
    if (!group) {
      return;
    }

    let newGroup = {
      name: group.name,
      key: uuidv4(),
      code: '',
      media: [],
      layers: [],
      filter: {
        Condition: null,
        FixedPlayerList: false,
      },
      conditionLookups: null,
      totalPlayerCount: 0,
      chosenContentTypeTagCode: '',
      orderN: state.currentBroadcast.groups.length,
      spotLength: BROADCAST_SPOT_LENGTH_DEFAULT,
    };
    state.currentBroadcast.groups.push(newGroup);
  },
  [EDIT_BROADCAST_GROUP](state, { group }) {
    if (!group) {
      return;
    }

    state.currentBroadcast.groups[state.currentBroadcast.activeGroup].name =
      group.name;
  },
  [DUPLICATE_ACTIVE_BROADCAST_GROUP](state) {
    let activeGroup =
      state.currentBroadcast.groups[state.currentBroadcast.activeGroup];
    let newIndex = state.currentBroadcast.activeGroup + 1;
    let groupCopy = JSON.parse(JSON.stringify(activeGroup));
    groupCopy.code = ''; // Reset code so backend will create new group
    groupCopy.key = uuidv4(); // Give new key
    groupCopy.name = groupCopy.name + '_copy';
    state.currentBroadcast.groups.splice(newIndex, 0, groupCopy);
  },
  [DELETE_BROADCAST_GROUP](state) {
    if (state.currentBroadcast.groups.length > 1) {
      let index = state.currentBroadcast.activeGroup;
      state.currentBroadcast.groups.splice(index, 1);
      state.currentBroadcast.activeGroup = '0';

      state.currentBroadcast.groups.forEach((group, index) => {
        group.orderN = index;
      });
    }
  },
  [UPDATE_GROUP_PLAYER_COUNT](state, { totalPlayerCount }) {
    state.currentBroadcast.groups[
      state.currentBroadcast.activeGroup
    ].totalPlayerCount = totalPlayerCount;
    // Initial should be same, so we can compare other changes
    state.initialBroadcastValues.groups[
      state.initialBroadcastValues.activeGroup
    ].totalPlayerCount = totalPlayerCount;
  },
  [SET_BROADCAST_TRIGGERS](state, triggers) {
    state.currentBroadcast.triggers = triggers;
  },
  [SET_BROADCAST_GROUP_ORDER](state, { groups }) {
    let newActiveGroup = '';
    state.currentBroadcast.groups.forEach((group, index) => {
      group.orderN = groups.find(
        (g) => g.groupCode === index.toString(),
      ).orderN;

      // Update active group
      if (index.toString() === state.currentBroadcast.activeGroup) {
        newActiveGroup = group.orderN.toString();
      }
    });

    state.currentBroadcast.groups.sort((a, b) =>
      a.orderN > b.orderN ? 1 : b.orderN > a.orderN ? -1 : 0,
    );
    state.currentBroadcast.activeGroup = newActiveGroup;
    state.initialBroadcastValues.activeGroup = newActiveGroup;
  },
  [SET_LOADING](state, loading) {
    state.currentBroadcast.loading = loading;
  },
  [SET_HAS_PERMISSION_TO_ALL_LAYERS](state, hasPermission) {
    state.currentBroadcast.hasPermissionToAllLayers = hasPermission;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
