<template>
  <div class="va-checkbox-container">
    <label
      class="container"
      data-testid="checkbox-label"
      :class="{ 'some-selected': someSelected, disabled: disabled }"
    >
      <span class="label">
        {{ labelToDisplay }}
      </span>
      <input
        class="checkbox"
        type="checkbox"
        :checked="isChecked"
        :value="value"
        @change="updateInput($event.target.checked)"
      />
      <span class="checkmark" :data-testid="`checkbox-checkmark-${value}`" />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Boolean, Number],
      default: null,
    },
    modelValue: {
      type: [Array, Boolean],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    someSelected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    labelToDisplay() {
      return this.label ? this.label : '';
    },
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue;
    },
  },
  methods: {
    updateInput(checked) {
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];

        if (checked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('update:modelValue', newValue);
      } else {
        this.$emit('update:modelValue', checked ? true : false);
      }
    },
  },
};
</script>

<style lang="scss">
.va-checkbox-container {
  /* Customize the label (the container) */
  .container {
    left: 0;
    display: inline;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    font-size: 13px;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    background-color: $color-fill-inactive;
    transition: background-color 0.2s ease-in-out;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: $color-fill-inactive-hover;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $color-fill-active;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark::after {
    display: block;
  }

  /* Style the checkmark/indicator */
  /* stylelint-disable-next-line no-descending-specificity */
  .container .checkmark::after {
    left: 6px;
    top: 3px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .some-selected .checkmark::after {
    left: 3px;
    top: -3px;
    width: 10px;
    height: 10px;
    border: solid white;
    border-width: 0 0 2px;
    transform: none;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
