<template>
  <div class="metadata-layer-list">
    <metadata-layer-list-header
      ref="metadata-layer-list-header"
      :selected="selectedLayers"
      :modal-fields="modalFields"
      @select-all="onSelectAll"
      @update:layer="updateSelectedLayers"
    />

    <metadata-layer-list-row
      v-for="(layer, index) in newLayers"
      :key="layer.id"
      :data-testid="`metadata-layer-list-row-${index}`"
      :layer="layer"
      :selected="isSelected(layer.id)"
      :modal-fields="modalFields"
      :remove-option="hasRemoveOption"
      @add="addLayer(index)"
      @remove="removeLayer(index)"
      @update:layer="updateLayer($event, index)"
      @copy="copyLayer($event, index)"
      @update:selected="selectLayer(layer)"
    />
  </div>
</template>

<script>
import MetadataLayerListHeader from './metadata-layer-list-header.vue';
import MetadataLayerListRow from './metadata-layer-list-row.vue';
import { v4 as uuidv4 } from 'uuid';
export default {
  components: {
    MetadataLayerListHeader,
    MetadataLayerListRow,
  },
  props: {
    layers: {
      type: Array,
      required: true,
    },
    modalFields: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:layers'],
  data() {
    return {
      selectedLayers: [],
    };
  },
  computed: {
    newLayers() {
      return this.layers;
    },
    hasRemoveOption() {
      return this.newLayers.length > 1;
    },
  },
  methods: {
    onSelectAll(selected) {
      this.selectedLayers = [];
      if (selected) {
        this.newLayers.forEach((layer) => {
          this.selectedLayers.push(layer);
        });
      }
    },
    addLayer(index) {
      let newLayer = {
        id: uuidv4(),
      };
      this.newLayers.splice(index + 1, 0, newLayer);
      this.emitUpdate();
    },
    removeLayer(index) {
      this.newLayers.splice(index, 1);
      this.emitUpdate();
    },
    updateLayer(layer, index) {
      this.newLayers.splice(index, 1, layer);
      this.emitUpdate();
    },
    copyLayer(layer, index) {
      let newLayer = JSON.parse(JSON.stringify(layer));
      newLayer.id = uuidv4();
      this.newLayers.splice(index + 1, 0, newLayer);
      this.emitUpdate();
    },
    selectLayer(layer) {
      let index = this.selectedLayers.findIndex((l) => l.id === layer.id);
      if (index > -1) {
        this.selectedLayers.splice(index, 1);
      } else {
        this.selectedLayers.push(layer);
      }
    },
    isSelected(id) {
      return this.selectedLayers.find((layer) => layer.id === id)
        ? true
        : false;
    },
    emitUpdate() {
      this.$emit('update:layers', this.newLayers);
    },
    updateSelectedLayers(layer) {
      this.selectedLayers.forEach((sLayer) => {
        const initialLayer = JSON.parse(JSON.stringify(sLayer));
        let index = this.newLayers.findIndex((l) => l.id === sLayer.id);
        let returnedLayer = Object.assign(sLayer, layer);
        returnedLayer.layerName = initialLayer.layerName;
        this.updateLayer(returnedLayer, index);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.metadata-layer-list {
  :deep(.fields) {
    display: grid;
    grid-template-columns: 18px 180px 85px 85px auto;
    align-items: center;
    grid-gap: 10px;
    border-bottom: 1px solid $color-border;
    padding: 10px 0;

    .field-last {
      justify-self: flex-end;
    }
  }
}
</style>
