<template>
  <div class="metadata-layer-header">
    <div class="fields">
      <va-checkbox ref="select-all" v-model="selectAll" />
      <div class="layer-name field">
        <span v-if="!selectAll">
          {{ $translate('formFields.vaMetadataLayers.labels.layerName') }}
        </span>
        <span v-else>
          {{
            $translate(
              'formFields.vaMetadataLayers.labels.layersSelected',
              selected.length,
            )
          }}
        </span>
      </div>
      <div class="x-position field">
        <span v-if="!selectAll">
          {{ $translate('formFields.vaMetadataLayers.labels.xPos') }}
        </span>
      </div>
      <div class="y-position field">
        <span v-if="!selectAll">
          {{ $translate('formFields.vaMetadataLayers.labels.yPos') }}
        </span>
      </div>
      <div class="options field field-last">
        <span v-if="!selectAll"> {{ $translate('generic.options') }} </span>
        <span v-else class="clickable edit-selected" @click="showModal = true">
          {{ $translate('formFields.vaMetadataLayers.labels.editSelected') }}
        </span>
      </div>
    </div>
    <metadata-layer-modal
      v-if="showModal"
      ref="metadata-layer-modal"
      :fields="modalFields"
      @update:layer="onUpdateLayer"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import MetadataLayerModal from './metadata-layer-modal.vue';
import VaCheckbox from '@/components/framework/va-checkbox.vue';
export default {
  components: {
    MetadataLayerModal,
    VaCheckbox,
  },
  props: {
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modalFields: {
      type: Object,
      required: true,
    },
  },
  emits: ['select-all', 'update:layer'],
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    selectAll: {
      get() {
        return this.selected.length > 0;
      },
      set(value) {
        this.$emit('select-all', value);
      },
    },
  },
  methods: {
    onUpdateLayer(layer) {
      this.$emit('update:layer', layer);
    },
  },
};
</script>

<style lang="scss" scoped>
.metadata-layer-header {
  font-size: 14px;

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    color: $color-text;
  }
}
</style>
