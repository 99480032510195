import { Permission } from '@/helpers/constants';
import { ValidPermission } from '@/enums/permission';

const TemplateView = () => import('@/views/templates/TemplateView.vue');
const TemplateFormView = () => import('@/views/templates/TemplateFormView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const templateRoutes = [
  {
    path: '/screens/:target/:targetName/:channelCode/add/templates',
    name: 'ScreenTemplateView',
    components: { default: TemplateView, 'side-bar': SideBar },
    props: { default: true },
    meta: {
      permission: {
        resources: [
          { name: 'Player', action: 'AddAndEdit' },
          {
            name: Permission.Playlist.CreateSignFromTemplate,
            action: ValidPermission.ENABLED,
          },
        ],
        config: {
          noAccessRedirect: 'Screens',
        },
      },
      breadcrumbs: ({ route, translate }) => {
        return [
          {
            name: translate('sidebar.screens'),
            route: { name: 'Screens' },
          },
          {
            name: route.params.targetName,
            route: {
              name: 'Playlist',
              params: {
                playerId: route.params.target,
                playerName: route.params.targetName,
              },
            },
          },
          {
            name: translate('templates.title'),
          },
        ];
      },
    },
  },
  {
    path: '/media/:target/add/templates',
    name: 'TemplateView',
    components: { default: TemplateView, 'side-bar': SideBar },
    props: { default: true },
    meta: {
      permission: {
        resources: [
          { name: 'Media', action: 'AddAndEdit' },
          {
            name: Permission.Playlist.CreateSignFromTemplate,
            action: ValidPermission.ENABLED,
          },
        ],
        config: {
          noAccessRedirect: 'MediaView',
        },
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.media'),
            route: { name: 'MediaView' },
          },
          {
            name: translate('templates.title'),
          },
        ];
      },
    },
  },
  {
    path: '/templates/:target/:targetName?/signs/:mediaCode/:channelCode?',
    name: 'TemplateFormListView',
    components: { fullscreen: TemplateFormView },
    props: { fullscreen: true },
    meta: {
      permission: {
        resources: [{ name: 'Media', action: 'AddAndEdit' }],
        config: {
          noAccessRedirect: 'MediaView',
        },
      },
      breadcrumbs: ({ route, translate }) => {
        let firstBreadcrumb = {
          name: translate('sidebar.media'),
          route: { name: 'MediaView' },
        };
        let secondBreadcrumb = {
          name: translate('templates.title'),
          route: {
            name: 'TemplateView',
            params: { target: route.params.target },
          },
        };
        if (route.params.target !== 'media') {
          firstBreadcrumb = {
            name: route.params.targetName,
            route: {
              name: 'Playlist',
              params: {
                playerId: route.params.target,
                playerName: route.params.targetName,
              },
            },
          };
          secondBreadcrumb = {
            name: translate('templates.title'),
            route: {
              name: 'ScreenTemplateView',
              params: {
                target: route.params.target,
                targetName: route.params.targetName,
                channelCode: route.params.channelCode,
              },
            },
          };
        }
        return [
          firstBreadcrumb,
          secondBreadcrumb,
          {
            name: translate('templates.signs.newSign'),
          },
        ];
      },
    },
  },
  {
    path: '/templates/:target/:datacode/signs/:mediaCode/:targetName?',
    name: 'TemplateFormEdit',
    components: { fullscreen: TemplateFormView },
    props: { fullscreen: true },
    meta: {
      permission: {
        resources: [{ name: 'Media', action: 'AddAndEdit' }],
        config: {
          noAccessRedirect: 'MediaView',
        },
      },
      breadcrumbs: ({ route, translate }) => {
        let firstBreadcrumb = {
          name: translate('sidebar.media'),
          route: { name: 'MediaView' },
        };
        if (route.params.target !== 'media') {
          firstBreadcrumb = {
            name: route.params.targetName,
            route: {
              name: 'Playlist',
              params: {
                playerId: route.params.target,
                playerName: route.params.targetName,
              },
            },
          };
        }
        return [
          firstBreadcrumb,
          {
            name:
              route.params.datacode === 'duplicate'
                ? translate('templates.signs.newSign')
                : translate('templates.signs.editSign'),
          },
        ];
      },
    },
  },
];
export default templateRoutes;
