import { Permission } from '@/helpers/constants';

const hasPermissionToModifyTags = ($hasPermission) => {
  return $hasPermission(
    Permission.MediaLibrary.Tag,
    Permission.ValidPermissions.Edit,
  );
};

const hasPermissionToDeleteMedia = ($hasPermission) => {
  return $hasPermission(
    Permission.MediaLibrary.Delete,
    Permission.ValidPermissions.Edit,
  );
};

const hasPermissionToUploadMedia = ($hasPermission) => {
  return $hasPermission(
    Permission.MediaLibrary.Upload,
    Permission.ValidPermissions.Edit,
  );
};

const hasPermissionToDownloadPreview = ($hasPermission) => {
  return $hasPermission(
    Permission.MediaLibrary.Upload,
    Permission.ValidPermissions.Edit,
  );
};

const hasPermissionToModifyAIAttributes = ($hasPermission) => {
  return $hasPermission(
    Permission.Components.AI,
    Permission.ValidPermissions.Edit,
    { isClient: true },
  );
};

const hasPermissionToAIAttributesColumn = ($hasPermission) => {
  return $hasPermission(
    Permission.Components.AI,
    Permission.ValidPermissions.View,
    { isClient: true },
  );
};

const hasPermissionToCreateSignFromTemplate = ($hasPermission) => {
  return $hasPermission(
    Permission.Playlist.CreateSignFromTemplate,
    Permission.ValidPermissions.Edit,
  );
};

export default {
  hasPermissionToModifyTags,
  hasPermissionToDeleteMedia,
  hasPermissionToUploadMedia,
  hasPermissionToDownloadPreview,
  hasPermissionToModifyAIAttributes,
  hasPermissionToAIAttributesColumn,
  hasPermissionToCreateSignFromTemplate,
};
