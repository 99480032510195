<template>
  <div class="va-number-picker">
    <div>
      <input v-model="value" class="va-number-picker-input" type="number" />
    </div>
    <div class="arrow-container">
      <button
        class="arrow-button up"
        @click="$emit('update:modelValue', modelValue + 1)"
      >
        <div class="arrow-up" />
      </button>
      <button class="arrow-button down" @click="decreaseNumber">
        <div class="arrow-down" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value ? Number(value) : null);
      },
    },
  },
  methods: {
    decreaseNumber() {
      if (this.modelValue > 0) {
        this.$emit('update:modelValue', this.modelValue - 1);
      } else {
        this.$emit('update:modelValue', 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input[type='number']::-webkit-inner-spin-button {
  appearance: none;
}

.va-number-picker {
  height: 40px;
  width: 85px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.va-number-picker-input {
  height: 40px;
  width: 59.5px;
  background-color: $color-fill-secondary;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 2.86;
  letter-spacing: 0.6px;
  text-align: center;
  color: $color-text;
  border: none;
  padding: 0;
  margin-right: 1px;
  float: left;

  &::placeholder {
    color: $color-text-secondary;
  }
}

.arrow-container {
  height: 40px;
  width: 24.5px;
  float: left;
  overflow: hidden;
}

.arrow-button {
  border: none;
  box-shadow: none;
  height: 19.5px;
  background-color: $color-fill-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow-button.up {
  margin-bottom: 1px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid $color-fill;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid $color-fill;
}
</style>
