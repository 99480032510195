<template>
  <div class="metadata-layer-modal">
    <va-modal ref="va-modal" type="confirm" width="620" @close="onClose">
      <template #header>
        <h3>
          {{ $translate('generic.options') }}
        </h3>
      </template>
      <template #body>
        <div>
          <metadata-layer-modal-fields
            ref="metadata-layer-modal-fields"
            v-model:layer="newLayer"
            :fields="fields"
          />
        </div>
      </template>
      <template #footer>
        <footer class="footer-buttons">
          <va-button
            data-testid="metadata-layer-modal--cancel-button"
            color="secondary"
            @click="onClose"
          >
            {{ $translate('generic.cancel') }}
          </va-button>
          <va-button
            data-testid="metadata-layer-modal--save-button"
            @click="onSave"
          >
            {{ $translate('generic.save') }}
          </va-button>
        </footer>
      </template>
    </va-modal>
  </div>
</template>

<script>
import VaModal from '@/components/framework/va-modal.vue';
import MetadataLayerModalFields from './metadata-layer-modal-fields.vue';
import VaButton from '@/components/framework/VaButton.vue';
export default {
  components: {
    VaModal,
    MetadataLayerModalFields,
    VaButton,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    layer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['update:layer', 'close'],
  data() {
    return {
      newLayer: JSON.parse(JSON.stringify(this.layer)),
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave() {
      this.$emit('update:layer', this.newLayer);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped></style>
