//POST /connect/revocation HTTP/1.1
// Host: server.example.com
// Content-Type: application/x-www-form-urlencoded
// Authorization: Basic czZCaGRSa3F0MzpnWDFmQmF0M2JW

// token=45ghiukldjahdnhzdauz

// const HTTP_CODE_UNAUTHORIZED = 401;

// const USERROLE_Administrator = 'Administrator';
// const USERROLE_Publisher = 'Publisher';
// const USERROLE_User = 'User';

// module.exports = {
//     HTTP_CODE_UNAUTHORIZED,
//     USERROLE_Administrator,
//     USERROLE_Publisher,
//     USERROLE_User
// };

const Action = {
  view: 'Action_view',
};

const PermissionType = {
  allow: 'PermissionType_allow',
  deny: 'PermissionType_deny',
};

const Resource = {
  Menu: {
    Screens: 'Menu_Screens',
    MediaLibrary: 'Menu_MediaLibrary',
    Settings: 'Menu_Settings',
    Help: 'Menu_Help',
    Users: 'Menu_Users',
    Broadcast: 'Menu_Broadcast',
  },
  Screens: {
    AddNewScreen: 'Screens_AddNewScreen',
    Group: 'Screens_Group',
    ShowName: 'Screens_ShowName',
    MediaDrawer: 'Screens_MediaDrawer',
    Settings: 'Screens_Settings',
    Information: 'Screens_Information',
    Options: 'Screens_Options',
    Permissions: 'Screens_Permissions',
    Delete: 'Screens_Delete',
  },
  Playlist: {
    Pause: 'Playlist_Pause',
    Schedule: 'Playlist_Schedule',
    Duration: 'Playlist_Duration',
    Delete: 'Playlist_Delete',
    DeleteChannel: 'Playlist_DeleteChannel',
    EditBroadcast: 'Playlist_EditBroadcast',
    PauseBroadcast: 'Playlist_PauseBroadcast',
    ToggleTakeover: 'Playlist_ToggleTakeover',
  },
  MediaLibrary: {
    Upload: 'MediaLibrary_Upload',
    SideDrawer: 'MediaLibrary_SideDrawer',
    EditSign: 'MediaLibrary_EditSign',
    Tag: 'MediaLibrary_Tag',
    Delete: 'MediaLibrary_Delete',
    Drag: 'MediaLibrary_Drag',
    Preview: 'MediaLibrary_Preview',
    EditImage: 'MediaLibary_EditImage',
  },
  Settings: {
    OpeningHours: 'Settings_OpeningHours',
    Language: 'Settings_Language',
    Location: 'Settings_Location',
  },
  Users: {
    Invite: 'Users_Invite',
    Edit: 'Users_Edit',
    Permissions: 'Users_Permissions',
    Delete: 'Users_Delete',
    ResetPassword: 'Users_ResetPassword',
    RenewInvite: 'Users_RenewInvite',
  },
};

const Permission = {
  Components: {
    Screens: 'Player',
    MediaLibrary: 'Media',
    Broadcast: 'Broadcast',
    Settings: 'Setting',
    RolesAndUsers: 'User',
    Triggers: 'Trigger',
    Items: 'Item',
    LocalSelections: 'Broadcast.AllowInteractiveBroadcasts',
    ContentType: 'ContentType',
    Variables: 'Variable',
    RemoteControlWebPage: 'RemoteControlWebPage',
    RemoteControlLiveView: 'RemoteControlLiveView',
    ShareOfVoice: 'ShareOfVoice',
    AI: 'AI',
    ContentMix: 'ContentMix',
    Tags: 'Tag',
  },
  Broadcasts: {
    AllowStandardBroadcasts: 'Broadcast.AllowStandardBroadcasts',
    AllowShareOfVoiceBroadcasts: 'Broadcast.AllowShareOfVoiceBroadcasts',
  },
  ShareOfVoice: {
    DownloadProofOfPlayReport: 'ShareOfVoice.ProofOfPlayReport',
  },
  Screens: {
    AddNewScreen: 'Player',
    ShowName: 'Player',
    MediaDrawer: 'Media',
    Settings: 'Player',
    Information: 'Player',
    Options: 'Player',
    Delete: 'Player',
    Filter: 'Player',
    PreviewAllMediaInPlaylist: 'Playlist.PreviewAllMediaInPlaylist',
    Columns: {
      ComponentStatuses: 'Player.Col_ComponentStatuses',
      Firmware: 'Player.Col_Firmware',
      IPAddress: 'Player.Col_IPAddress',
      MACAddress: 'Player.Col_MACAddress',
      OSVersion: 'Player.Col_OSVersion',
      PlayerVersion: 'Player.Col_PlayerVersion',
    },
    Contacts: 'ScreenContacts',
  },
  Playlist: {
    Pause: 'Playlist',
    Schedule: 'Playlist',
    Duration: 'Playlist',
    Delete: 'Playlist',
    DeleteChannel: 'Playlist',
    EditBroadcast: 'Broadcast',
    PauseBroadcast: 'Broadcast',
    ToggleTakeover: 'Playlist',
    Reorder: 'Playlist',
    AddMediaToPlaylist: 'Playlist',
    CreateSignFromTemplate: 'Media.AllowCreateSignsFromTemplates',
  },
  MediaLibrary: {
    Upload: 'Media',
    SideDrawer: 'Player',
    EditSign: 'Media',
    Tag: 'Media',
    Delete: 'Media',
    Drag: 'Media',
    Preview: 'Media',
    EditImage: 'Media',
    Filter: 'Media',
    Duplicate: 'Media',
    CreateSignFromTemplate: 'Media.AllowCreateSignsFromTemplates',
  },
  RolesAndUsers: {
    SendUserInvite: 'Admin',
    AddRole: 'Admin',
    EditRole: 'Admin',
    DeleteRole: 'Admin',
    ResetPassword: 'User',
    TransferOwnership: 'User',
    EditUser: 'User',
    DeleteUser: 'User',
    EditUserPermissions: 'Admin',
  },
  Settings: {
    Location: 'Location',
    PowerScheduling: 'PowerScheduling',
    Fallback: 'FallbackTemplate',
    Volume: 'Volume',
  },
  Items: {
    View: 'Item',
    Edit: 'Item',
    Add: 'Item',
    Delete: 'Item',
    Filter: 'Item',
  },
  Overview: {
    Accounts: {
      Add: 'Account',
      Edit: 'Account',
      Delete: 'Account',
    },
    Clients: {
      Import: 'Client',
      Export: 'Client',
      ReleasePlayerByMacAddress: 'ReleasePlayerByMacAddress',
    },
    AccountSettings: {
      AccountFeatures: 'AccountFeature',
      SupportFeatures: 'SupportFeature',
      MultiAccountAccess: 'MultiAccountAccess',
      Templates: 'AccountClientHtmlTemplate',
      PlayerFormats: 'AccountClientPlayoutFormat',
    },
    Customers: {
      Add: 'Customer',
      Edit: 'Customer',
      Delete: 'Customer',
    },
    PlayerSoftware: {
      View: 'PlayerSoftware',
      Add: 'PlayerSoftware',
      Edit: 'PlayerSoftware',
      Delete: 'PlayerSoftware',
    },
  },
  Tags: {
    Media: {
      View: 'MediaTag',
      Add: 'MediaTag',
      Edit: 'MediaTag',
      Delete: 'MediaTag',
    },
    Player: {
      View: 'PlayerTag',
      Add: 'PlayerTag',
      Edit: 'PlayerTag',
      Delete: 'PlayerTag',
    },
  },
  ValidPermissions: {
    Edit: 'AddAndEdit',
    View: 'ViewOnly',
    Hide: 'Hide',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
  },
};

export { Action, PermissionType, Resource, Permission };

export default {
  install: (app) => {
    app.config.globalProperties.$constants = {
      HTTP_CODE_UNAUTHORIZED: 401,
      HTTP_CODE_NOTFOUND: 404,

      USERROLE_Administrator: 'Administrator',
      USERROLE_Publisher: 'Publisher',
      USERROLE_User: 'User',

      Action,
      Resource,
      Permission,
    };
  },
};
