<template>
  <div
    :class="['va-img-container', { clickable: hasPermission }]"
    @click="handleOnClick"
  >
    <div v-if="loading" class="loader" />
    <img
      v-if="imageData"
      :draggable="false"
      class="va-img"
      :class="{ transparent: loading }"
      :src="imageData"
      @load="onLoaded"
      @error="handleError"
    />
  </div>
</template>

<script>
import imageService from '@/services/image';
import brokenThumbUrl from '@/assets/images/broken-thumb.svg';
import notFoundThumbUrl from '@/assets/images/notfound-thumb.svg';
import eyeOffUrl from '@/assets/images/eye-off.svg';
import webpageSignUrl from '@/assets/images/webpage-sign.png';
import filesThumbUrl from '@/assets/images/files-thumb.png';

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    mediaType: {
      type: String,
      default: '',
    },
    hasPermission: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['load', 'click', 'error'],
  data() {
    return {
      imgBlob: '',
      loading: true,
      errorRetrievingImage: false,
    };
  },
  computed: {
    hasImage() {
      return this.src ? true : false;
    },
    imageData() {
      if (!this.hasPermission) {
        return eyeOffUrl;
      } else if (this.mediaType === 'WebPage' && this.errorRetrievingImage) {
        return webpageSignUrl;
      } else if (this.mediaType === 'None' || this.mediaType === 'File') {
        return filesThumbUrl;
      } else if (this.errorRetrievingImage) {
        return notFoundThumbUrl;
      } else if (this.src) {
        return this.imgBlob;
      } else {
        return brokenThumbUrl;
      }
    },
  },
  watch: {
    src() {
      this.updateImage();
    },
  },
  created() {
    if (this.src) {
      this.updateImage();
    }
  },
  methods: {
    handleOnClick(e) {
      if (this.hasPermission) {
        this.$emit('click', e);
      }
    },
    handleError(e) {
      this.$emit('error', e);
    },
    updateImage() {
      imageService
        .getImage(this.src)
        .then((img) => {
          this.imgBlob = URL.createObjectURL(img.data);
          this.loading = false;
          this.errorRetrievingImage = false;
        })
        .catch(() => {
          this.loading = false;
          this.errorRetrievingImage = true;
        });
    },
    onLoaded() {
      this.loading = false;
      this.$emit('load');
    },
  },
};
</script>

<style scoped lang="scss">
.va-img-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  user-select: none;

  &:not(.clickable) {
    cursor: not-allowed;
  }

  .va-img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;

    &.transparent {
      opacity: 0;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 0.5rem solid $color-fill-secondary;
  border-top: 0.5rem solid $color-fill-active;
  border-radius: 50%;
  width: 50%;
  animation: loading-spin 2s linear infinite;
}

.loader::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

@keyframes loading-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Making sure that the image gets hidden for Edge and Safari, in the image editor. */
.va-imageeditor-container .va-img {
  display: none !important;
}
</style>
