const MediaView = () => import('@/views/media/MediaView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const mediaRoutes = [
  {
    path: '/media',
    name: 'MediaView',
    components: { default: MediaView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Media', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ translate }) => {
        return [{ name: translate('sidebar.media') }];
      },
    },
  },
];
export default mediaRoutes;
