<template>
  <div class="va-field va-product-selector">
    <label class="title-label">{{ title }}</label>
    <div class="va-button-container">
      <button ref="button" class="va-product-button">
        <div @click="showItems">
          <div v-if="getSelectedThumbnailUrl" class="product-image">
            <va-img
              v-if="!showModal"
              class="img"
              :src="getSelectedThumbnailUrl"
            />
          </div>
          <div v-else class="add-icon">
            <svg-icon icon="add" color="green" />
          </div>
        </div>
        <div class="product-name" @click="showItems">
          {{ getSelectedName }}
        </div>
        <div class="add-icon" @click.self="showItems">
          <svg-icon
            v-if="selectedItem.factCode"
            icon="cross"
            color="pink"
            @click="clearSelected"
          />
        </div>
      </button>
      <div
        v-if="
          data[fieldModel.fieldName] &&
          (!nameAttribute || !selectedItem.factCode)
        "
        class="error-message"
      >
        <span>{{ $translate('generic.errorMessage') }}</span>
        <SvgIcon icon="refresh" class="refresh-icon" @click="refresh" />
      </div>
    </div>
    <product-selector-modal
      v-if="showModal"
      @close="closeSelectModal"
      @select="selectItem"
    ></product-selector-modal>
  </div>
</template>

<script>
import formHelper from '@/helpers/form-helper';
import VaImg from '@/components/framework/va-img.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import ProductSelectorModal from './product-selector-modal.vue';
import { withAsync } from '@/helpers/withAsync';
import { getFact, getFactScheme } from '@/api/factsApi';

export default {
  components: {
    VaImg,
    SvgIcon,
    ProductSelectorModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      selectedItem: {},
      showModal: false,
      nameAttribute: '',
      thumbnailAttribute: '',
    };
  },
  computed: {
    getSelectedThumbnailUrl() {
      if (this.data[this.fieldModel.fieldName]) {
        if (this.selectedItem?.factAttributes) {
          return this.getThumbnailUrl(this.selectedItem);
        }
      }
      return '';
    },
    getSelectedName() {
      if (this.data[this.fieldModel.fieldName]) {
        if (this.selectedItem?.factAttributes) {
          return this.getName(this.selectedItem);
        }
      }
      return this.$translate('items.addItem');
    },
    title() {
      return formHelper.getTranslatedLabel(
        this.fieldModel.label,
        this.$getUserlanguage(),
      );
    },
  },
  created() {
    this.getItemSelectorSchema();
  },
  mounted() {
    if (this.data && this.fieldModel && this.fieldModel.fieldName) {
      this.getItemData(this.data[this.fieldModel.fieldName]);
    }
  },
  methods: {
    async getItemSelectorSchema() {
      const { response } = await withAsync(getFactScheme, 'Item', 'Selector');

      if (response?.data?.factScheme) {
        const factScheme = response.data.factScheme;
        this.nameAttribute = factScheme.schema.find(
          (f) => f.type === 'va-input',
        )?.attributeCode;
        this.thumbnailAttribute = factScheme.schema.find(
          (f) => f.mediaLibrary,
        )?.attributeCode;
      }
    },
    closeSelectModal() {
      this.showModal = false;
    },
    selectItem(item) {
      this.addFormFact(item);
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = item.factCode;
      // this.$set(this.data, this.fieldModel.fieldName, item.factCode);
      this.getItemData(item.factCode);
      this.showModal = false;
      this.onEmit();
    },
    onEmit() {
      this.$emit('updatedData');
    },
    showItems() {
      this.showModal = true;
    },
    clearSelected() {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = '';
      // this.$set(this.data, this.fieldModel.fieldName, '');
      this.getItemData();
      this.showModal = false;
      this.onEmit();
    },
    async getItemData(factCode) {
      if (!factCode) {
        this.selectedItem = {};
      } else {
        const { response } = await withAsync(getFact, 'Item', factCode);

        if (response?.data?.fact) {
          this.selectedItem = response.data.fact;
          this.addFormFact(response.data.fact);
        }
      }
    },
    getName(item) {
      return (
        item?.factAttributes?.find(
          (attribute) => attribute.AttributeCode === this.nameAttribute,
        )?.Value || null
      );
    },
    getThumbnailUrl(item) {
      let media = item.factAttributes?.filter(
        (attribute) => attribute.AttributeCode === this.thumbnailAttribute,
      );
      let mediaCode =
        media.find((media) => media.IsDefault)?.MediaRef?.MediaCode ||
        media.find((media) => media.MediaRef?.MediaCode)?.MediaRef?.MediaCode ||
        '';
      return mediaCode
        ? `${this.$getEnv(
            'VITE_API_URL_SIGNAGE_BACKEND',
          )}/api/media/${mediaCode}/thumbnail`
        : '';
    },
    addFormFact(item) {
      this.$store.dispatch('addFormFact', item);
    },
    async refresh() {
      await this.getItemSelectorSchema();
      await this.getItemData(this.data[this.fieldModel.fieldName]);
    },
  },
};
</script>

<style lang="scss" scoped>
.va-product-selector {
  .va-button-container {
    display: inline-flex;
    align-items: center;
    gap: 0 0.5rem;
    margin: 0;
    height: 40px;

    .va-product-button {
      display: grid;
      grid-template-columns: 40px auto 40px;
      align-items: center;
      padding: 0;
      position: relative;
      color: $color-text-secondary;
      background-color: $color-action-bg-tertiary;
      font-weight: 500;
      cursor: pointer;
      border: none;
      border: 1px solid $color-action-border-tertiary;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      outline: none;
      height: 40px;
      box-sizing: border-box;

      .product-image {
        width: 38px;
        height: 38px;
        background-color: $color-bg-secondary;

        .img {
          width: 100%;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          :deep(.va-img) {
            object-fit: cover;
          }
        }
      }

      .product-name {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-left: 10px;
        white-space: nowrap;
        text-align: left;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .add-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height: 100%;
      }
    }
  }

  .error-message {
    color: $color-text-danger;
    display: flex;
    align-items: center;
    gap: 0 0.5rem;

    .refresh-icon {
      color: $color-fill-secondary;
      cursor: pointer;

      &:hover {
        color: $color-fill;
      }
    }
  }
}
</style>
