<template>
  <div class="top-menu" :class="{ expanding: navBarSearchFilter }">
    <div class="top-parts">
      <div class="top-part">
        <va-breadcrumbs
          v-if="$route.meta.breadcrumbs"
          :breadcrumbs="
            $route.meta.breadcrumbs({ route: $route, translate: $translate })
          "
          class="menu-item"
          @click="onBreadcrumbClick"
        />
      </div>
      <div class="top-part">
        <div class="menu-item extra-slot"><slot name="extra"></slot></div>
        <div
          v-for="(button, index) in navigationButtons"
          :key="index"
          class="menu-item"
        >
          <div class="menu-button">
            <router-link v-slot="{ href, navigate }" :to="button.to" custom>
              <a
                class="button nav"
                :href="href"
                :data-testid="button.to.name"
                @click="navigate"
              >
                {{ button.label }}
              </a>
            </router-link>
          </div>
        </div>
        <div
          v-if="navBarActionButtonLabel"
          class="menu-item menu-button-container"
        >
          <va-dropdown
            v-if="navBarActions"
            :dropdown-actions="navBarActions"
            data-testid="top-nav-bar-action-dropdown"
            @DROPDOWN_SELECTED="onDropdownItemClick($event)"
          >
            <span class="dropbtn">{{ navBarActionButtonLabel }}</span>
            <svg-icon icon="chevron" class="chevron-icon" />
          </va-dropdown>
          <div v-if="!navBarActions" class="menu-button">
            <va-button
              id="nav-bar-action-button"
              data-testid="action-button"
              @click="onMenuButtonClick"
            >
              {{ navBarActionButtonLabel }}
            </va-button>
          </div>
        </div>
        <div v-if="navBarViewMenu" class="menu-item menu-view-container">
          <div class="view-menu">
            <div
              :class="[{ active: activeViewLayout == 'grid' }, 'left']"
              @click="onViewMenuClick('grid')"
            >
              <i class="fa fa-th-large" aria-hidden="true" />
            </div>
            <div
              :class="[{ active: activeViewLayout == 'list' }, 'right']"
              @click="onViewMenuClick('list')"
            >
              <i class="fa fa-bars" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div
          v-if="navBarSearch || navBarSearchFilter"
          class="menu-item menu-search-container filter-search"
        >
          <va-search
            ref="vasearch"
            v-model="searchText"
            :start-filter-open="startFilterOpen"
            :filter-option="navBarSearchFilter"
            @OPEN_FILTER="onFilterOpen"
            @CLOSE_FILTER="onFilterClose"
            @keyup="onKeyUp"
          />
        </div>
      </div>
    </div>
    <div v-if="showFilter" class="filter-part">
      <va-search-filter
        :key="filterCode"
        :save="{
          save: true,
          conditionLookups: conditionLookups,
          savedCondition: savedConditions,
          filterCode: filterCode,
        }"
        :filter-by="computedFilterBy"
        :saved-filters="savedFilters"
        :successfully-saved-filter="successfullySavedFilter"
        :has-permission-to-edit-filter="hasPermissionToEditFilter"
        :media-libraries="mediaLibraries"
        :tag-type="tagType"
        @DELETE_FILTER="showDeleteFilterModal = true"
        @CLOSE_FILTER="onSearchFilterClose"
        @CHANGED_FILTER="onFilterChange"
        @CHANGED_ATTRIBS="onAttributesChange"
        @SAVE_FILTER="onSaveFilter"
        @SEARCH_SAVED_FILTER="onSearchSavedFilter"
      />
    </div>
    <div class="bottom-part">
      <va-tabbar
        v-if="tabBarItems"
        :tab-bar-items="tabBarItems"
        :dropdown-actions="tabBarDropdownActions"
        :active="activeTab"
        @TABBAR_BUTTON_CLICK="onTabClick"
        @TABBAR_ACTION_CLICK="onTabActionClick"
      />
    </div>

    <va-modal
      v-if="showDeleteFilterModal"
      class="deleteFilterModal"
      type="warning"
      width="425"
      @confirm="onDeleteFilter"
      @close="showDeleteFilterModal = false"
    >
      <template #header>
        <h3>{{ $translate('filter.deleteModal.title') + filterName }}?</h3>
      </template>
      <template #body>
        <p>
          {{ $translate('filter.deleteModal.info') }}
        </p>
      </template>
      <template #footer>
        <footer class="footer-buttons">
          <va-button color="secondary" @click="showDeleteFilterModal = false">
            {{ $translate('generic.cancel') }}
          </va-button>
          <va-button
            data-testid="va-top-navbar--delete-button"
            color="danger"
            @click="onDeleteFilter"
          >
            {{ $translate('generic.delete') }}
          </va-button>
        </footer>
      </template>
    </va-modal>
  </div>
</template>

<script>
import VaTabbar from '@/components/framework/va-tabbar.vue';
import VaDropdown from './va-dropdown-old.vue';
import VaSearch from '@/components/framework/va-search.vue';
import VaSearchFilter from '@/components/framework/va-search-filter.vue';
import { mapGetters } from 'vuex';
import mediaFilterService from '@/services/mediaFilter';
import playerFilterService from '@/services/playerFilter';
import VaButton from '@/components/framework/VaButton.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import VaBreadcrumbs from '@/components/framework/va-breadcrumbs.vue';
import { isEmpty } from '@/helpers/objectHelpers.js';
import { withAsync } from '@/helpers/withAsync';
import VaModal from '@/components/framework/va-modal.vue';
import {
  deleteFactFilter,
  editFactFilter,
  getFactFilter,
  saveFactFilter,
} from '@/api/factsApi';

export default {
  components: {
    VaModal,
    VaDropdown,
    VaTabbar,
    VaButton,
    VaSearch,
    SvgIcon,
    VaBreadcrumbs,
    VaSearchFilter,
  },
  props: {
    navBarActionButtonLabel: {
      type: String,
      default: '',
    },
    navBarActions: {
      type: Array,
      default: null,
    },
    navBarSearch: {
      type: Boolean,
      default: null,
    },
    navBarSearchFilter: {
      type: Boolean,
      default: null,
    },
    navBarViewMenu: {
      type: Boolean,
      default: null,
    },
    tabBarItems: {
      type: Array,
      default: null,
    },
    tabBarDropdownActions: {
      type: Array,
      default: null,
    },
    activeTab: {
      type: String,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      default: null,
    },
    navigationButtons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterBy: {
      type: [Object, Array],
      default: null,
    },
    mediaLibraries: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tagType: {
      type: String,
      default: '',
    },
  },
  emits: [
    'search',
    'FILTER_CHANGED',
    'NAVBAR_DROPDOWN_SELECTED',
    'NAVBAR_SEARCH',
    'TABBAR_ITEM_CLICKED',
    'NAVBAR_BUTTON_CLICKED',
    'TABBAR_ACTION_CLICK',
    'SAVE_FILTER',
    'BREADCRUMB_CLICK',
  ],
  data() {
    return {
      searchText: '',
      startFilterOpen: false,
      showDeleteFilterModal: false,
      savedConditions: null,
      conditionLookups: null,
      filterCode: null,
      filterName: null,
      searchDelayTimer: null,
      successfullySavedFilter: false,
      showFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      activeViewLayout: 'activeViewLayout',
      searchData: 'searchData',
      browserState: 'browserState',
      playerFilters: 'playerFilters',
      mediaFilters: 'mediaFilters',
      itemFilters: 'itemFilters',
    }),
    computedFilterBy() {
      if (this.filterBy) {
        return this.filterBy;
      } else if (
        this.activeView === 'MediaView' ||
        this.activeView === 'ItemsImages'
      ) {
        return { screenFormat: true, tags: true, mediaNames: true };
      } else if (this.activeView === 'Screens') {
        return {
          location: true,
          screenFormat: true,
          tags: true,
          screenNames: true,
          layers: true,
        };
      } else {
        return { tags: true };
      }
    },
    savedFilters() {
      let filters = [];
      let targetFilters = [];
      let targetFilterCode = '';
      let targetFilterName = '';

      switch (this.activeView) {
        case 'MediaView':
        case 'ItemsImages':
          targetFilters = this.mediaFilters;
          targetFilterCode = 'mediaFilterCode';
          targetFilterName = 'mediaFilterName';
          break;
        case 'Screens':
          targetFilters = this.playerFilters;
          targetFilterCode = 'playerFilterCode';
          targetFilterName = 'playerFilterName';
          break;
        case 'ItemsView':
          targetFilters = this.itemFilters;
          targetFilterCode = 'attributeFilterCode';
          targetFilterName = 'attributeFilterName';
          break;
      }

      targetFilters.forEach((targetFilter) => {
        let filter = targetFilter;
        filter.code = targetFilter[targetFilterCode];
        filter.name = targetFilter[targetFilterName];
        filters.push(filter);
      });
      return filters;
    },
    hasPermissionToEditFilter() {
      if (this.activeView === 'Screens') {
        return this.$hasPermission(
          this.$constants.Permission.Screens.Filter,
          this.$constants.Permission.ValidPermissions.Edit,
        );
      } else if (
        this.activeView === 'MediaView' ||
        this.activeView === 'ItemsImages'
      ) {
        return this.$hasPermission(
          this.$constants.Permission.MediaLibrary.Filter,
          this.$constants.Permission.ValidPermissions.Edit,
        );
      } else if (this.activeView === 'ItemsView') {
        return this.$hasPermission(
          this.$constants.Permission.Items.Filter,
          this.$constants.Permission.ValidPermissions.Edit,
        );
      }
      return false;
    },
    activeView() {
      return this.$route.name;
    },
  },
  mounted() {
    const emitAction = this.$route.query.filter;
    if (emitAction) {
      this.onTabClick(emitAction);
    }
    this.$nextTick(() => {
      if (this.browserState.back) {
        const data = this.searchData[this.activeView];
        this.searchText = data?.searchText || '';
        if (
          this.navBarSearchFilter &&
          data?.condition &&
          !isEmpty(data.condition)
        ) {
          this.startFilterOpen = data.showFilter;
          this.conditionLookups = data.lookup;
          this.savedConditions = data.condition;
        }
        if (data) {
          this.$emit('search', data);
        }
      } else {
        this.$store.dispatch('clearSearchData', {
          view: this.activeView,
        });
      }
    });
  },
  methods: {
    createFilter(emitAction) {
      if (~emitAction.indexOf('mediaFilter:')) {
        let mediaCode = emitAction.split(':')[1];
        mediaFilterService
          .getMediaFilter(mediaCode)
          .then((mediaFilter) => {
            this.savedConditions = mediaFilter.condition;
            this.conditionLookups = mediaFilter.conditionLookups;
            this.filterCode = mediaFilter.mediaFilterCode;
            this.filterName = mediaFilter.mediaFilterName;
            this.updateSearchData({
              condition: this.savedConditions,
              lookup: this.conditionLookups,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (~emitAction.indexOf('playerFilter:')) {
        const filterCode = emitAction.split(':')[1];
        playerFilterService
          .getPlayerFilter(filterCode)
          .then((playerFilter) => {
            this.savedConditions = playerFilter.condition;
            this.conditionLookups = playerFilter.conditionLookups;
            this.filterCode = playerFilter.playerFilterCode;
            this.filterName = playerFilter.playerFilterName;
            this.updateSearchData({
              condition: this.savedConditions,
              lookup: this.conditionLookups,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (~emitAction.indexOf('itemFilter:')) {
        let filterCode = emitAction.split(':')[1];
        this.getFactFilter(filterCode);
      } else {
        this.savedConditions = null;
        this.conditionLookups = null;
        this.filterCode = null;
        this.filterName = null;
        this.updateSearchData({ condition: this.savedConditions });
      }
    },
    onSearchFilterClose() {
      this.$refs.vasearch.showFilter = false;
      this.onFilterClose();
    },
    onFilterClose() {
      this.showFilter = false;

      if (this.activeView !== 'Screens') {
        this.onTabClick(this.activeTab);
      }

      this.updateSearchData({ showFilter: this.showFilter });
    },
    onFilterOpen() {
      this.showFilter = true;
      this.updateSearchData({ showFilter: this.showFilter });

      if (this.activeView === 'Screens') {
        this.createFilter(this.$route.query.filter);
      }
    },
    onFilterChange(condition) {
      this.savedConditions = condition;
      this.updateSearchData({ condition: this.savedConditions });
      this.$emit('FILTER_CHANGED', condition);
    },
    onAttributesChange(attribs) {
      this.conditionLookups = attribs;
      this.updateSearchData({ lookup: this.conditionLookups });
    },
    onDeleteFilter() {
      if (
        this.activeView === 'MediaView' ||
        this.activeView === 'ItemsImages'
      ) {
        this.onDeleteMediaFilter();
      } else if (this.activeView === 'Screens') {
        this.onDeletePlayerFilter();
      } else if (this.activeView === 'ItemsView') {
        this.onDeleteItemFilter();
      }
    },
    onDeleteMediaFilter() {
      mediaFilterService
        .deleteMediaFilter(this.filterCode)
        .then(() => {
          this.onSuccessfulFilterDelete();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onDeletePlayerFilter() {
      playerFilterService
        .deletePlayerFilter(this.filterCode)
        .then(() => {
          this.onSuccessfulFilterDelete();
          this.$store.dispatch('getPlayerFilters', {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async onDeleteItemFilter() {
      const { response } = await withAsync(deleteFactFilter, this.filterCode);

      if (response?.data?.success?.ok) {
        this.onSuccessfulFilterDelete();
        this.$store.dispatch('getItemFilters', {});
      }
    },
    onSuccessfulFilterDelete() {
      this.savedConditions = null;
      this.filterCode = null;
      this.onTabClick('all');
      this.showDeleteFilterModal = false;
      this.$emit('FILTER_CHANGED', null);
      this.$store.dispatch('addMessage', {
        message: {
          text: this.$translate('filter.deleteModal.success', this.filterName),
          type: 'success',
        },
        time: 10000,
      });
    },
    onDropdownItemClick(emitAction) {
      this.$emit('NAVBAR_DROPDOWN_SELECTED', emitAction);
    },
    onKeyUp() {
      if (this.searchDelayTimer) {
        clearTimeout(this.searchDelayTimer);
      }
      this.searchDelayTimer = setTimeout(this.onNavbarSearch, 500);
    },
    onNavbarSearch() {
      this.updateSearchData({ searchText: this.searchText });
      this.$emit('NAVBAR_SEARCH', this.searchText);
    },
    onTabClick(emitAction) {
      this.createFilter(emitAction);
      this.$emit('TABBAR_ITEM_CLICKED', emitAction);
    },
    async getFactFilter(filterCode) {
      const { response } = await withAsync(getFactFilter, filterCode);

      if (response?.data?.filter) {
        const filter = response.data.filter;

        this.savedConditions = filter.condition;
        this.conditionLookups = filter.conditionLookups;
        this.filterCode = filter.attributeFilterCode;
        this.filterName = filter.attributeFilterName;
        this.updateSearchData({
          condition: this.savedConditions,
          lookup: this.conditionLookups,
        });
      }
    },
    onViewMenuClick(emitAction) {
      this.$store.dispatch('changeActiveViewLayout', {
        activeViewLayout: emitAction,
      });
    },
    onMenuButtonClick() {
      this.$emit('NAVBAR_BUTTON_CLICKED', this.navBarActionButtonLabel);
    },
    onTabActionClick(actionItem) {
      this.$emit('TABBAR_ACTION_CLICK', actionItem);
    },
    onSaveFilter(payload) {
      this.$emit('SAVE_FILTER', payload);
      if (
        this.activeView === 'MediaView' ||
        this.activeView === 'ItemsImages'
      ) {
        this.onSaveMediaFilter(payload);
      } else if (this.activeView === 'Screens') {
        this.onSavePlayerFilter(payload);
      } else if (this.activeView === 'ItemsView') {
        this.onSaveItemFilter(payload);
      }
    },
    onSaveMediaFilter(payload) {
      if (payload.saveCode) {
        mediaFilterService
          .editMediaFilter(
            payload.saveCode,
            payload.filterName,
            payload.conditions,
          )
          .then((mediaFilter) => {
            if (mediaFilter.success.ok) {
              this.onSuccessfulSaveFilter();
              this.$store.dispatch('getMediaFilters', {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        mediaFilterService
          .saveMediaFilter(payload.filterName, payload.conditions)
          .then((mediaFilter) => {
            if (mediaFilter.success.ok) {
              this.onSuccessfulSaveFilter();
              this.$store.dispatch('getMediaFilters', {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onSavePlayerFilter(payload) {
      if (payload.saveCode) {
        playerFilterService
          .editPlayerFilter(
            payload.saveCode,
            payload.filterName,
            payload.conditions,
          )
          .then((mediaFilter) => {
            if (mediaFilter.success.ok) {
              this.onSuccessfulSaveFilter();
              this.$store.dispatch('getPlayerFilters', {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        playerFilterService
          .savePlayerFilter(payload.filterName, payload.conditions)
          .then((mediaFilter) => {
            if (mediaFilter.success.ok) {
              this.onSuccessfulSaveFilter();
              this.$store.dispatch('getPlayerFilters', {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async onSaveItemFilter(payload) {
      let result;
      if (payload.saveCode) {
        result = await withAsync(editFactFilter, {
          filterCode: payload.saveCode,
          filterName: payload.filterName,
          condition: payload.conditions,
          factType: 'Item',
        });
      } else {
        result = await withAsync(saveFactFilter, {
          filterName: payload.filterName,
          condition: payload.conditions,
          factType: 'Item',
        });
      }

      if (result?.response?.data?.success?.ok) {
        this.onSuccessfulSaveFilter();
        this.$store.dispatch('getItemFilters', {});
      }
    },
    onSuccessfulSaveFilter() {
      this.successfullySavedFilter = true;
      this.$nextTick(() => {
        this.successfullySavedFilter = false;
      });
    },
    onBreadcrumbClick(e) {
      this.$emit('BREADCRUMB_CLICK', e);
    },
    onSearchSavedFilter(searchText) {
      switch (this.activeView) {
        case 'MediaView':
        case 'ItemsImages':
          this.$store.dispatch('getMediaFilters', { searchText: searchText });
          break;
        case 'Screens':
          this.$store.dispatch('getPlayerFilters', { searchText: searchText });
          break;
        case 'ItemsView':
          this.$store.dispatch('getItemFilters', { searchText: searchText });
          break;
      }
    },
    updateSearchData(data) {
      this.$store.dispatch('updateSearchData', {
        searchData: data,
        view: this.activeView,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$default-height: 2.5rem;

.top-menu {
  position: relative;
  height: auto;
  width: 100%;
  padding-top: $default-height;

  .top-parts {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .top-part {
    display: flex;
  }

  .menu-item {
    padding-right: 20px;

    &.extra-slot {
      display: flex;
      align-items: center;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .menu-button-container,
  .menu-view-container {
    display: inline-block;
    height: $default-height;
    vertical-align: middle;
    cursor: inherit;
  }

  .view-menu {
    border-radius: 100px;
    display: flex;

    > * {
      font-size: 16px;
    }

    div {
      background-color: #4b4b51;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: $default-height;
      border-radius: 100px;
      width: 42px;
      cursor: pointer;
      pointer-events: all;
      transition: background-color 0.2s ease-out;

      i {
        width: 15px;
        height: 15px;
        color: $color-text-secondary;
        transition: color 0.2s ease-out;
      }

      &.active {
        background-color: $color-fill-active;
        color: $color-text;

        i {
          color: $color-fill;
        }

        &:hover {
          background-color: $color-fill-active-hover;
        }
      }

      &:hover {
        background-color: #64646d;

        i {
          color: #fafafa;
        }
      }
    }

    .left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  input[type='file'],
  input[type='file']::-webkit-file-upload-button {
    cursor: pointer;
  }

  .menu-button {
    overflow: hidden;
  }

  .menu-button,
  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: $default-height;
    border-radius: 19.5px;
    vertical-align: top;

    .button,
    :deep(button) {
      color: #000;
      background-color: #5edc88;
      height: $default-height;
      padding: 0 20px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.3px;
      cursor: pointer;
      border: none;
      border-radius: 19.5px;
      line-height: $default-height;
      user-select: none;
      transition: background-color 0.2s ease-out;

      .svg-icon {
        color: inherit;
        padding: 0 0 0 10px;
      }

      &:hover,
      &.open {
        background-color: #43f980;
      }

      &.open {
        .icon-container {
          transform: rotateZ(180deg);
        }

        .svg-icon {
          outline: none;
        }
      }

      &:hover {
        .svg-icon {
          color: inherit;
        }
      }

      &.nav {
        color: $color-text-secondary;
        background-color: #181e24;
        border: 1px solid #4b4b51;
        text-decoration: none;
        padding-top: 0.65rem;
        padding-bottom: 0.65rem;
      }
    }
  }

  .top-menu-right {
    position: absolute;
    right: 0;
    height: 45px;
    vertical-align: top;
  }
}
</style>
