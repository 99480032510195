<template>
  <div class="weekdays">
    <div class="day">{{ $translate(`generic.week`).substring(0, 1) }}.</div>
    <div v-for="day in 7" :key="day" class="day">
      {{ $translate(`generic.daysArray.[${day - 1}]`).substring(0, 3) }}
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$day-w-h: 1.875rem;

.weekdays {
  display: grid;
  grid-template-columns: 1.5rem repeat(7, $day-w-h);
  grid-template-rows: $day-w-h;

  .day {
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &:first-child {
      padding-right: 0.4rem;
    }
  }
}
</style>
