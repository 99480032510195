<template>
  <div class="va-date-header">
    <div class="arrow-container" @click="$emit('prev-month')">
      <div
        aria
        class="arrow left"
        :title="
          $translate(
            `generic.months.[${
              displayMonth - 2 !== -1 ? displayMonth - 2 : 11
            }]`,
          )
        "
        role="button"
        aria-pressed="false"
        tabindex="0"
        data-testid="date-picker-arrow-left"
        @keyup.escape="$emit('close')"
        @keyup.enter.space="$emit('prev-month')"
      />
    </div>
    <h3 class="month-year">
      {{ $translate(`generic.months.[${displayMonth - 1}]`) }}
      {{ displayYear > 1800 ? displayYear : '????' }}
    </h3>
    <div class="arrow-container right" @click="$emit('next-month')">
      <div
        class="arrow right"
        :title="
          $translate(
            `generic.months.[${displayMonth - 1 !== 11 ? displayMonth : 0}]`,
          )
        "
        role="button"
        aria-pressed="false"
        tabindex="0"
        data-testid="date-picker-arrow-right"
        @keyup.enter.space="$emit('next-month')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayMonth: {
      type: Number,
      default: 1,
    },
    displayYear: {
      type: Number,
      default: 1,
    },
  },
  emits: ['next-month', 'prev-month', 'close'],
};
</script>

<style lang="scss" scoped>
.va-date-header {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;

  .arrow-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.6rem 1.35rem;
    margin: -0.6rem 0;
    justify-self: flex-start;
    z-index: 10;

    &.right {
      justify-self: flex-end;
    }

    .arrow {
      width: 0.6rem;
      height: 0.6rem;
      box-sizing: border-box;
      border-bottom: 2px solid $color-fill-secondary;
      border-right: 2px solid $color-fill-secondary;
      cursor: pointer;
      transition: border-color 0.2s ease-out;

      &.left {
        transform: rotate(135deg);
      }

      &.right {
        transform: rotate(-45deg);
      }
    }

    &:hover .arrow {
      border-color: $color-fill;
    }
  }

  .month-year {
    text-align: center;
    display: inline-block;
    line-height: 1.5rem;
  }
}
</style>
