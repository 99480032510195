<template>
  <div :class="['tabbar', { actions: dropdownActions }]">
    <div class="tabbar-item-container">
      <draggable
        :model-value="tabBarItems"
        class="tabbar-draggable"
        :disabled="!draggable"
        @update:model-value="$emit('update:tabBarItems', $event)"
      >
        <div
          v-for="item in tabBarItems"
          :key="item.emitAction"
          role="link"
          class="tabbar-item"
          :class="[
            item.class,
            {
              active: active == item.emitAction && item.label,
              disabled: disabled && item.emitAction != disabled,
              unclickable: item.unclickable || !item.label,
            },
          ]"
          :action="item.emitAction"
          :data-testid="`tab-item-${item.emitAction}`"
          @click="onTabBarItemClick"
        >
          {{ item.label }}
        </div>
      </draggable>
    </div>

    <div v-if="rowActions" class="tabbar-options tabbar-options-row">
      <button
        v-for="action in rowActions"
        :key="action.emitAction"
        :data-testid="`tab-item-${action.emitAction}`"
        :class="[
          'tabbar-options-row-button',
          { disabled: action.disabled },
          action.type,
        ]"
        :disabled="action.disabled"
        role="button"
        @click="onDropdownItemClick(action.emitAction)"
      >
        {{ action.label }}
      </button>
    </div>

    <div v-if="dropdownActions" class="tabbar-options">
      <va-dropdown
        ref="dropdown-actions"
        :dropdown-actions="dropdownActions"
        data-testid="tabbar-dropdown-actions"
        @DROPDOWN_SELECTED="onDropdownItemClick($event)"
      >
        <svg-icon
          icon="options"
          class="icon"
          :title="$translate('broadcast.headings.options')"
        />
      </va-dropdown>
    </div>
  </div>
</template>

<script>
import VaDropdown from '@/components/framework/va-dropdown.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import VaDraggable from '@/components/framework/VaDraggable.vue';

export default {
  components: {
    VaDropdown,
    SvgIcon,
    draggable: VaDraggable,
  },
  props: {
    tabBarItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    active: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    dropdownActions: {
      type: Array,
      default: null,
    },
    rowActions: {
      type: Array,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['TABBAR_BUTTON_CLICK', 'TABBAR_ACTION_CLICK', 'update:tabBarItems'],
  methods: {
    onTabBarItemClick(e) {
      if (!this.disabled)
        this.emitItemClick(e.srcElement.getAttribute('action'));
    },
    emitItemClick(item) {
      this.$emit('TABBAR_BUTTON_CLICK', item);
    },
    onDropdownItemClick(item) {
      this.$emit('TABBAR_ACTION_CLICK', item);
    },
  },
};
</script>

<style lang="scss" scoped>
$tab-height: 1.75rem;
$tabbar-height: 3rem;

.tabbar {
  display: flex;
  justify-content: space-between;
  user-select: none;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  letter-spacing: 0.33px;
  text-align: left;
  height: $tabbar-height;

  .tabbar-item-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    padding-bottom: 10px;

    &:hover {
      overflow: scroll hidden;
      padding-bottom: 0;
    }

    .tabbar-draggable {
      position: relative;
      border-bottom: 2px solid #2c2f35;
      width: 100%;
      display: flex;
      box-sizing: border-box;
      height: $tab-height;
    }

    .tabbar-item {
      box-sizing: content-box;
      color: $color-text-secondary;
      display: inline-block;
      padding: 0 10px;
      padding-bottom: 10px;
      cursor: pointer;
      height: 16px;
      transition: color 0.2s ease-out;
      border-bottom: 2px solid #2c2f35;
      margin-bottom: -2px;

      // ? line-height is set to deal with overflowing letter Å
      line-height: 20px;

      &:hover {
        color: $color-text;
      }

      &.active {
        color: $color-text;
        border-bottom: 2px solid $color-fill-active;
      }

      &.disabled {
        display: none;
        opacity: 0;
        cursor: inherit;
      }

      &.unclickable {
        cursor: inherit;
        pointer-events: none;
      }

      &.right {
        float: right;
      }
    }
  }

  .tabbar-options {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 0.8rem;
    background-color: $color-bg;
    border-bottom: 2px solid #2c2f35;
    height: $tab-height;

    .icon {
      font-size: 18px;
    }
  }

  .tabbar-options-row {
    display: flex;
  }

  .tabbar-options-row-button {
    background: none;
    border: none;
    color: $color-text;
    font-size: 13px;
    letter-spacing: 0.33px;
    line-height: 15px;
    padding-left: 1rem;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.warning {
      color: $color-text-warning;
    }

    &.danger {
      color: $color-text-danger;
    }
  }
}
</style>
