<script setup lang="ts">
import { onMounted, onBeforeUnmount, watch } from 'vue';
import { updateUser } from '@/api/userApi';
import { useApi } from '@/api/composables/useApi';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - This is a temporary solution until vuex is replaced with Pinia
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { MultiAccountAccessType } from '@/views/overview/clients/accountSettings/enums/multiAccountAccessType';

const store = useStore();

const router = useRouter();

const { exec, normalizedApiStatuses } = useApi('updateUser', updateUser);

const { updateUserStatusSuccess } = normalizedApiStatuses;

const switchClient = async () => {
  store.dispatch('setIsClientLoaded', false);

  await exec(
    store.getters.currentUser.id,
    {
      defaultClientCode: store.getters.currentUser.clientCode,
    },
    {
      switchingClient: true,
    },
  );

  if (updateUserStatusSuccess.value) {
    store.dispatch('setIsClientLoaded', true);
  }
};

const handleFocus = () => {
  const { permissionToClients, isAdmin, accountAccessType } =
    store.getters.currentUser;
  if (
    permissionToClients?.length > 1 &&
    (isAdmin ||
      accountAccessType === MultiAccountAccessType.ENVIRONMENT_ADMINISTRATOR)
  ) {
    switchClient();
  }
};

onMounted(() => {
  window.addEventListener('focus', handleFocus);
});

onBeforeUnmount(() => {
  window.removeEventListener('focus', handleFocus);
});

watch(
  () => store.getters.currentUser?.clientCode,
  () => {
    // If user does not have permission to the client, redirect to the first client
    const hasPermissionToCurrentClient =
      store.getters.currentUser?.permissionToClients?.some(
        (client: { clientCode: string }) =>
          client.clientCode === store.getters.currentUser?.clientCode,
      );
    if (!hasPermissionToCurrentClient) {
      if (store.getters.currentUser?.permissionToClients.length) {
        router.push({
          name: 'ClientLoginView',
          params: {
            clientCode:
              store.getters.currentUser?.permissionToClients[0].clientCode,
          },
        });
      }
    }
  },
);
</script>

<template><div class="switch-client-overlay"></div></template>

<style scoped lang="scss">
.switch-client-overlay {
  display: none;
}
</style>
